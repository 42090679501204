import clsx from "clsx";
import React, { useEffect, useState } from "react";
import chevronRight from "../assets/social-icons/faq-chevron-right.png";
import HOC from "../component/HOC";
import fetchApi from "../apiservice/ApiService";
import parser from "html-react-parser";

const FAQ = () => {
  const [openDetails, setOpenDetails] = useState("");
  const [faqDetails, setFaqDetails] = useState("");
  const [showMainContainer, setShowMainContainer] = useState("");
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "FAQ | Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetFAQ();
  }, []);

  const handleOpenDetails = (key, index) => {
    setOpenDetails(openDetails === key ? "" : key);
    setFaqDetails(index);
  };

  const handleGetFAQ = () => {
    setLoading(true);
    fetchApi
      .getFaq()
      .then((response) => {
        setFaqData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <div className="terms-bg flex justify-center items-center">
          <p className="text-white text-[32px] lg:text-[47px] font-bold text-center px-8 md:px-0">
            Frequently Asked Questions
          </p>
        </div>

        {loading ? (
          <div className="flex flex-col items-center justify-center py-20">
            <div className="page-loader"></div>
          </div>
        ) : (
          <div className="mt-10">
            {faqData?.map((item, index) => {
              return (
                <div
                  id={item?.id}
                  className={clsx(
                    "lg:mx-20 mx-3",
                    showMainContainer === index ? "my-10" : "my-2"
                  )}
                  key={index}
                >
                  <div
                    className="flex justify-between items-center border-b-2 broder-b-[#B5B5B5] mb-10 cursor-pointer h-[50px]"
                    onClick={() => {
                      setShowMainContainer(
                        showMainContainer === index ? "" : index
                      );
                      if (showMainContainer !== index) {
                        document.getElementById(item?.id).scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }
                    }}
                  >
                    <p className="lg:text-[32px] text-lg font-semibold text-[#600D10]">
                      {item?.title}
                    </p>
                    <img
                      src={chevronRight}
                      alt="right arrow"
                      className={clsx(
                        "transition-all duration-500 w-[30px] md:w-[50px]",
                        showMainContainer === index ? "hidden" : "block"
                      )}
                    />
                  </div>
                  <div
                    className={clsx(
                      showMainContainer === index ? "block" : "hidden"
                    )}
                  >
                    {item?.faqs?.length > 0 &&
                      item?.faqs?.map((ele, key) => {
                        return (
                          <div
                            className="bg-background rounded-[20px] p-6 my-4"
                            key={key}
                          >
                            <div
                              className="flex justify-between items-center cursor-pointer"
                              onClick={() => {
                                handleOpenDetails(key, index);
                              }}
                            >
                              <p className="text-primary font-bold lg:text-[20px] text-base">
                                {ele?.title}
                              </p>
                              <img
                                src={chevronRight}
                                alt="right arrow"
                                className={clsx(
                                  "transition-all duration-500 w-[30px] md:w-[50px]",
                                  openDetails === key && faqDetails === index
                                    ? "rotate-90"
                                    : "rotate-0"
                                )}
                              />
                            </div>
                            <div
                              className={clsx(
                                "font-light transition-all duration-500 overflow-hidden",
                                openDetails === key && faqDetails === index
                                  ? "h-auto visible"
                                  : "h-0 invisible"
                              )}
                            >
                              <p className="my-2 whitespace-pre-line text-sm d-flex content-bg">
                                {ele?.description && parser(ele?.description)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default HOC(FAQ);
