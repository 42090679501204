import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import toast, { toastConfig } from "react-simple-toasts";
import { AppConfig } from "../../apiservice/AppConfig";
import portrait from "../../assets/images/contact-form.png";
import BannerImg from "../../assets/images/contact-us-banner.png";
import Button from "../../common-component/button/Button";
import RadioButton from "../../common-component/radio-button/RadioButton";
import TextInput from "../../common-component/textinput/TextInput";
import HOC from "../../component/HOC";
import SelectDropdown from "../../common-component/select-dropdown/SelectDropdown";
import fetchApi from "../../apiservice/ApiService";

const productsArray = [
  "Payment Platform",
  "Payouts",
  "NeuX",
  "Connected Banking",
  "Expense Management",
  "Smart Routing",
  "Aasaan Checkout",
  "Whitelabel",
  "APIX",
  "Others",
];

const Contact = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [citiesData, setCitiesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [checkProduct, setCheckProduct] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const productRef = useRef(null);

  useEffect(() => {
    const handleCloseProductModal = (e) => {
      if (productRef?.current && !productRef?.current?.contains(e.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleCloseProductModal);

    return () => {
      document.removeEventListener("mousedown", handleCloseProductModal);
    };
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      const mail = e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      setForm({ ...form, [e.target.name]: e.target.value });
      if (mail) {
        setIsValidEmail(true);
        setError({ ...error, email: "" });
      } else if (e.target.value === "") {
        setForm({ ...form, [e.target.name]: "" });
        setError({ ...error, email: "" });
      } else {
        setError({ ...error, email: "Please enter a valid email address." });
      }
    } else if (e.target.name === "phone") {
      const tel = e.target.value.match(/^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/gm);
      setForm({ ...form, [e.target.name]: e.target.value });
      if (tel) {
        setIsValidPhone(true);
        setError({ ...error, phone: "" });
      } else if (e.target.value === "") {
        setForm({ ...form, [e.target.name]: "" });
        setError({ ...error, phone: "" });
      } else {
        setError({ ...error, phone: "Please enter a valid phone number." });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const handleSelectProduct = (data) => {
    let tempArr = [...product];
    if (tempArr.includes(data)) {
      tempArr = tempArr.filter((ele) => ele !== data);
    } else {
      tempArr.push(data);
    }
    setProduct(tempArr);
    if (tempArr?.length == 0) {
      setCheckProduct(true);
      return;
    }
  };

  const handleGetCities = (id) => {
    fetchApi
      .getCities(id)
      .then((response) => {
        let newCitiesArray = [];
        response?.data?.data?.forEach((item) => {
          newCitiesArray.push({ id: item?.id, label: item?.name });
        });
        setCitiesData(newCitiesArray);
      })
      .catch((error) => {
        console.log(error);
        toastConfig({ theme: "failure" });
        toast(error?.message);
      });
  };

  const handleGetStates = () => {
    fetchApi
      .getStates()
      .then((response) => {
        let newStateArray = [];
        response?.data?.data?.forEach((item) => {
          newStateArray.push({ id: item?.id, label: item?.name });
        });
        setStatesData(newStateArray);
      })
      .catch((error) => {
        toastConfig({ theme: "failure" });
        toast(error?.message);
      });
  };

  useEffect(() => {
    document.title = "Contact Us - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetStates();
  }, []);

  const handleSubmit = () => {
    setLoader(true);
    if (
      product?.length === 0 ||
      form?.email === "" ||
      form?.first_name === "" ||
      form?.phone === "" ||
      form?.message === "" ||
      selectedCity === null ||
      selectedState === null
    ) {
      toastConfig({ theme: "failure" });
      toast("Please fill all the mandatory fields to send message.");
      setTimeout(() => {
        setLoader(false);
      }, 500);
      return false;
    }
    if (isValidEmail && isValidPhone) {
      const _form = new FormData();
      _form.append("first_name", form?.first_name);
      _form.append("email", form?.email);
      _form.append("phone", form?.phone);
      _form.append("message", form?.message);
      _form.append("page_name", "contact_page");
      _form.append("city", selectedCity?.id);
      _form.append("state", selectedState?.id);
      product?.forEach((item, index) => {
        _form.append(`product[${index}]`, item);
      });
      axios
        .post(AppConfig?.baseUrl + "customer/landing/contact-us", _form)
        .then((response) => {
          setForm({
            first_name: "",
            email: "",
            phone: "",
            message: "",
          });
          setProduct("");
          setSelectedCity(null);
          setSelectedState(null);
          document
            .querySelectorAll(".common-component")
            .forEach((component) => {
              component.checked = false;
            });
          toastConfig({ theme: "success" });
          toast(response?.data?.message);
          setIsValidEmail(false);
          setIsValidPhone(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error?.response?.data?.errors);
          setShowOptions(true);
          toastConfig({ theme: "failure" });
          if (error?.response?.data?.message) {
            toast(error?.response?.data?.message);
          } else {
            toast("Email not sent.");
          }
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  };

  return (
    <>
      <section>
        <div className="about-bg justify-end items-center hidden md:flex">
          <div className="text-primary w-[50%] p-10 grid gap-2">
            <p className="text-[38px] tex font-extrabold">
              How can we assist you?
            </p>
            <p className="text-[18px] tex max-w-[70%]">
              Need quick solutions to your queries? Contact the Touras team now
              for instant resolution
            </p>
          </div>
        </div>

        <div className="block md:hidden justify-end items-center">
          <div>
            <img src={BannerImg} alt="banner" className="w-full" />
          </div>
          <div className="text-primary p-10 grid gap-2 bg-background">
            <p className="text-[38px] font-extrabold">How can we assist you?</p>
            <p className="text-[18px]">
              Need quick solutions to your queries? Contact the Touras team now
              for instant resolution
            </p>
          </div>
        </div>

        {/* <div className="">
          <section className="grid lg:flex items-center max-w-[1140px] mx-4 lg:mx-auto py-20">
            <div className="bg-background md:w-[95%] lg:w-[35%] rounded-[10px]">
              <div className="p-6">
                <p className="text-primary text-[40px] font-bold">
                  Ready to Begin?
                </p>
                <p className="text-primary text-[16px]">
                  Easily kickstart your journey of payments and solutions via
                  Touras by registering or contact sales to tailor your package
                  to perfectly fit your business requirements.
                </p>
              </div>
              <div className="w-full">
                <img
                  src={portrait}
                  width={300}
                  alt="portrait"
                  className="mt-4 w-full object-contain"
                />
              </div>
            </div>
            <div className="grid gap-8 px-4 md:px-10 lg:w-[65%] font-poppins mt-5 md:mt-0">
              <div className="grid lg:grid-cols-2 gap-6 lg:gap-10">
                <TextInput
                  className="bg-transparent"
                  themeColor="#060606"
                  name={"first_name"}
                  label={"First Name"}
                  type={"text"}
                  required
                  value={form?.first_name}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.name !== "") {
                      setError({ ...error, first_name: "" });
                    }
                  }}
                  error={Boolean(error?.first_name)}
                  helperText={error?.first_name}
                ></TextInput>
                <TextInput
                  className="bg-transparent"
                  themeColor="#060606"
                  name={"last_name"}
                  label={"Last Name"}
                  type={"text"}
                  required
                  value={form?.last_name}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.name !== "") {
                      setError({ ...error, last_name: "" });
                    }
                  }}
                  error={Boolean(error?.last_name)}
                  helperText={error?.last_name}
                ></TextInput>
                <TextInput
                  className="bg-transparent"
                  themeColor="#060606"
                  name={"email"}
                  label={"Email"}
                  type={"email"}
                  required
                  value={form?.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  error={Boolean(error?.email)}
                  helperText={error?.email}
                ></TextInput>
                <TextInput
                  className="bg-transparent"
                  themeColor="#060606"
                  name={"phone"}
                  label={"Phone Number"}
                  type={"number"}
                  required
                  value={form?.phone}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  error={Boolean(error?.phone)}
                  helperText={error?.phone}
                ></TextInput>
                <SelectDropdown
                  label={"State"}
                  themeColor={"#060606"}
                  placeHolder="Select State"
                  data={statesData}
                  value={selectedState?.label}
                  onClick={(val) => {
                    setSelectedState(val);
                    handleGetCities(val?.id);
                  }}
                  required
                  error={Boolean(error?.state)}
                  helperText={error?.state}
                />
                <SelectDropdown
                  label={"City"}
                  themeColor={"#060606"}
                  placeHolder="Select City"
                  message="Please choose a state in order to select a city."
                  data={citiesData}
                  value={selectedCity?.label}
                  required
                  onClick={(val) => {
                    setSelectedCity(val);
                  }}
                  error={Boolean(error?.city)}
                  helperText={error?.city}
                />
              </div>
              <div
                className={clsx(
                  "border-[1px] rounded-[14px] p-6 md:max-w-[80%]",
                  Boolean(error?.product) ? "border-red-500" : "border-primary"
                )}
              >
                <p
                  className={clsx(
                    "font-semibold cursor-pointer w-max",
                    Boolean(error?.product) ? "text-red-500" : "text-primary"
                  )}
                  onClick={() => setShowOptions(!showOptions)}
                >
                  Select Product* <span className="triangle-down"></span>
                </p>
                {showOptions && (
                  <div
                    className={clsx(
                      "grid lg:grid-cols-3 gap-x-10 gap-y-3 p-4 transition-all duration-500"
                    )}
                  >
                    <RadioButton
                      id="radio-button-1"
                      themeColor="#060606"
                      label={"Payment Platform"}
                      name={"product"}
                      value={product}
                      onClick={() => {
                        handleSelectProduct("Payment Platform");
                        setError({ ...error, product: "" });
                      }}
                    ></RadioButton>
                    <RadioButton
                      id="radio-button-2"
                      themeColor="#060606"
                      label={"Payouts"}
                      name={"product"}
                      value={product}
                      onClick={() => {
                        handleSelectProduct("Payouts");
                        setError({ ...error, product: "" });
                      }}
                    ></RadioButton>
                    <RadioButton
                      id="radio-button-3"
                      themeColor="#060606"
                      label={"Neux"}
                      name={"product"}
                      value={product}
                      onClick={() => {
                        handleSelectProduct("Neux");
                        setError({ ...error, product: "" });
                      }}
                    ></RadioButton>
                    <RadioButton
                      id="radio-button-4"
                      themeColor="#060606"
                      label={"Aasaan"}
                      name={"product"}
                      value={product}
                      onClick={() => {
                        handleSelectProduct("Aasaan");
                        setError({ ...error, product: "" });
                      }}
                    ></RadioButton>
                    <RadioButton
                      id="radio-button-5"
                      themeColor="#060606"
                      label={"APIX"}
                      name={"product"}
                      value={product}
                      onClick={() => {
                        handleSelectProduct("APIX");
                        setError({ ...error, product: "" });
                      }}
                    ></RadioButton>
                    <RadioButton
                      id="radio-button-6"
                      themeColor="#060606"
                      label={"ONOX"}
                      name={"product"}
                      value={product}
                      onClick={() => {
                        handleSelectProduct("ONOX");
                        setError({ ...error, product: "" });
                      }}
                    ></RadioButton>
                    <RadioButton
                      id="radio-button-7"
                      themeColor="#060606"
                      label={"White label"}
                      name={"product"}
                      value={product}
                      onClick={() => {
                        handleSelectProduct("White label");
                        setError({ ...error, product: "" });
                      }}
                    ></RadioButton>
                    <RadioButton
                      id="radio-button-8"
                      themeColor="#060606"
                      label={"Others"}
                      name={"product"}
                      value={product}
                      onClick={() => {
                        handleSelectProduct("Others");
                        setError({ ...error, product: "" });
                      }}
                    ></RadioButton>
                  </div>
                )}
              </div>
              <div className="grid text-[#212121] text-[14px]">
                <label
                  className={clsx(
                    "font-medium",
                    Boolean(error?.message) ? "text-red-500" : "text-primary"
                  )}
                >
                  Message *
                </label>
                <textarea
                  name="message"
                  placeholder="Write your message.."
                  className={clsx(
                    "outline-none py-2 border-b-[1px] bg-transparent resize-none",
                    Boolean(error?.message)
                      ? "border-b-red-500"
                      : "border-b-primary"
                  )}
                  value={form?.message}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.name !== "") {
                      setError({ ...error, message: "" });
                    }
                  }}
                ></textarea>
                {Boolean(error?.message) && (
                  <p className="text-[12px] text-red-500 mt-1">
                    {error?.message}
                  </p>
                )}
              </div>
              <div className="flex justify-start">
                {loader ? (
                  <p
                    className="bg-primary text-white py-2 px-4 flex justify-center items-center rounded-md w-[150px]"
                    onClick={() => {
                      toastConfig({ theme: "failure" });
                      toast("please wait! we are processing your request.");
                    }}
                  >
                    <span className="loader"></span>
                  </p>
                ) : (
                  <Button
                    text={"Send Message"}
                    backgroundColor={"#060606"}
                    onClick={() => {
                      handleSubmit();
                    }}
                  ></Button>
                )}
              </div>
            </div>
          </section>
        </div> */}
        <section className="grid lg:flex items-start py-10 px-4 md:px-20 get-started-bg font-MyriadPro">
          <div className="lg:w-[35%] pt-6 rounded-[10px]">
            <p className="text-content text-[28px] md:text-[40px] font-bold">
              Ready to be resilient?{" "}
            </p>
            <p className="text-content text-[14px] md:text-[16px] border-b border-black border-dashed pb-5">
              Create your account or contact our experts to explore custom
              solutions.
            </p>
            <div className="flex items-center gap-4 mt-8">
              <div>
                <p className="text-[28px] md:text-[44px] font-semibold">
                  24/7<span className="text-secondary">*</span>
                </p>
                <p className="text-[#00000080] text-[14px] md:text-[18px]">
                  Service Availability
                </p>
              </div>
              <div>
                <p className="text-[28px] md:text-[44px] font-semibold">
                  100<span className="text-secondary">+</span>
                </p>
                <p className="text-[#00000080] text-[14px] md:text-[18px]">
                  Banking Integration
                </p>
              </div>
            </div>
          </div>
          <div className="grid gap-2 px-4 py-10 md:px-10 md:py-0 lg:w-[65%] font-poppins">
            <div className="grid lg:grid-cols-2 gap-x-6 lg:gap-x-4">
              <TextInput
                name={"first_name"}
                label={"First Name"}
                type={"text"}
                value={form?.first_name}
                required
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.name !== "") {
                    setError({ ...error, first_name: "" });
                  }
                }}
                error={Boolean(error?.first_name)}
                helperText={error?.first_name}
              ></TextInput>
              <TextInput
                name={"email"}
                label={"Email"}
                type={"email"}
                required
                value={form?.email}
                onChange={(e) => {
                  handleChange(e);
                }}
                error={Boolean(error?.email)}
                helperText={error?.email}
              ></TextInput>
              <TextInput
                name={"phone"}
                label={"Phone Number"}
                value={form?.phone}
                type={"number"}
                required
                onChange={(e) => {
                  handleChange(e);
                }}
                error={Boolean(error?.phone)}
                helperText={error?.phone}
              ></TextInput>
              <SelectDropdown
                label={"State"}
                placeHolder="Select State"
                data={statesData}
                value={selectedState?.label}
                onClick={(val) => {
                  setSelectedState(val);
                  handleGetCities(val?.id);
                }}
                required
                error={Boolean(error?.state)}
                helperText={error?.state}
              />
              <SelectDropdown
                label={"City"}
                placeHolder="Select City"
                message="Please choose a state in order to select a city."
                data={citiesData}
                value={selectedCity?.label}
                onClick={(val) => {
                  setSelectedCity(val);
                }}
                required
                error={Boolean(error?.city)}
                helperText={error?.city}
              />

              <div>
                <div className="text-[#212121] grid">
                  <label
                    className={clsx(
                      "text-[14px] font-medium flex items-center",
                      checkProduct ? "text-red-500" : ""
                    )}
                  >
                    Select Product
                    <span className="text-secondary">*</span>
                  </label>
                </div>
                <div
                  className={clsx(
                    "relative h-[45px] border-[1px] rounded-[8px] px-2 py-1",
                    checkProduct ? "border-red-500" : "border-[#A5A5A5]"
                  )}
                >
                  <div
                    className={clsx(
                      "flex items-center justify-between cursor-pointer text-[14px] h-[35px] text-[#212121] font-medium"
                    )}
                    onClick={() => setShowOptions(!showOptions)}
                  >
                    {product?.length > 0 ? (
                      <p className="flex gap-2 items-center">
                        {product?.slice(0, 1)?.map((item, index) => {
                          return (
                            <label
                              key={index}
                              className="border border-secondary py-1 px-2 bg-background rounded-[20px] text-secondary"
                            >
                              {item}
                            </label>
                          );
                        })}
                        {product?.length >= 2 && (
                          <label className="border border-secondary py-1 px-2 bg-background rounded-[20px] text-secondary">
                            +{product?.length - 1}
                          </label>
                        )}
                      </p>
                    ) : (
                      <p className="text-[14px] font-medium flex items-center text-[#999]">
                        Select
                      </p>
                    )}
                    <span className="triangle-down mr-8"></span>
                  </div>
                  {showOptions && (
                    <div
                      ref={productRef}
                      className="absolute top-[44px] left-0 z-10 w-full bg-white border border-[#6B6B6B] grid max-h-[200px] h-auto overflow-auto"
                    >
                      {productsArray?.map((item, index) => {
                        return (
                          <div key={index} className="py-2 px-4">
                            <label className="flex gap-2 items-center cursor-pointer text-[#6B7888]">
                              <input
                                type="checkbox"
                                checked={product?.includes(item)}
                                onClick={() => {
                                  handleSelectProduct(item);
                                }}
                              />
                              {item}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="grid text-[#212121] text-[14px]">
              <label
                className={clsx(
                  "font-medium",
                  Boolean(error?.message) ? "text-red-500" : ""
                )}
              >
                Message <span className="text-secondary">*</span>
              </label>
              <textarea
                name="message"
                placeholder="Write your message.."
                className={clsx(
                  "outline-none p-2 border-[1px] resize-none rounded-[8px]",
                  Boolean(error?.message)
                    ? "border-red-500"
                    : "border-[#A5A5A5]"
                )}
                value={form?.message}
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.name !== "") {
                    setError({ ...error, message: "" });
                  }
                }}
              ></textarea>
              {Boolean(error?.message) && (
                <p className="text-[12px] text-red-500 mt-1">
                  {error?.message}
                </p>
              )}
            </div>
            <div className="flex justify-end">
              {loader ? (
                <p
                  className="bg-primary text-white py-2 px-4 flex justify-center items-center rounded-md w-[150px]"
                  onClick={() => {
                    toastConfig({ theme: "failure" });
                    toast("please wait! we are processing your request.");
                  }}
                >
                  <span className="loader"></span>
                </p>
              ) : (
                <Button
                  text={"Send Message"}
                  backgroundColor={"#060606"}
                  onClick={() => {
                    handleSubmit();
                  }}
                ></Button>
              )}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default HOC(Contact);
