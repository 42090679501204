import clsx from "clsx";
import React, { useEffect, useState } from "react";
import atWorkImg1 from "../../assets/images/career-images/at-work-1.jpg";
import atWorkImg2 from "../../assets/images/career-images/at-work-2.jpg";
import atWorkImg4 from "../../assets/images/career-images/at-work-4.jpg";
import dreamRole from "../../assets/images/career-images/dream-role-img.png";
import lifeAtSafexpay from "../../assets/images/career-images/life-at-safexpay.png";
import insta from "../../assets/social-icons/insta-color.png";
import reviewArr from "../../assets/social-icons/review-arrow.svg";
import rightArr from "../../assets/social-icons/right-arr.svg";
import HOC from "../../component/HOC";
import { careerBenefitData, staffReviewData } from "./CareerData";
import fetchApi from "../../apiservice/ApiService";

const Career = () => {
  const [slide, setSlide] = useState(0);
  const [VacanciesData, setVacanciesData] = useState([]);

  const handlePrevious = () => {
    setSlide(slide - 1);
  };

  const handleNext = () => {
    setSlide(slide + 1);
  };

  useEffect(() => {
    document.title = "Career - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetVacancies();
  }, []);

  const handleGetVacancies = () => {
    fetchApi
      .getVacancies()
      .then((response) => {
        setVacanciesData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <section>
        <div className="career-bg flex justify-end items-end md:justify-start md:items-center">
          <div className="text-primary w-full bg-background md:bg-transparent md:w-[50%] px-4 py-10 md:p-10 grid gap-2 md:mx-10">
            <p className="text-[24px] md:text-[38px] font-extrabold">
              Welcoming new talents to a journey of growth
            </p>
            <p className="md:text-[18px]">
              Become a Tourasians and Build the Future of Payments
            </p>
            <div className="mt-6">
              <button
                className="text-white mt-6 bg-primary text-[12px] lg:text-[16px] py-2 px-10 rounded-[20px] hover:scale-95 transition-all w-[60%]"
                onClick={() => {
                  document
                    .getElementById("vacancy")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Explore Growth
              </button>
            </div>
          </div>
        </div>

        <div id="vacancy" className="lg:my-10 lg:mx-20 mb-1 mx-4 mt-10">
          <p className="lg:text-[40px] text-[24px] font-bold text-primary lg:px-10 px-4 text-center">
            View All Vacancies
          </p>

          <div className="mt-8 grid gap-4">
            {VacanciesData?.slice(0, 4)?.map((item, index) => {
              return (
                <div
                  className="bg-background rounded-[20px] lg:px-10 lg:py-6 px-5 py-4 flex justify-between items-center cursor-pointer"
                  key={index}
                  onClick={() => {
                    window.open(item?.link);
                  }}
                >
                  <div>
                    <p className="text-primary lg:text-[20px] text-base font-extrabold">
                      {item?.title}
                    </p>
                  </div>
                  <div className="bg-white lg:first-letter:p-4 rounded-full flex justify-center items-center">
                    <img
                      src={rightArr}
                      alt="right arrow"
                      className="min-w-max"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {/* {VacanciesData?.length > 4 && (
            <div className="lg:mt-10 my-4 flex justify-center">
              <button className="bg-[#01A30F] text-white text-[12px] lg:text-[18px] py-2 px-4 w-[240px] rounded-[80px] hover:scale-95 transition-all">
                Load More
              </button>
            </div>
          )} */}
        </div>

        <div className="bg-primary px-4 md:px-20 py-10">
          <p className="text-white lg:text-[40px] text-[30px] font-bold text-center">
            Tourasians at work
          </p>

          <div className="grid sm:grid-cols-3 gap-4 sm:gap-10 mt-10 items-center">
            <div className="md:h-[265px]">
              <img
                src={atWorkImg1}
                alt="Tourasians at work"
                className="object-cover mx-auto h-full w-full"
              />
            </div>
            <div className="md:h-[265px]">
              <img
                src={atWorkImg2}
                alt="Tourasians at work"
                className="object-cover mx-auto h-full"
              />
            </div>
            <div className="md:h-[265px]">
              <img
                src={atWorkImg4}
                alt="Tourasians at work"
                className="object-cover mx-auto h-full"
              />
            </div>
          </div>
        </div>

        <div className="bg-background lg:p-10 px-4 py-10">
          <p className="lg:text-[40px] text-[30px] font-bold text-primary px-10 text-center">
            Benefits
          </p>

          <div className="mt-10 grid lg:grid-cols-4 grid-cols-2 lg:gap-10 gap-4 lg:mx-20">
            {careerBenefitData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="bg-white rounded-[20px] h-[250px] grid place-items-center p-4"
                >
                  <img
                    src={item?.iconUrl}
                    alt={item?.label}
                    className="w-16 h-16"
                  />
                  <p className="text-primary md:text-[20px] font-bold text-center">
                    {item?.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="md:p-10 py-10 px-4">
          <p className="lg:text-[40px] text-[30px] font-bold text-primary px-10 text-center">
            Life at Touras
          </p>

          <div className="mt-8">
            <img src={lifeAtSafexpay} alt="Life at Touras" />
          </div>

          <div className="mt-8 flex justify-center">
            <p
              className="text-[#2FAA4A] border-[1px] border-[#2FAA4A] p-2 flex items-center gap-2 rounded-[10px] w-max md:text-[18px] cursor-pointer"
              onClick={() => {
                window.open(
                  "https://www.instagram.com/official_touras/",
                  "_blank"
                );
              }}
            >
              <img src={insta} alt="instagram" />
              Visit Instagram profile
            </p>
          </div>
        </div>

        <div className="lg:p-10 p-4">
          <p className="lg:text-[40px] text-[30px] font-bold text-primary px-4 md:px-10 text-center">
            Hear from our <span className="text-secondary">people</span>
          </p>

          <div className="lg:mx-20 mt-6">
            <div className="bg-background p-4 lg:p-10 flex gap-4 items-center relative">
              {slide !== 0 && (
                <div
                  className="absolute top-[40%] md:top-[50%] -left-2 md:-left-6 -translate-y-[50%] cursor-pointer"
                  onClick={() => handlePrevious()}
                >
                  <img
                    src={reviewArr}
                    alt="arrow left"
                    className="w-[40px] md:w-auto"
                  />
                </div>
              )}
              {staffReviewData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={clsx(
                      "gap-4 lg:gap-10 flex-col md:flex-row",
                      slide === index ? "flex" : "hidden"
                    )}
                  >
                    <div className="md:w-[40%] h-[250px] md:h-[360px] flex">
                      <img
                        src={item?.imageUrl}
                        alt="review"
                        className="h-auto object-contain"
                      />
                    </div>
                    <div className="flex flex-col justify-between gap-10 md:w-[60%] p-3 lg:p-0">
                      <p className="md:text-[20px] md:leading-7 text-base text-[#000000]">
                        {item?.review}
                      </p>
                      <p className="grid text-[#000000]">
                        <span className="text-[22px] font-bold">
                          {item?.name}
                        </span>
                        <span className="text-[20px]">{item?.jobRole}</span>
                      </p>
                    </div>
                  </div>
                );
              })}
              {slide !== staffReviewData?.length - 1 && (
                <div
                  className="absolute top-[40%] md:top-[50%] -right-2 md:-right-6 -translate-y-[50%] rotate-180 cursor-pointer"
                  onClick={() => handleNext()}
                >
                  <img
                    src={reviewArr}
                    alt="arrow right"
                    className="w-[40px] md:w-auto"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-center gap-2 mt-6">
              {[...Array(staffReviewData?.length)]?.map((_, index) => {
                return (
                  <button
                    key={index}
                    className={clsx(
                      "w-[30px] md:w-[80px] h-[3px] rounded-full",
                      slide === index ? "bg-secondary " : "bg-[#C4C4C4]"
                    )}
                    onClick={() => setSlide(index)}
                  ></button>
                );
              })}
            </div>
          </div>
        </div>

        <div className="px-4 py-10 md:p-10">
          <div className="bg-primary lg:mx-20 lg:w-[80%] p-10 rounded-[30px] relative">
            <div className="lg:w-[80%]">
              <p className="text-[#FFFFFF] block md:hidden font-extrabold text-[24px] text-center md:text-[40px]">
                Can't Find Your Dream Role? We'd Still Love to Hear from You!
              </p>
              <p className="text-[#FFFFFF] hidden md:block font-extrabold text-[24px] md:text-[40px]">
                Can't Find Your Dream Role?
                <br /> We'd Still Love to Hear from You!
              </p>
              <p className="text-[#FFFFFF] md:text-[20px] mt-4 md:max-w-[80%]">
                Let us know what works for you by emailing your resume and
                anything that could help us understand your preferences.
              </p>
              <div>
                <a href={`mailto:hr@safexpay.com`}>
                  <button className="bg-[#FFFFFF] text-primary py-2 px-8 rounded-[10px] mt-10 cursor-pointer">
                    Email Us
                  </button>
                </a>
              </div>
              <img
                src={dreamRole}
                alt="dream role"
                className="lg:absolute -right-40 top-[50%] lg:-translate-y-[50%] lg:w-[40%] mt-10 lg:mt-0"
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HOC(Career);
