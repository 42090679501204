import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/home.css";
import rightArr from "../../assets/social-icons/right-arr.svg";
import HOC from "../../component/HOC";
import { routes_path } from "../../config/routesPath";
import dashboardVideo from "../../assets/video/Home_page_videos/dashboard.mp4";
import HomeAbout from "./HomeAbout";
import {
  bankingPartnerData,
  financePlatformProductData,
  financePlatformTitleData,
  solutionsData,
} from "./homeData";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel } from "swiper/modules";
import homeBanner from "../../assets/images/Home-banner.png";
import learnMoreArrow from "../../assets/social-icons/learn-more-arrow.png";
import getStartedFlow from "../../assets/images/get-started-flow.png";
import getStartedFlowMobile from "../../assets/images/get-started-flow-mobile.png";

const HomeBannerDynamicTxt = ["Right", "Reliable", "Resilient"];

const Home = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [financePlatformTitle, setFinancePlatformTitle] = useState(
    financePlatformTitleData?.[0]?.id
  );
  const swiperRef = useRef(null);
  const [bannerCount, setBannerCount] = useState(0);
  const [swiperIndex, setSwiperIndex] = useState(0);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setBannerCount(
  //       bannerCount == HomeBannerArr?.length - 1 ? 0 : bannerCount + 1
  //     );
  //   }, 1800);
  // }, [bannerCount]);

  useEffect(() => {
    document.title =
      "Touras - Best Payment Solution for Online Payments In India";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="get-started-bg pb-4">
        <div className="home-bnr-bg flex flex-col-reverse md:flex-row items-center gap-[20px] md:h-[450px] lg:h-[550px] w-full">
          <div
            className="lg:px-20 pt-8 px-4 text-center md:text-left md:w-[50%] font-MyriadPro relative z-[5]"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <p className="font-MyriadPro text-[1.2em] md:text-[30px] lg:text-[50px] xl:text-[70px] font-semibold text-primary leading-[35px] lg:leading-[80px] flex flex-wrap items-center justify-center md:justify-start overflow-hidden md:w-max">
              <span>Choose the</span>
              <span className="flex flex-col px-2 lg:px-4 text-secondary h-[30px] md:h-[40px] gap-10 dynamic-text text-[20px] md:text-[30px] font-normal">
                {HomeBannerDynamicTxt?.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className="w-max h-[30px] md:h-[40px] flex items-center bg-white border-2 border-secondary rounded-[80px] px-4"
                    >
                      {item}
                    </span>
                  );
                })}
                {/* <span className="w-max h-[30px] md:h-[40px] flex items-center bg-white border-2 border-secondary rounded-[80px] px-4">
                Reliable
              </span>
              <span className="w-max h-[30px] md:h-[40px] flex items-center bg-white border-2 border-secondary rounded-[80px] px-4">
                Resilient
              </span> */}
              </span>{" "}
              {/* <span className="block md:hidden">Solution Now</span> */}
            </p>
            <p className="font-MyriadPro text-[1.2em] md:text-[30px] lg:text-[50px] xl:text-[70px] font-semibold text-primary leading-[35px] lg:leading-[80px]">
              Solution Now
            </p>
            <p className="font-MyriadPro py-6 text-[#6B6B6B] overflow-hidden text-[16px] lg:text-[20px] relative border-b border-dashed border-b-[#CBCBCB]">
              Elevate your experience with our wide array of consumer-centric
              products and services, all at your fingertips.
            </p>
            <div className="grid lg:flex gap-4 mt-4 lg:mt-10">
              <button
                className="bg-primary flex justify-center items-baseline hover:scale-95 transition-all py-[10px] px-[26px] rounded-[8px] text-white gap-3"
                onClick={() => {
                  navigate(routes_path?.contactUs);
                }}
              >
                Right Solutions
                <img
                  src={rightArr}
                  alt="right arrow"
                  className="translate-y-1 invert object-contain"
                />
              </button>
            </div>
          </div>
          <div
            className="md:w-[50%] lg:w-[50%] pt-12 h-full px-4"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <figure className="mx-auto flex w-full lg:w-max justify-center h-full transition-all duration-500">
              <img
                src={homeBanner}
                className="Img w-full lg:max-w-[520px] object-cover h-auto overflow-visible"
                alt="foreground banner"
              />
            </figure>
          </div>
        </div>

        {/* Our Banking partners */}
        <section className="my-4 py-4 mx-4 lg:mx-20 border border-[#EBEBEB] font-MyriadPro">
          <p className="text-[18px] lg:text-[24px] text-primary text-center">
            Our Banking Partners
          </p>
          <div className="overflow-hidden">
            <div className="flex gap-20 py-6 items-center justify-between banking-marquee-effect w-screen">
              {bankingPartnerData?.map((item) => {
                return (
                  <img
                    src={item?.imgUrl}
                    alt={item?.alt_text}
                    key={item?.index}
                    width={150}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </div>

      {/* One stop finance platform */}
      <div className="my-10 px-4 md:px-20 font-MyriadPro">
        <p className="text-[28px] md:text-[48px] font-semibold text-center text-black">
          One-stop finance platform
        </p>
        <div className="flex gap-2 justify-center flex-wrap mt-8">
          {financePlatformTitleData?.map((item, index) => {
            return (
              <p
                // href={`#${item?.id}`}
                key={index}
                className={clsx(
                  "font-bold w-max p-1 border-b-2 cursor-pointer",
                  swiperIndex == index
                    ? "border-b-secondary text-secondary text-[15px]"
                    : "border-b-[#787878] text-[#787878] text-[13px]"
                )}
                onClick={() => {
                  // setFinancePlatformTitle(index);
                  swiperRef?.current?.swiper?.slideTo(index);
                  // document
                  //   .getElementById(item?.id)
                  //   .scrollIntoView({ behavior: "smooth" });
                }}
              >
                {item?.title}
              </p>
            );
          })}
        </div>

        <div className="py-10 font-MyriadPro">
          <Swiper
            ref={swiperRef}
            direction={"vertical"}
            slidesPerView={1}
            spaceBetween={30}
            // mousewheel={true}
            mousewheel={{
              forceToAxis: true,
              sensitivity: 1,
              releaseOnEdges: true,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Mousewheel]}
            className="mySwiper max-h-[600px] md:max-h-[400px]"
            onRealIndexChange={(ele) => setSwiperIndex(ele?.activeIndex)}
          >
            {financePlatformProductData?.map((item, index) => {
              return (
                <SwiperSlide>
                  <div
                    key={index}
                    id={item?.id}
                    className="flex flex-col md:flex-row gap-4 justify-center items-center"
                  >
                    <div className="md:w-[50%]">
                      <p className="w-max text-sm text-[#00060E] border-2 border-secondary rounded-[80px] px-4 py-1 text-center">
                        {item?.title}
                      </p>
                      <p className="text-[28px] md:text-[48px] leading-[57px] mt-2">
                        {item?.heading}
                      </p>
                      <p className="text-[#6B6B6B] text-[16px] md:text-[18px] py-4">
                        {item?.description}
                      </p>
                      <div className="flex items-center gap-2">
                        <p
                          className={clsx(
                            "w-max text-[16px] md:text-[18px] border-b border-[#4262FF] text-[#4262FF]",
                            item?.navigationURL
                              ? "cursor-pointer"
                              : "cursor-default"
                          )}
                          onClick={() => {
                            if (item?.navigationURL) {
                              navigate(item?.navigationURL);
                            }
                          }}
                        >
                          Learn more
                        </p>
                        <img src={learnMoreArrow} alt="arrow" />
                      </div>
                    </div>
                    <div className="md:w-[50%]">
                      <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="h-auto md:h-[410px] rounded-[20px] mx-auto"
                      >
                        <source src={item?.videoURL} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      {/* Get started */}
      <div className="get-started-bg pb-4">
        <div className="font-MyriadPro py-8 px-4">
          <p className="text-[28px] md:text-[48px] font-semibold text-center">
            Get Started in Just 3 Easy Steps
          </p>
          <p className="text-[#6B6B6B] text-[16px] md:text-[18px] text-center">
            We'll guide you through the onboarding process, verify your
            documents, and provide the tools you need.
          </p>

          <div className="py-4 px-4 md:px-20">
            <img
              src={getStartedFlow}
              alt="get started flow"
              className="hidden md:block"
            />
            <img
              src={getStartedFlowMobile}
              alt="get started flow"
              className="block md:hidden"
            />
          </div>

          {/* <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2 mt-10 px-4 md:px-20">
            {getStartedData?.map((item, index) => {
              return (
                <div key={index}>
                  <p className="text-[28px] md:text-[48px]">{item?.title}</p>
                  <p className="text-[#6B6B6B] text-[16px] md:text-[18px]">
                    {item?.description}
                  </p>
                </div>
              );
            })}
          </div> */}
        </div>

        <div className="mt-14 px-4 md:px-20">
          <video autoPlay loop playsInline muted className="h-auto mx-auto">
            <source src={dashboardVideo} type="video/mp4" />
          </video>
        </div>
      </div>

      {/* Solutions For Every Industries section */}
      <div className="my-20 px-4 font-MyriadPro">
        <p className="text-center text-[28px] md:text-[48px] font-semibold">
          Solutions For Every Industries
        </p>
        <p className="text-center text-[16px] md:text-[18px] text-[#6B6B6B]">
          Customized payment solutions designed to meet the unique needs of
          various industries.
        </p>

        <div className="flex flex-wrap justify-center md:grid grid-cols-5 gap-4 place-items-center my-20 w-[90%] mx-auto">
          {solutionsData?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  navigate(item?.navigationURL);
                }}
                className="cursor-pointer even:bg-[#FDFAF7] w-full py-4"
              >
                <p className="text-center">{item?.title}</p>
                <img
                  src={item?.iconURL}
                  alt="upper right arrow"
                  className="mx-auto"
                  width={35}
                />
              </div>
            );
          })}
        </div>
      </div>

      <HomeAbout />
    </>
  );
};

export default HOC(Home);
