import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import UAE from "../assets/images/uae.png";
import close from "../assets/social-icons/close.png";
import menu from "../assets/social-icons/hambuger.png";
import { routes_path } from "../config/routesPath";

const Header = ({
  safexpayLogo,
  xIcon,
  productLogo,
  bgColor,
  productTitle,
}) => {
  const [showPages, setShowPages] = useState(false);
  const [showPagesMobile, setShowPagesMobile] = useState(false);
  const [showProductsMobile, setShowProductsMobile] = useState(false);
  const [showIndustryMobile, setShowIndustryMobile] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showHamMenu, setShowHamMenu] = useState(false);
  const [headerColor, setHeaderColor] = useState("#FFF");
  const [showSignupMenu, setShowSignupMenu] = useState(false);
  const [showLoginMenu, setShowLoginMenu] = useState(false);
  const [showSignupMenuMobile, setShowSignupMenuMobile] = useState(false);
  const [showLoginMenuMobile, setShowLoginMenuMobile] = useState(false);
  const pageRef = useRef();
  const productRef = useRef();
  const parentPageRef = useRef();
  const parentProductRef = useRef();
  const industryRef = useRef();
  const resourceRef = useRef();
  const parentResourceRef = useRef();
  const signupRef = useRef();
  const signupParentRef = useRef();
  const loginRef = useRef();
  const loginParentRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    function closePages(e) {
      if (
        (pageRef.current &&
          !pageRef.current.contains(e.target) &&
          !parentPageRef.current.contains(e.target)) ||
        (productRef.current &&
          !productRef.current.contains(e.target) &&
          !parentProductRef.current.contains(e.target)) ||
        (resourceRef.current &&
          !resourceRef.current.contains(e.target) &&
          !parentResourceRef.current.contains(e.target)) ||
        (signupRef.current &&
          !signupRef.current.contains(e.target) &&
          !signupParentRef.current.contains(e.target)) ||
        (loginRef.current &&
          !loginRef.current.contains(e.target) &&
          !loginParentRef.current.contains(e.target))
      ) {
        setShowPages(false);
        setShowProducts(false);
        setShowResources(false);
        setShowSignupMenu(false);
        setShowLoginMenu(false);
      }
    }

    function changeColor() {
      if (window.scrollY > 100) {
        setHeaderColor("#fff");
      } else {
        setHeaderColor("#FFF");
      }
    }

    document.addEventListener("mousedown", closePages);
    document.addEventListener("scroll", changeColor);

    return () => {
      document.removeEventListener("mousedown", closePages);
    };
  }, []);
  return (
    <>
      <div
        className={`${
          headerColor === "#fff"
            ? "sticky top-0 z-20 transition-all duration-500"
            : ""
        }`}
      >
        <div
          className={`py-2 px-4 lg:px-16 xl:px-20 relative z-10 flex items-center justify-between transition-all duration-500`}
          style={{
            background: `${
              window.location.pathname === "/"
                ? headerColor
                : bgColor
                ? bgColor
                : "#ffffff"
            }`,
          }}
        >
          <div
            className="text-xl font-bold font-serif drop-shadow cursor-pointer flex items-center h-[50px]"
            onClick={() => {
              navigate(routes_path.home);
              setShowHamMenu(false);
            }}
          >
            <img
              src={safexpayLogo ? safexpayLogo : logo}
              alt="logo"
              className="w-[100px] lg:max-w-[160px] lg:w-[100px] h-auto"
            />
            {xIcon && (
              <img
                src={xIcon}
                alt="logo"
                className="w-[15px] lg:w-[16px] mx-2"
              />
            )}
            {productLogo && (
              <img
                src={productLogo}
                alt="logo"
                className="w-[100px] lg:max-w-[160px] lg:w-[85px] h-auto"
              />
            )}
          </div>

          {/* Hamburger menu */}
          <div className="relative block lg:hidden">
            <div
              onClick={() => {
                setShowHamMenu(!showHamMenu);
                setShowPagesMobile(false);
                setShowProductsMobile(false);
                setShowSignupMenuMobile(false);
                setShowLoginMenuMobile(false);
              }}
            >
              <img
                src={showHamMenu ? close : menu}
                width={40}
                className="rounded"
                alt="hamburger menu"
              />
            </div>
            <ul
              className={clsx(
                "overflow-hidden transition-all duration-500 absolute mt-3 top-[38px] -right-4 w-screen font-bold z-10 shadow",
                showHamMenu ? "h-auto visible" : "h-0 invisible",
                window.location.pathname === "/aasaan"
                  ? "bg-[#181A21] text-[#ffffff]"
                  : "bg-white"
              )}
            >
              {/* <li
                className="py-4 pl-4 pr-10"
                onClick={() => {
                  navigate(routes_path.home);
                  setShowHamMenu(false);
                }}
              >
                Home
              </li> */}
              <li className="py-2 pl-4 pr-10 relative">
                <label
                  ref={parentProductRef}
                  className="cursor-pointer hover:text-secondary down-arrow flex items-center"
                  onClick={() => {
                    setShowProductsMobile(!showProductsMobile);
                    setShowPagesMobile(false);
                    setShowIndustryMobile(false);
                  }}
                >
                  Products
                </label>

                <ul
                  className={clsx(
                    "overflow-auto transition-all duration-500 w-screen right-0 px-3",
                    showProductsMobile ? "h-[235px] visible" : "h-0 invisible",
                    window.location.pathname === "/aasaan"
                      ? "bg-[#181A21]"
                      : "bg-white"
                  )}
                >
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.paymentPlatform);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Payment Platform / Aggregator
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.payouts);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Payouts
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.aasaan);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Aasaan
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.whitelabel);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Whitelabel
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.neuX);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    NeuX
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.apix);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    APIX
                  </li>
                  {/* <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.onox);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    ONOX
                  </li> */}
                </ul>
              </li>
              {/* <li
                className="py-4 pl-4 pr-10"
                onClick={() => {
                  navigate(routes_path.pricing);
                  setShowHamMenu(false);
                }}
              >
                Pricing
              </li> */}
              <li className="py-2 pl-4 pr-10 relative">
                <label
                  ref={industryRef}
                  className="cursor-pointer hover:text-secondary down-arrow flex items-center"
                  onClick={() => {
                    setShowIndustryMobile(!showIndustryMobile);
                    setShowPagesMobile(false);
                    setShowProductsMobile(false);
                    setShowSignupMenuMobile(false);
                    setShowLoginMenuMobile(false);
                  }}
                >
                  Industries
                </label>

                <ul
                  className={clsx(
                    "overflow-auto transition-all duration-500 w-screen right-0 px-3",
                    showIndustryMobile ? "h-[190px] visible" : "h-0 invisible",
                    window.location.pathname === "/aasaan"
                      ? "bg-[#181A21]"
                      : "bg-white"
                  )}
                >
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.eCommerce);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    E-Commerce
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.education);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Education
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.society);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Society
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.retail);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Retail
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.industries);
                      setShowProductsMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Other Industries
                  </li>
                </ul>
              </li>
              <li
                className="py-4 pl-4 pr-10 relative"
                onClick={() => {
                  navigate(routes_path.blog);
                  setShowHamMenu(false);
                }}
              >
                Blog
              </li>
              <li className="py-2 pl-4 pr-10 relative">
                <label
                  ref={parentPageRef}
                  className="cursor-pointer hover:text-secondary down-arrow flex items-center"
                  onClick={() => {
                    setShowPagesMobile(!showPagesMobile);
                    setShowProductsMobile(false);
                    setShowIndustryMobile(false);
                    setShowSignupMenuMobile(false);
                    setShowLoginMenuMobile(false);
                  }}
                >
                  Resources
                </label>
                <ul
                  className={clsx(
                    "overflow-auto transition-all duration-500 w-screen right-0 px-3",
                    showPagesMobile ? "h-[275px] visible" : "h-0 invisible",
                    window.location.pathname === "/aasaan"
                      ? "bg-[#181A21]"
                      : "bg-white"
                  )}
                >
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.partnerProgram);
                      setShowPagesMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Partner's Program
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.aboutUs);
                      setShowPagesMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    About Us
                  </li>

                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.contactUs);
                      setShowPagesMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Contact Us
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.career);
                      setShowPagesMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Career
                  </li>
                  {/* <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.mediaAndAwards);
                      setShowPagesMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Media & Awards
                  </li> */}
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.termsAndCondition);
                      setShowPagesMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Terms & Conditions
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.FAQ);
                      setShowPagesMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    FAQ
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.privacyPoliciy);
                      setShowPagesMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Privacy Policy
                  </li>
                </ul>
              </li>
              <li className="py-4 pl-4 pr-10">
                <label
                  ref={signupParentRef}
                  className="cursor-pointer hover:text-secondary flex items-center"
                  // removed class down-arrow
                  onClick={() => {
                    // setShowSignupMenuMobile(!showSignupMenuMobile);
                    navigate(routes_path?.contactUs);
                    setShowProductsMobile(false);
                    setShowIndustryMobile(false);
                    setShowPagesMobile(false);
                    setShowLoginMenuMobile(false);
                  }}
                >
                  Sign up
                </label>
                <ul
                  className={clsx(
                    "overflow-auto transition-all duration-500 w-screen right-0 px-3",
                    showSignupMenuMobile
                      ? "h-[115px] visible"
                      : "h-0 invisible",
                    window.location.pathname === "/aasaan"
                      ? "bg-[#181A21]"
                      : "bg-white"
                  )}
                >
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.paymentAggregatorSignup);
                      setShowSignupMenuMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    PG
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.payoutSignup);
                      setShowSignupMenuMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Payouts
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.contactUs);
                      setShowSignupMenuMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Others
                  </li>
                </ul>
              </li>
              <li className="py-2 pl-4 pr-10">
                <label
                  ref={loginParentRef}
                  className="cursor-pointer hover:text-secondary flex items-center"
                  // removed class down-arrow
                  onClick={() => {
                    navigate(routes_path?.contactUs);
                    // setShowLoginMenuMobile(!showLoginMenuMobile);
                    setShowProductsMobile(false);
                    setShowIndustryMobile(false);
                    setShowPagesMobile(false);
                    setShowSignupMenuMobile(false);
                  }}
                >
                  Login
                </label>
                <ul
                  className={clsx(
                    "overflow-auto transition-all duration-500 w-screen right-0 px-3",
                    showLoginMenuMobile ? "h-[115px] visible" : "h-0 invisible",
                    window.location.pathname === "/aasaan"
                      ? "bg-[#181A21]"
                      : "bg-white"
                  )}
                >
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      window.open(routes_path.pgDmoLogin);
                      setShowLoginMenuMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    PG
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      window.open(routes_path.payoutDmoLogin);
                      setShowLoginMenuMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Payouts
                  </li>
                  <li
                    className="py-2 px-4 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate(routes_path.contactUs);
                      setShowLoginMenuMobile(false);
                      setShowHamMenu(false);
                    }}
                  >
                    Others
                  </li>
                </ul>
              </li>
              {/* <li className="flex items-center gap-4 p-4 font-normal">
                <span>
                  <img
                    src={UAE}
                    alt="UAE"
                    width={40}
                    className="cursor-pointer"
                    // onClick={() => {
                    //   window.open("https://www.safexpay.ae/", "_blank");
                    // }}
                  />
                </span>
              </li> */}
            </ul>
          </div>

          <div className="hidden lg:block">
            <ul
              className={clsx(
                "flex gap-8 text-sm font-semibold font-MyriadPro items-center",
                bgColor ? "text-[#FFFFFF]" : "text-primary"
              )}
            >
              {/* <li
                className="cursor-pointer hover:text-secondary "
                onClick={() => {
                  navigate(routes_path.home);
                }}
              >
                Home
              </li> */}
              <li className="cursor-pointer relative ">
                <label
                  ref={parentProductRef}
                  className="cursor-pointer hover:text-secondary down-arrow flex items-center"
                  onClick={() => {
                    setShowProducts(!showProducts);
                    setShowPages(false);
                    setShowResources(false);
                  }}
                >
                  Products
                </label>
                {showProducts && (
                  <ul
                    ref={productRef}
                    className={clsx(
                      "absolute top-10 z-10 w-max -left-6 shadow-[0px_0px_14px_1px_#44444444] bg-[#FFFFFF]"
                    )}
                    style={{ color: bgColor ? bgColor : "#060606" }}
                  >
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.paymentPlatform);
                      }}
                    >
                      Payment Platform / Aggregator
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.payouts);
                      }}
                    >
                      Payouts
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.aasaan);
                      }}
                    >
                      Aasaan
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.whitelabel);
                      }}
                    >
                      Whitelabel
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.neuX);
                      }}
                    >
                      NeuX
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.apix);
                      }}
                    >
                      APIX
                    </li>
                    {/* <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.onox);
                      }}
                    >
                      ONOX
                    </li> */}
                  </ul>
                )}
              </li>
              {/* <li
                className="cursor-pointer hover:text-secondary "
                onClick={() => {
                  navigate(routes_path.pricing);
                }}
              >
                Pricing
              </li> */}
              <li className="cursor-pointer relative">
                <label
                  ref={parentPageRef}
                  className="cursor-pointer hover:text-secondary down-arrow flex items-center"
                  onClick={() => {
                    setShowPages(!showPages);
                    setShowProducts(false);
                    setShowResources(false);
                  }}
                >
                  Industries
                </label>
                {showPages && (
                  <ul
                    ref={pageRef}
                    className={clsx(
                      "absolute top-10 z-10 w-max -left-6 shadow-[0px_0px_14px_1px_#44444444] bg-[#FFFFFF]"
                    )}
                    style={{ color: bgColor ? bgColor : "#060606" }}
                  >
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.eCommerce);
                        setShowPages(false);
                      }}
                    >
                      E-commerce
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.education);
                        setShowPages(false);
                      }}
                    >
                      Education
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.society);
                        setShowPages(false);
                      }}
                    >
                      Society
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.retail);
                        setShowPages(false);
                      }}
                    >
                      Retail
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.industries);
                        setShowPages(false);
                      }}
                    >
                      Other Industries
                    </li>
                  </ul>
                )}
              </li>
              <li
                className="cursor-pointer hover:text-secondary"
                onClick={() => {
                  navigate(routes_path.blog);
                }}
              >
                Blog
              </li>
              <li className="cursor-pointer relative">
                <label
                  ref={parentResourceRef}
                  className="cursor-pointer hover:text-secondary down-arrow flex items-center"
                  onClick={() => {
                    setShowResources(!showResources);
                    setShowProducts(false);
                    setShowPages(false);
                  }}
                >
                  Resources
                </label>
                {showResources && (
                  <ul
                    ref={resourceRef}
                    className={clsx(
                      "absolute top-10 z-10 w-max -left-6 shadow-[0px_0px_14px_1px_#44444444] bg-[#FFFFFF]"
                    )}
                    style={{ color: bgColor ? bgColor : "#060606" }}
                  >
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.partnerProgram);
                        setShowResources(false);
                      }}
                    >
                      Partner's Program
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.aboutUs);
                        setShowResources(false);
                      }}
                    >
                      About Us
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.contactUs);
                        setShowResources(false);
                      }}
                    >
                      Contact Us
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.career);
                        setShowResources(false);
                      }}
                    >
                      Career
                    </li>
                    {/* <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.mediaAndAwards);
                        setShowResources(false);
                      }}
                    >
                      Media & Awards
                    </li> */}
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.termsAndCondition);
                        setShowResources(false);
                      }}
                    >
                      Terms & Conditions
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.FAQ);
                        setShowResources(false);
                      }}
                    >
                      FAQ
                    </li>
                    <li
                      className="py-4 px-6 hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate(routes_path.privacyPoliciy);
                        setShowResources(false);
                      }}
                    >
                      Privacy Policy
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <ul className="flex items-center gap-4">
                  <li className="flex items-center gap-4 font-normal relative">
                    <label
                      ref={loginParentRef}
                      className={clsx(
                        "cursor-pointer hover:scale-95 transition-all py-[6px] px-[26px] rounded-[4px]",
                        window.location.pathname === "/aasaan"
                          ? "bg-white border-[1px] border-[#5437AF] text-[#5437AF]"
                          : window.location.pathname === "/onox"
                          ? "bg-[#3F3F3F]"
                          : "bg-primary text-white border-2 border-primary"
                      )}
                      onClick={() => {
                        // window.open(routes_path.login);
                        // setShowLoginMenu(!showLoginMenu);
                        navigate(routes_path?.contactUs)
                      }}
                    >
                      Login
                    </label>
                    {showLoginMenu && (
                      <ul
                        ref={loginRef}
                        className={clsx(
                          "absolute top-10 z-10 w-max left-0 shadow-[0px_0px_14px_1px_#44444444] bg-[#FFFFFF] font-semibold text-sm"
                        )}
                        style={{ color: bgColor ? bgColor : "#060606" }}
                      >
                        <li
                          className="py-4 px-6 hover:bg-secondary hover:text-white cursor-pointer"
                          onClick={() => {
                            window.open(routes_path.pgDmoLogin);
                            setShowLoginMenu(false);
                          }}
                        >
                          PG
                        </li>
                        <li
                          className="py-4 px-6 hover:bg-secondary hover:text-white cursor-pointer"
                          onClick={() => {
                            window.open(routes_path.payoutDmoLogin);
                            setShowLoginMenu(false);
                          }}
                        >
                          Payouts
                        </li>
                        <li
                          className="py-4 px-6 hover:bg-secondary hover:text-white cursor-pointer"
                          onClick={() => {
                            navigate(routes_path.contactUs);
                            setShowLoginMenu(false);
                          }}
                        >
                          Others
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="flex items-center gap-4 font-normal relative">
                    <label
                      ref={signupParentRef}
                      className={clsx(
                        "cursor-pointer hover:scale-95 transition-all py-[6px] px-[26px] rounded-[4px]",
                        window.location.pathname === "/aasaan"
                          ? "bg-gradient-to-br from-[#5437AF] to-[#265FE0]"
                          : window.location.pathname === "/onox"
                          ? `bg-white text-[#0C99D5]`
                          : "bg-white text-secondary border-2 border-secondary"
                      )}
                      onClick={() => {
                        // window.open(routes_path.signup);
                        navigate(routes_path?.contactUs);
                        // setShowSignupMenu(!showSignupMenu);
                      }}
                    >
                      Sign Up
                    </label>
                    {showSignupMenu && (
                      <ul
                        ref={signupRef}
                        className={clsx(
                          "absolute top-10 z-10 w-max left-0 shadow-[0px_0px_14px_1px_#44444444] bg-[#FFFFFF] font-semibold text-sm"
                        )}
                        style={{ color: bgColor ? bgColor : "#060606" }}
                      >
                        <li
                          className="py-4 px-6 hover:bg-secondary hover:text-white cursor-pointer"
                          onClick={() => {
                            window.open(routes_path.paymentAggregatorSignup);
                            setShowSignupMenu(false);
                          }}
                        >
                          PG
                        </li>
                        <li
                          className="py-4 px-6 hover:bg-secondary hover:text-white cursor-pointer"
                          onClick={() => {
                            window.open(routes_path.payoutSignup);
                            setShowSignupMenu(false);
                          }}
                        >
                          Payouts
                        </li>
                        <li
                          className="py-4 px-6 hover:bg-secondary hover:text-white cursor-pointer"
                          onClick={() => {
                            navigate(routes_path.contactUs);
                            setShowSignupMenu(false);
                          }}
                        >
                          Others
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </li>
              {/* <li className="flex items-center gap-4 font-normal">
                <span>
                  <img
                    src={UAE}
                    alt="UAE"
                    width={30}
                    // className="cursor-pointer"
                    // onClick={() => {
                    //   window.open("https://www.safexpay.ae/", "_blank");
                    // }}
                  />
                </span>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
