import clsx from "clsx";
import React, { useEffect, useState } from "react";
import benefit from "../../assets/images/partner-images/why-partner-with-us.png";
import yourBrandImg3 from "../../assets/images/partner-images/your-brand-img-3.png";
import PartnerModal from "../../common-component/modal/PartnerModal";
import HOC from "../../component/HOC";
import {
  GetMoreData,
  PartnerWithUsData,
  ultimateCollectionData,
  yourBrandData,
} from "./PartnerProgramData";

const PartnerProgram = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.title = "Partner's Program - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      {showModal && (
        <PartnerModal
          showModal={showModal}
          setShowModal={setShowModal}
          pageName={"partner_page"}
        />
      )}
      <div>
        <div className="flex partner-banner p-8 lg:p-20 flex-col gap-4 lg:flex-row justify-end items-end md:justify-start md:items-start">
          <div className="lg:w-[60%]">
            <p className="text-[24px] lg:text-[40px] text-primary font-extrabold leading-[40px]">
              Join Touras Partner Program & Expand Your Business
            </p>
            <p className="text-[14px] lg:text-[18px] text-primary py-3 lg:py-6 leading-[27px]">
              Provide your clients with a wide array of payment solutions and
              start earning.
            </p>
            <button
              className="text-secondary border-[1px] border-secondary mt-6 bg-[#FFFFFF] text-[12px] lg:text-[16px] py-2 px-10 rounded-[20px] hover:scale-95 transition-all w-[60%]"
              onClick={() => setShowModal(true)}
            >
              Become A Partner
            </button>
          </div>
        </div>

        {/* <div className="py-10 partner-with-us-bg">
          <p className="text-primary text-[24px] lg:text-[50px] font-black text-center">
            Who Can Partner With Us?
          </p>
          <p className="text-primary text-[20px] lg:text-[30px] text-center pb-16">
            Made For All Businesses
          </p>

          <div className="flex flex-wrap justify-center gap-20 lg:m-4 lg:my-20 lg:mx-20">
            {PartnerWithUsData?.map((item, index) => {
              return (
                <div className="lg:w-[45%] mx-4 md:mx-0" key={index}>
                  <img
                    src={item?.iconUrl}
                    alt={item.title}
                    className="mx-auto"
                  />
                  <p className="text-center text-primary text-[20px] lg:text-[30px] md:h-[90px] my-4 font-bold">
                    {item?.title}
                  </p>
                  <p className="text-center text-primary text-[18px] lg:text-[30px] md:mt-10">
                    {item?.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div> */}

        <div className="px-1 py-10 bg-background">
          <p className="text-primary text-[24px] lg:text-[50px] font-extrabold text-center">
            Who can Partner With Us?
          </p>
          <div className="grid md:grid-cols-3 lg:gap-x-10 md:gap-x-4 gap-y-20 mt-10 lg:mx-20 md:ml-4">
            {PartnerWithUsData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="mx-4 md:mx-auto grid gap-4 text-center md:text-start"
                >
                  <div className="m-auto md:m-0">
                    <img src={item.iconUrl} alt={item.title} />
                  </div>
                  <p className="text-primary font-bold text-[20px] md:text-[24px]">
                    {item.title}
                  </p>
                  <p className="text-content text-[18px] md:text-[20px]">
                    {item.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="py-10 md:flex gap-10  m-8 lg:mx-20 lg:my-10">
          <div className="lg:w-[40%]">
            <img src={benefit} alt="benefits" className="rounded-[12px]" />
          </div>
          <div className="lg:w-[50%]">
            <p className="text-primary text-[24px] lg:text-[40px] font-extrabold">
              Why Partner with Touras
            </p>
            <ul className="text-content text-[18px] lg:text-[24px] font-semibold list-disc mt-6">
              <li className="py-4"> Quick and easy onboarding for partners</li>
              <li className="py-4">
                Real-time tracking of merchant transactions
              </li>
              <li className="py-4">Unlimited earnings and rewards</li>
            </ul>
          </div>
        </div>

        <div className="px-1 py-10 bg-background">
          <p className="text-primary text-[24px] lg:text-[50px] font-extrabold text-center">
            Get more with <span className="text-secondary">Touras</span>
          </p>

          <div className="grid md:grid-cols-2 lg:gap-x-10 md:gap-x-4 gap-y-20 mt-10 lg:mx-20 md:ml-4">
            {GetMoreData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="lg:w-[400px] mx-4 md:mx-auto grid gap-4 text-center md:text-start"
                >
                  <div className="m-auto md:m-0">
                    <img src={item.iconUrl} alt={item.title} />
                  </div>
                  <p className="text-primary font-bold text-[20px] md:text-[24px]">
                    {item.title}
                  </p>
                  <p className="text-content text-[18px] md:text-[20px]">
                    {item.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="py-10">
          <p className="text-primary text-[24px] lg:text-[50px] font-extrabold text-center">
            Our Platform, Your <span className="text-secondary">Brand</span>
          </p>
          <div className="grid gap-20">
            {yourBrandData?.map((item, index) => {
              return (
                <div
                  className={clsx(
                    "flex lg:justify-between items-center lg:gap-10 md:gap-0 gap-4 m-4 lg:my-10 bg-background relative rounded-[50px] p-10 md:w-[80%] lg:mx-auto md:h-[300px]",
                    item?.imgDirection === "left"
                      ? "flex-col-reverse lg:flex-row"
                      : "flex-col-reverse lg:flex-row-reverse"
                  )}
                  key={index}
                >
                  <div className="lg:w-[50%]">
                    <img
                      src={item.imageUrl}
                      alt={item.title}
                      className={clsx(
                        "md:absolute w-[200px] md:w-[300px] lg:w-[500px] max-h-[500px] object-contain",
                        item.imgDirection === "left"
                          ? "top-[50%] md:translate-y-[-50%] lg:-left-10 left-[11.5rem] md:left-[27.5rem]"
                          : "top-[50%] md:translate-y-[-50%] md:-right-10 -right-14"
                      )}
                    />
                  </div>
                  <div className="lg:w-[50%] text-primary">
                    <p className="md:text-[30px] text-[22px] font-bold">
                      {item.title}
                    </p>
                    <p className="text-[18px] md:text-[24px] md:w-[80%]">
                      {item.description}
                    </p>
                  </div>
                </div>
              );
            })}
            <div
              className={clsx(
                "flex flex-col-reverse lg:justify-between items-center gap-4 lg:gap-10 md:gap-0 m-4 lg:my-10 bg-background relative rounded-[50px] p-10 w-[90%] md:w-[60%] mx-auto md:ml-auto md:mr-[130px] md:h-[300px] lg:flex-row"
              )}
            >
              <div className="md:w-[30%]">
                <img
                  src={yourBrandImg3}
                  alt={"Checkout pages"}
                  className={clsx(
                    "md:absolute w-[200px] md:w-[300px] lg:w-[500px] max-h-[500px] object-contain top-[50%] md:translate-y-[-50%] md:-left-[15rem]"
                  )}
                />
              </div>
              <div className="w-full text-primary ml-auto md:ml-auto">
                <p className="md:text-[30px] text-[22px] font-bold">
                  Checkout pages
                </p>
                <p className="text-[18px] md:text-[24px] md:w-[80%]">
                  Customize the checkout page with your brand logo and colors to
                  foster customer trust.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 mt-10">
          <p className="text-primary text-[24px] lg:text-[50px] font-extrabold text-center">
            Our Ultimate Collection of{" "}
            <span className="text-secondary">Products</span>
          </p>
          <p className="text-content text-[18px] md:text-[30px] max-w-[75%] mx-auto text-center">
            We offer various payment solutions to help your affiliates collect
            payments with ease thanks to our Payment Aggregator Products
          </p>

          <div className="flex flex-wrap justify-center gap-10 m-4 lg:my-10 lg:mx-20 py-4 lg:py-0">
            {ultimateCollectionData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="w-[300px] h-[300px] flex flex-col justify-center items-center gap-4 bg-backgroundLight rounded-[20px]"
                >
                  <div className="h-[100px] flex items-center">
                    <img
                      src={item.iconUrl}
                      alt={item.label}
                      className="mx-auto max-w-[100px] max-h-[100px]"
                    />
                  </div>
                  <p className="text-[30px] font-bold text-primary text-center">
                    {item.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="prt-pgr-footer-bg py-10 text-center px-4 lg:max-w-[80%] md:max-w-[95%] mx-auto rounded-[8px]">
          <p className="text-white text-[22px] lg:text-[30px] font-bold">
            Join the Touras Partner Program
          </p>
          <p className="text-white text-[20px] lg:text-[24px] font-light px-10">
            Offer your customers an unparalleled payment experience and take
            your business to new heights by becoming a Touras partner.
          </p>
          <button
            className="mt-6 font-bold bg-primary text-white py-2 px-10 rounded-[80px] hover:scale-95 transition-all"
            onClick={() => setShowModal(true)}
          >
            Become a Touras Partner
          </button>
        </div>
      </div>
    </>
  );
};

export default HOC(PartnerProgram);
