import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { toastConfig } from "react-simple-toasts";
import { AppConfig } from "../../apiservice/AppConfig";
import next from "../../assets/images/testimonial-images/next.png";
import portrait from "../../assets/images/testimonial-images/portrait-happy-smiling_WteIIow 1.png";
import previous from "../../assets/images/testimonial-images/previous.png";
import Button from "../../common-component/button/Button";
import RadioButton from "../../common-component/radio-button/RadioButton";
import TextInput from "../../common-component/textinput/TextInput";
import { routes_path } from "../../config/routesPath";
import { featuredData, testimonialData } from "./homeData";
import fetchApi from "../../apiservice/ApiService";
import SelectDropdown from "../../common-component/select-dropdown/SelectDropdown";
import subscribeBg from "../../assets/images/subscribe-bg.png";

const productsArray = [
  "Payment Platform",
  "Payouts",
  "NeuX",
  "Connected Banking",
  "Expense Management",
  "Smart Routing",
  "Aasaan Checkout",
  "Whitelabel",
  "APIX",
  "Others",
];

const HomeAbout = () => {
  const navigate = useNavigate();
  const [slide, setSlide] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [subscribeLoader, setSubscribeLoader] = useState(false);
  const [error, setError] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recentBlogsData, setRecentBlogsData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [form, setForm] = useState({
    first_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [emailAddress, setEmailAddress] = useState("");
  const [checkProduct, setCheckProduct] = useState(false);
  const productRef = useRef(null);

  useEffect(() => {
    const handleCloseProductModal = (e) => {
      if (productRef?.current && !productRef?.current?.contains(e.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleCloseProductModal);

    return () => {
      document.removeEventListener("mousedown", handleCloseProductModal);
    };
  }, []);

  const handleSelectProduct = (data) => {
    let tempArr = [...product];
    if (tempArr.includes(data)) {
      tempArr = tempArr.filter((ele) => ele !== data);
    } else {
      tempArr.push(data);
    }
    setProduct(tempArr);
    if (tempArr?.length == 0) {
      setCheckProduct(true);
      return;
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "email") {
      const mail = e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      setForm({ ...form, [e.target.name]: e.target.value });
      if (mail) {
        setIsValidEmail(true);
        setError({ ...error, email: "" });
      } else if (e.target.value === "") {
        setForm({ ...form, [e.target.name]: "" });
        setError({ ...error, email: "" });
      } else {
        setError({ ...error, email: "Please enter a valid email address." });
      }
    } else if (e.target.name === "phone") {
      const tel = e.target.value.match(/^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/gm);
      setForm({ ...form, [e.target.name]: e.target.value });
      if (tel) {
        setIsValidPhone(true);
        setError({ ...error, phone: "" });
      } else if (e.target.value === "") {
        setForm({ ...form, [e.target.name]: "" });
        setError({ ...error, phone: "" });
      } else {
        setError({ ...error, phone: "Please enter a valid phone number." });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const handleSubscribeChange = (e) => {
    const mail = e.target.value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    setEmailAddress(e.target.value);
    if (mail) {
      setIsValidEmail(true);
      setError(false);
    } else if (e.target.value === "") {
      setEmailAddress("");
      setError(false);
    } else {
      setError(true);
    }
  };

  const handlePrevious = () => {
    setSlide(slide - 1);
  };

  const handleNext = () => {
    setSlide(slide + 1);
  };

  const handleSubmit = () => {
    setLoader(true);
    if (
      product?.length === 0 ||
      form?.email === "" ||
      form?.first_name === "" ||
      form?.phone === "" ||
      form?.message === "" ||
      selectedCity === null ||
      selectedState === null
    ) {
      toastConfig({ theme: "failure" });
      toast("Please fill all the mandatory fields to send message.");
      setTimeout(() => {
        setLoader(false);
      }, 500);
      return false;
    }
    if (isValidEmail && isValidPhone) {
      const _form = new FormData();
      _form.append("first_name", form?.first_name);
      _form.append("email", form?.email);
      _form.append("phone", form?.phone);
      _form.append("message", form?.message);
      _form.append("page_name", "home_page");
      _form.append("city", selectedCity?.id);
      _form.append("state", selectedState?.id);
      product?.forEach((item, index) => {
        _form.append(`product[${index}]`, item);
      });
      axios
        .post(AppConfig?.baseUrl + "customer/landing/contact-us", _form)
        .then((response) => {
          setForm({
            first_name: "",
            email: "",
            phone: "",
            message: "",
          });
          setProduct("");
          setSelectedCity(null);
          setSelectedState(null);
          document
            .querySelectorAll(".common-component")
            .forEach((component) => {
              component.checked = false;
            });
          toastConfig({ theme: "success" });
          toast(response?.data?.message);
          setIsValidEmail(false);
          setIsValidPhone(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error?.response?.data?.errors);
          setShowOptions(true);
          toastConfig({ theme: "failure" });
          if (error?.response?.data?.message) {
            toast(error?.response?.data?.message);
          } else {
            toast("Email not sent.");
          }
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  };

  const handleSubscribeSubmit = () => {
    setSubscribeLoader(true);
    if (emailAddress === "") {
      toastConfig({ theme: "failure" });
      toast("please enter a email.");
      setTimeout(() => {
        setSubscribeLoader(false);
      }, 500);
      return false;
    }

    if (isValidEmail) {
      const _form = new FormData();
      _form.append("email", emailAddress);
      _form.append("page_name", "home");
      axios
        .post(AppConfig.baseUrl + "customer/landing/subscribe", _form)
        .then((response) => {
          setError(false);
          toastConfig({ theme: "success" });
          toast(response?.data?.message);
        })
        .catch((err) => {
          console.log(err);
          toastConfig({ theme: "failure" });
          if (err?.response?.data?.message) {
            toast(err?.response?.data?.message);
          } else {
            toast("Email not sent.");
          }
        })
        .finally(() => {
          setSubscribeLoader(false);
          setEmailAddress("");
          setIsValidEmail(false);
        });
    } else {
      toastConfig({ theme: "failure" });
      toast("Enter email to subscribe.");
      setSubscribeLoader(false);
    }
  };

  const handleGetCities = (id) => {
    fetchApi
      .getCities(id)
      .then((response) => {
        let newCitiesArray = [];
        response?.data?.data?.forEach((item) => {
          newCitiesArray.push({ id: item?.id, label: item?.name });
        });
        setCitiesData(newCitiesArray);
      })
      .catch((error) => {
        console.log(error);
        toastConfig({ theme: "failure" });
        toast(error?.message);
      });
  };

  const handleGetStates = () => {
    fetchApi
      .getStates()
      .then((response) => {
        let newStateArray = [];
        response?.data?.data?.forEach((item) => {
          newStateArray.push({ id: item?.id, label: item?.name });
        });
        setStatesData(newStateArray);
      })
      .catch((error) => {
        toastConfig({ theme: "failure" });
        toast(error?.message);
      });
  };

  useEffect(() => {
    handleGetRecentBlogs();
    handleGetStates();
  }, []);

  const handleGetRecentBlogs = () => {
    setLoading(true);
    fetchApi
      .getRecentBlogs()
      .then((res) => {
        setRecentBlogsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* Ready to begin */}
      <section className="grid lg:flex items-start py-10 px-4 md:px-20 get-started-bg font-MyriadPro">
        <div className="lg:w-[35%] pt-6 rounded-[10px]">
          <p className="text-content text-[28px] md:text-[40px] font-bold">
            Ready to be resilient?{" "}
          </p>
          <p className="text-content text-[14px] md:text-[16px] border-b border-black border-dashed pb-5">
            Create your account or contact our experts to explore custom
            solutions.
          </p>
          <div className="flex items-center gap-4 mt-8">
            <div>
              <p className="text-[28px] md:text-[44px] font-semibold">
                24/7<span className="text-secondary">*</span>
              </p>
              <p className="text-[#00000080] text-[14px] md:text-[18px]">
                Service Availability
              </p>
            </div>
            <div>
              <p className="text-[28px] md:text-[44px] font-semibold">
                100<span className="text-secondary">+</span>
              </p>
              <p className="text-[#00000080] text-[14px] md:text-[18px]">
                Banking Integration
              </p>
            </div>
          </div>
        </div>
        <div className="grid gap-2 px-4 py-10 md:px-10 md:py-0 lg:w-[65%] font-poppins">
          <div className="grid lg:grid-cols-2 gap-x-6 lg:gap-x-4">
            <TextInput
              name={"first_name"}
              label={"First Name"}
              type={"text"}
              value={form?.first_name}
              required
              onChange={(e) => {
                handleChange(e);
                if (e.target.name !== "") {
                  setError({ ...error, first_name: "" });
                }
              }}
              error={Boolean(error?.first_name)}
              helperText={error?.first_name}
            ></TextInput>
            <TextInput
              name={"email"}
              label={"Email"}
              type={"email"}
              required
              value={form?.email}
              onChange={(e) => {
                handleChange(e);
              }}
              error={Boolean(error?.email)}
              helperText={error?.email}
            ></TextInput>
            <TextInput
              name={"phone"}
              label={"Phone Number"}
              value={form?.phone}
              type={"number"}
              required
              onChange={(e) => {
                handleChange(e);
              }}
              error={Boolean(error?.phone)}
              helperText={error?.phone}
            ></TextInput>
            <SelectDropdown
              label={"State"}
              placeHolder="Select State"
              data={statesData}
              value={selectedState?.label}
              onClick={(val) => {
                setSelectedState(val);
                handleGetCities(val?.id);
              }}
              required
              error={Boolean(error?.state)}
              helperText={error?.state}
            />
            <SelectDropdown
              label={"City"}
              placeHolder="Select City"
              message="Please choose a state in order to select a city."
              data={citiesData}
              value={selectedCity?.label}
              onClick={(val) => {
                setSelectedCity(val);
              }}
              required
              error={Boolean(error?.city)}
              helperText={error?.city}
            />

            <div>
              <div className="text-[#212121] grid">
                <label
                  className={clsx(
                    "text-[14px] font-medium flex items-center",
                    checkProduct ? "text-red-500" : ""
                  )}
                >
                  Select Product
                  <span className="text-secondary">*</span>
                </label>
              </div>
              <div
                className={clsx(
                  "relative h-[45px] border-[1px] rounded-[8px] px-2 py-1",
                  checkProduct ? "border-red-500" : "border-[#A5A5A5]"
                )}
              >
                <div
                  className={clsx(
                    "flex items-center justify-between cursor-pointer text-[14px] h-[35px] text-[#212121] font-medium"
                  )}
                  onClick={() => setShowOptions(!showOptions)}
                >
                  {product?.length > 0 ? (
                    <p className="flex gap-2 items-center">
                      {product?.slice(0, 1)?.map((item, index) => {
                        return (
                          <label
                            key={index}
                            className="border border-secondary py-1 px-2 bg-background rounded-[20px] text-secondary"
                          >
                            {item}
                          </label>
                        );
                      })}
                      {product?.length >= 2 && (
                        <label className="border border-secondary py-1 px-2 bg-background rounded-[20px] text-secondary">
                          +{product?.length - 1}
                        </label>
                      )}
                    </p>
                  ) : (
                    <p className="text-[14px] font-medium flex items-center text-[#999]">
                      Select
                    </p>
                  )}
                  <span className="triangle-down mr-8"></span>
                </div>
                {showOptions && (
                  <div
                    ref={productRef}
                    className="absolute top-[44px] left-0 z-10 w-full bg-white border border-[#6B6B6B] grid max-h-[200px] h-auto overflow-auto"
                  >
                    {productsArray?.map((item, index) => {
                      return (
                        <div key={index} className="py-2 px-4">
                          <label className="flex gap-2 items-center cursor-pointer text-[#6B7888]">
                            <input
                              type="checkbox"
                              checked={product?.includes(item)}
                              onClick={() => {
                                handleSelectProduct(item);
                              }}
                            />
                            {item}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid text-[#212121] text-[14px]">
            <label
              className={clsx(
                "font-medium",
                Boolean(error?.message) ? "text-red-500" : ""
              )}
            >
              Message <span className="text-secondary">*</span>
            </label>
            <textarea
              name="message"
              placeholder="Write your message.."
              className={clsx(
                "outline-none p-2 border-[1px] resize-none rounded-[8px]",
                Boolean(error?.message) ? "border-red-500" : "border-[#A5A5A5]"
              )}
              value={form?.message}
              onChange={(e) => {
                handleChange(e);
                if (e.target.name !== "") {
                  setError({ ...error, message: "" });
                }
              }}
            ></textarea>
            {Boolean(error?.message) && (
              <p className="text-[12px] text-red-500 mt-1">{error?.message}</p>
            )}
          </div>
          <div className="flex justify-end">
            {loader ? (
              <p
                className="bg-primary text-white py-2 px-4 flex justify-center items-center rounded-md w-[150px]"
                onClick={() => {
                  toastConfig({ theme: "failure" });
                  toast("please wait! we are processing your request.");
                }}
              >
                <span className="loader"></span>
              </p>
            ) : (
              <Button
                text={"Send Message"}
                backgroundColor={"#060606"}
                onClick={() => {
                  handleSubmit();
                }}
              ></Button>
            )}
          </div>
        </div>
      </section>

      {/* Blog section */}
      <section className="py-14 px-4 md:px-20 justify-center">
        <div className="font-MyriadPro">
          <div className="">
            <p className="text-content text-[28px] md:text-[35px] text-center md:text-left lg:text-[38px] font-bold px-4">
              Our Knowledge Center
            </p>
          </div>

          {loading ? (
            <div className="grid lg:grid-cols-3 gap-4 lg:gap-6 relative my-4">
              {[...Array(3)]?.map((_, index) => {
                return (
                  <div
                    key={index}
                    className="skeleton--img h-[250px!important]"
                  >
                    <span></span>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="grid lg:grid-cols-3 gap-4 lg:gap-6 relative my-4">
              {recentBlogsData?.length > 0 &&
                recentBlogsData?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="font-MyriadPro relative overflow-hidden group rounded-[12px] bg-[#FDF4F4]"
                        key={index}
                      >
                        <img
                          src={item["image_url"]}
                          alt="Sales"
                          className="mx-auto w-auto cursor-pointer group-hover:scale-110 transition-all duration-500"
                          onClick={() => {
                            navigate(
                              `/blog/${item["title"]
                                .toLowerCase()
                                .replace(/[\s,-/!:@#$%^*()"?`]+/g, "-")
                                .replace(/[\s,-/!:@#$%^*()"?`]$/g, "")}/${
                                item?.id
                              }`,
                              {
                                state: {
                                  blog: item,
                                },
                              }
                            );
                          }}
                        />
                        <p className="text-[#000] py-1 px-4 text-[18px] lg:text-[20px] mt-4 font-bold cursor-pointer w-full line-clamp-1">
                          {item["title"]}
                        </p>
                        <p className="text-[#6B6B6B] px-4 pb-2">
                          {item["description"]}
                        </p>
                      </div>
                    </>
                  );
                })}
            </div>
          )}
        </div>
      </section>

      {/* Choose Touras */}
      <section className="relative mx-4 md:mx-20 my-10 font-MyriadPro">
        <img src={subscribeBg} alt="subscribe background" />
        <div className="bg-[#FDF4F4] grid gap-3 justify-center max-w-[600px] mx-auto md:absolute md:right-10 top-[50%] md:-translate-y-[50%] p-4 md:p-8 rounded-[16px]">
          <p className="text-[24px] lg:text-[40px] font-bold m-0">
            Subscribe to get updates
          </p>
          <p className="text-[14px] lg:text-[18px] text-[#6B6B6B] m-0">
            Subscribe to our newsletter for valuable insights and updates on
            business strategies
          </p>
          <div className="rounded-[18px] bg-white border-[4px] border-secondary p-4 grid gap-3">
            <p className="text-[#344054]">Email</p>
            <input
              type="email"
              placeholder="Enter your email"
              className="border border-[#D0D5DD] px-4 py-2 rounded-[8px]"
              onChange={(e) => handleSubscribeChange(e)}
            />
            <button
              className="bg-primary text-white py-3 px-4 w-max rounded-[8px]"
              onClick={() => {
                // navigate(routes_path?.contactUs);
                handleSubscribeSubmit();
              }}
            >
              Subscribe
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
