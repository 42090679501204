import parser from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import calendar from "../../assets/social-icons/calendar.png";
import leftArr from "../../assets/social-icons/left-arrow.png";
import comment from "../../assets/social-icons/message.png";
import rightArr from "../../assets/social-icons/right-arrow.png";
import shareOnFB from "../../assets/social-icons/shareOnFB.png";
import shareOnLinkedIn from "../../assets/social-icons/shareOnLinkedin.png";
import shareOnTwitter from "../../assets/social-icons/shareOnTwitter.png";
import HOC from "../../component/HOC";
import dayjs from "dayjs";
import fetchApi from "../../apiservice/ApiService";
import clsx from "clsx";
import { routes_path } from "../../config/routesPath";

const BlogDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState();
  const [loader, setLoader] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetBlogDetails(id);
  }, []);

  const handleGetBlogDetails = (id) => {
    setLoader(true);
    fetchApi
      .getBlogById(id)
      .then((response) => {
        setBlogData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handlChangeBlog = (url) => {
    setLoader(true);
    fetchApi
      .getData(url)
      .then((response) => {
        setBlogData(response?.data?.data);
        navigate(
          `/blog/${response?.data?.data?.blog?.["title"]
            .toLowerCase()
            .replace(/[\s,-/!@:#$%^*()"?`]+/g, "-")
            .replace(/[\s,-/!@#$%^*()"?`]$/g, "")}/${
            response?.data?.data?.blog?.id
          }`,
          {
            state: {
              blog: response?.data?.data?.blog?.id,
            },
          }
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleshareOnLinkedIn = () => {
    const left = (window.screen.width - 600) / 2;
    const top = (window.screen.height - 400) / 4;

    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&text=${blogData?.blog?.title}`,
      "_blank",
      `width=600, height=400, top=${top}, left=${left}`
    );
  };

  return (
    <>
      {loader ? (
        <div className="flex justify-center py-20">
          <div className="page-loader"></div>
        </div>
      ) : (
        <div className="py-10 mx-4 lg:mx-20">
          <div>
            <div className="grid lg:flex gap-10 items-center">
              <div className="lg:w-[50%]">
                <p className="text-primary text-[24px] lg:text-[38px] lg:leading-[45px]">
                  {blogData?.blog?.["title"]}
                </p>
                <div className="flex gap-6 py-6">
                  <p className="flex items-center gap-2 text-[14px]">
                    <img src={calendar} width={20} />{" "}
                    {blogData?.blog?.["start_date"]
                      ? dayjs(blogData?.blog?.["start_date"]).format(
                          "MMM DD, YYYY"
                        )
                      : dayjs(blogData?.blog?.["create_at"]).format(
                          "MMM DD, YYYY"
                        )}
                  </p>
                  <p className="flex items-center gap-2 text-[14px]">
                    <img src={comment} width={20} /> No comment
                  </p>
                </div>
              </div>
              <div className="lg:w-[50%]">
                <img src={blogData?.blog?.["image_url"]} />
              </div>
            </div>
            <div className="pt-10 text-[#57647C]">
              {blogData?.blog?.["detailed_description"]
                ? parser(
                    blogData?.blog?.["detailed_description"].replaceAll(
                      "\n\n",
                      "<br>"
                    )
                  )
                : blogData?.blog?.["detailed_description"]}
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <button
              className="text-[18px] py-3 px-8 font-bold text-[#fff] bg-primary rounded-[80px]"
              onClick={() => {
                navigate(
                  `${
                    routes_path.contactUs
                  }?utm_medium=blog&utm_campaign=${blogData?.blog?.["title"]
                    ?.replaceAll(/[\s,-/!@:'#$%^*()"?`]+/g, "-")
                    ?.toLowerCase()}`
                );
              }}
            >
              {blogData?.blog?.button_title}
            </button>
          </div>

          <div className="mt-10 mb-20">
            <p className="blog-share">Share On</p>
            <div className="mt-6 flex items-center gap-8">
              <img
                src={shareOnFB}
                width={25}
                height={25}
                className="cursor-pointer"
                title="Facebook"
                onClick={() => {
                  const left = (window.screen.width - 600) / 2;
                  const top = (window.screen.height - 400) / 4;
                  const currentURL = encodeURIComponent(window.location.href);
                  const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${currentURL}`;
                  window.open(
                    facebookShareURL,
                    "_blank",
                    `width=600, height=400, top=${top}, left=${left}`
                  );
                }}
              />
              <img
                src={shareOnTwitter}
                width={20}
                height={20}
                className="cursor-pointer"
                title="Twitter"
                onClick={() => {
                  const left = (window.screen.width - 600) / 2;
                  const top = (window.screen.height - 400) / 4;
                  window.open(
                    `https://twitter.com/intent/post?url=${window.location.href}&text=${blogData?.blog?.["title"]}`,
                    "_blank",
                    `width=600, height=400, top=${top}, left=${left}`
                  );
                }}
              />
              <img
                src={shareOnLinkedIn}
                width={20}
                height={20}
                className="cursor-pointer"
                title="LinkedIn"
                onClick={() => {
                  handleshareOnLinkedIn();
                }}
              />
            </div>
          </div>

          <div
            className={clsx(
              "flex items-center mt-10",
              blogData?.previous_blog ? "justify-between" : "justify-end"
            )}
          >
            {blogData?.previous_blog && (
              <div
                className="cursor-pointer flex items-center gap-1 font-semibold"
                onClick={() => {
                  handlChangeBlog(blogData?.previous_blog?.url);
                }}
              >
                <img src={leftArr} width={35} />
                <div>
                  <p className="font-bold text-primary">PREVIOUS</p>
                  <p className="text-[#57647C] text-[12px]">
                    {blogData?.previous_blog?.title}
                  </p>
                </div>
              </div>
            )}
            {blogData?.next_blog && (
              <div
                className="cursor-pointer flex items-center gap-1 font-semibold text-right"
                onClick={() => {
                  handlChangeBlog(blogData?.next_blog?.url);
                }}
              >
                <div>
                  <p className="font-bold text-primary">NEXT </p>
                  <p className="text-[#57647C] text-[12px]">
                    {blogData?.next_blog?.title}
                  </p>
                </div>
                <img src={rightArr} width={35} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HOC(BlogDetails);
