import clsx from "clsx";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import noCode from "../../assets/images/Products-images/whitelabel/whitelabel-nocode.png";
import banner from "../../assets/images/Products-images/whitelabel/whitelabel_banner.png";
import wlPNeobank from "../../assets/images/Products-images/whitelabel/whitelabel-section.png";
import HOC from "../../component/HOC";
import { routes_path } from "../../config/routesPath";
import {
  WLPAData,
  WLPNData,
  whiteLabelSolutionData,
  whitelabelData,
} from "./productDetailsData";
import PaymentAgregatorImg from "../../assets/images/Products-images/whitelabel/payment-agreegator.jpg";

const Whitelabel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "White Label - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div>
        <div className="whitelabel-bg flex flex-col-reverse md:flex-row md:justify-center items-center px-4 py-10 lg:py-0 lg:px-20 font-MyriadPro">
          <div
            className="md:w-[60%] md:pr-24"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-[24px] lg:text-[36px] font-extrabold text-primary">
              Upgrade Your Brand's Payment Processing with White Label
              Aggregator
            </p>
            <p className="text-[14px] lg:text-[18px] py-4">
              Easily manage payments and maintain your brand identity with our
              robust white label payment platform
            </p>
            <div className="grid grid-cols-2 gap-4 lg:max-w-[70%]">
              <button
                className="text-white bg-primary py-2 px-6 text-[12px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  // window.open(routes_path?.signup);
                  navigate(routes_path?.contactUs);
                }}
              >
                Create Account
              </button>
              <button
                className="text-secondary border-[1px] bg-white text-[12px] lg:text-[18px] border-secondary py-2 px-6 rounded-[20px] hover:scale-95 transition-all"
                onClick={() => navigate(routes_path?.contactUs)}
              >
                Contact Sales
              </button>
            </div>
          </div>
          <div
            className="md:w-[40%] px-8 pb-8 md:p-8"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img src={banner} alt="whitelabel" className="object-contain" />
          </div>
        </div>

        <div className="py-10 lg:py-20 wl-bg">
          <p className="text-[24px] lg:text-[38px] font-bold text-center text-primary px-4">
            Your Business & Touras:{" "}
            <span className="text-secondary">
              Uniting for Unstoppable Success
            </span>
          </p>
          <div className="text-[16px] lg:text-[18px] p-4 lg:p-10 lg:mx-20 mt-10 grid md:grid-cols-3 lg:grid-cols-4 gap-10">
            {whitelabelData?.map((item, index) => {
              return (
                <div
                  className="rounded-[30px] text-center w-[200px] mx-auto p-8 bg-[#FFFFFF]"
                  key={index}
                >
                  <img
                    src={item.iconUrl}
                    alt={item.title}
                    className="mx-auto"
                  />
                  <p className="mt-4 text-[17px]">{item.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="py-10">
          <p className="text-primary text-[24px] lg:text-[40px] font-bold text-center lg:px-10 lg:max-w-[75%] mx-auto">
            Customize payment experience with branded White Labeling.
          </p>

          <div className="py-10 px-4">
            <div className="flex items-start flex-col-reverse md:flex-row">
              <div className="md:w-[40%] lg:px-10 my-10">
                <p className="text-primary text-[24px] lg:text-[40px] font-bold">
                  Payment Aggregator
                </p>
                <ul className="px-6 mt-4">
                  {WLPAData?.map((item, index) => {
                    return (
                      <li
                        className="list-disc text-[18px] lg:text-[20px]"
                        key={index}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="md:w-[60%]">
                <img
                  src={PaymentAgregatorImg}
                  alt="whitelabel paymeny aggregator"
                  className="object-contain h-[300px] w-full"
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center bg-backgroundLight rounded-[20px] lg:rounded-[50px] mt-10 lg:px-20 py-10">
              <div className="md:w-[60%] px-4">
                <img src={wlPNeobank} alt="whitelabel paymeny aggregator" />
              </div>
              <div className="md:w-[40%] my-10">
                <p className="text-primary text-[24px] lg:text-[40px] font-bold">
                  Payout & Neobanking
                </p>
                <p className="mt-4 text-[18px] lg:text-[20px]">
                  Improve your checkout experience with our customizable
                  white-label solutions{" "}
                </p>
                {/* <ul className="px-6 mt-4">
                  {WLPNData?.map((item, index) => {
                    return (
                      <li
                        className="list-disc text-[18px] lg:text-[20px]"
                        key={index}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul> */}
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 wl-solution-bg isolate">
          <p className="text-[20px] lg:text-[30px] font-bold text-center text-primary px-10">
            Our Whitelabel solution gives businesses access to an unrivaled
            global network of payment solutions, allowing them to process safer
            and decentralized transactions.
          </p>

          <div className="flex pt-10 lg:pt-20">
            <div className="grid lg:w-[60%] px-10 lg:px-20">
              {whiteLabelSolutionData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col lg:flex-row items-center gap-4 lg:gap-10 py-10 relativ"
                  >
                    <div className="border-2 border-primary p-4 rounded-full w-max h-[96px] bg-white">
                      <p
                        className={clsx(index !== 0 ? "relative wl-steps" : "")}
                      >
                        <img
                          src={item.iconUrl}
                          alt={item.title}
                          className="w-[60px] h-[60px]"
                        />
                      </p>
                    </div>
                    <div className="text-center lg:text-left w-[80%]">
                      <p className="text-primary text-[23px] font-bold">
                        {item.title}
                      </p>
                      <p className="text-[17px] whitespace-pre-line">
                        {item.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="p-10 lg:p-20 flex flex-col md:flex-row items-center gap-10 relative">
          <div className="lg:w-[50%]">
            <p className="grid text-[38px] lg:text-[60px] font-black text-primary leading-[38px] lg:leading-[60px]">
              <span>No Coding.</span>
              <span className="text-secondary">No Website.</span>
              <span>No Problem</span>
            </p>
            <p className="text-[18px] mt-8">
              Collect payments easily over social media with Payment Links
              without a single line of coding. Send links instantly for missed
              transactions or abandoned carts.
            </p>
          </div>
          <div className="w-full lg:w-[50%]">
            <img src={noCode} alt="No Code section" className="" />
          </div>
          <div className="absolute -bottom-4 left-[50%] translate-x-[-50%] w-full md:w-auto grid grid-cols-2 gap-4 my-8 mx-auto px-6">
            <button
              className="text-white bg-primary py-1 px-6 text-[16px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all border border-secondary"
              onClick={() => {
                // window.open(routes_path?.signup);
                navigate(routes_path?.contactUs);
              }}
            >
              Sign Up Now
            </button>
            <button
              className="text-secondary bg-white border-[1px] text-[16px] lg:text-[18px] border-secondary py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
              onClick={() => navigate(routes_path?.contactUs)}
            >
              Contact Sales
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(Whitelabel);
