import React, { useEffect, useState } from "react";
import Pagination from "../../common-component/pagination/Pagination";
import fetchApi from "../../apiservice/ApiService";
import dayjs from "dayjs";

const ArticleSection = () => {
  const [articleData, setArticlesData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetArticle();
  }, []);

  const handleGetArticle = () => {
    setLoading(true);
    fetchApi
      .getArticles()
      .then((response) => {
        setArticlesData(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="bg-background" id="articles">
        <div className="mx-4 md:mx-20 pt-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-primary text-[30px] font-extrabold">
              Articles
            </p>
          </div>

          {loading ? (
            <div className="grid lg:grid-cols-3 gap-20 py-10">
              {[...Array(3)]?.map((_, index) => {
                return (
                  <div key={index}>
                    <div className="skeleton--img">
                      <span></span>
                    </div>
                    <div className="py-8 font-roboto">
                      <p className="skeleton--title">
                        <span></span>
                      </p>
                      <div className="flex items-center gap-5 my-3">
                        <label className="skeleton--date">
                          <span></span>
                        </label>
                      </div>
                      <p className="skeleton--description">
                        <span></span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="grid lg:grid-cols-3 gap-20 py-10">
              {articleData?.data?.length > 0 &&
                articleData?.data?.map((ele, key) => {
                  return (
                    <React.Fragment>
                      <div
                        key={key}
                        className="hover:-translate-y-2 transition-all duration-500"
                        onClick={() => {
                          window.open(ele?.link, "_blank");
                        }}
                        title={ele?.title}
                      >
                        <div className="w-full h-[185px] flex">
                          <img
                            src={ele["image_path"]}
                            alt="article 1"
                            className="cursor-pointer h-auto mx-auto"
                          />
                        </div>
                        <div className="py-8 font-roboto">
                          <p className="text-primary text-[25px] font-extrabold cursor-pointer line-clamp-2">
                            {ele["title"]}
                          </p>
                          <div className="flex items-center gap-5 text-[#57647C] my-3">
                            <label className="flex items-center gap-2 text-[12px]">
                              {ele?.start_date ? dayjs(ele?.start_date).format("MMM DD, YYYY") : dayjs(ele?.created_at).format("MMM DD, YYYY")}
                            </label>
                          </div>
                          <p className="text-[18px] line-clamp-3 text-content overflow-hidden">
                            {ele.description}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          )}
          {articleData?.data?.length > 0 && (
            <Pagination
              totalPage={articleData?.links}
              setData={setArticlesData}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ArticleSection;
