import clsx from "clsx";
import React from "react";
import leftArr from "../../assets/social-icons/pagination-arr.svg";
import fetchApi from "../../apiservice/ApiService";

const Pagination = ({ totalPage, setData }) => {
  return (
    <React.Fragment>
      <div className="flex items-center pb-10 justify-center flex-wrap">
        <img
          src={leftArr}
          alt="pagination left arrow"
          className={clsx(
            "cursor-pointer",
            totalPage?.[0]?.url === null ? "pointer-events-none" : ""
          )}
          onClick={() => {
            fetchApi
              ?.getData(totalPage?.[0]?.url)
              .then((res) => {
                setData(res?.data?.data);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        />
        {totalPage?.slice(1, totalPage?.length - 1)?.map((item, index) => {
          return (
            <p
              key={index}
              className={clsx(
                "px-2 py-1 mx-3",
                item?.active
                  ? "bg-secondary text-[#FFFFFF] rounded-[6px] pointer-events-none"
                  : "cursor-pointer"
              )}
              onClick={() => {
                fetchApi
                  ?.getData(item?.url)
                  .then((res) => {
                    setData(res?.data?.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {item?.label}
            </p>
          );
        })}
        <img
          src={leftArr}
          alt="pagination right arrow"
          className={clsx(
            "rotate-180 cursor-pointer",
            totalPage?.[totalPage?.length - 1]?.url === null
              ? "pointer-events-none"
              : ""
          )}
          onClick={() => {
            fetchApi
              ?.getData(totalPage?.[totalPage?.length - 1]?.url)
              .then((res) => {
                setData(res?.data?.data);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default Pagination;
