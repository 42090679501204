import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { toastConfig } from "react-simple-toasts";
import { default as fetchApi } from "../../apiservice/ApiService";
import { AppConfig } from "../../apiservice/AppConfig";
import HOC from "../../component/HOC";
import dayjs from "dayjs";

const Blog = () => {
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recentBlogsData, setRecentBlogsData] = useState([]);

  const handleChange = (e) => {
    const mail = e.target.value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    setEmailAddress(e.target.value);
    if (mail) {
      setIsValid(true);
      setError(false);
    } else if (e.target.value === "") {
      setEmailAddress("");
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    if (emailAddress === "") {
      toastConfig({ theme: "failure" });
      toast("please enter a email.");
      setTimeout(() => {
        setLoader(false);
      }, 500);
      return false;
    }

    if (isValid) {
      const _form = new FormData();
      _form.append("email", emailAddress);
      _form.append("page_name", "blog");
      axios
        .post(AppConfig.baseUrl + "customer/landing/subscribe", _form)
        .then((response) => {
          setError(false);
          toastConfig({ theme: "success" });
          toast(response?.data?.message);
        })
        .catch((err) => {
          console.log(err);
          toastConfig({ theme: "failure" });
          if (err?.response?.data?.message) {
            toast(err?.response?.data?.message);
          } else {
            toast("Email not sent.");
          }
        })
        .finally(() => {
          setLoader(false);
          setEmailAddress("");
          setIsValid(false);
        });
    } else {
      toastConfig({ theme: "failure" });
      toast("Enter email to subscribe.");
      setLoader(false);
    }
  };

  useEffect(() => {
    document.title = "Blogs - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetBlogs();
    handleGetRecentBlogs();
  }, []);

  const handleGetBlogs = () => {
    setLoading(true);
    fetchApi
      .getBlogs()
      .then((res) => {
        setBlogData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetRecentBlogs = () => {
    fetchApi
      .getRecentBlogs()
      .then((res) => {
        setRecentBlogsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div>
        <div className="">
          <div className="text-center py-10 px-4 md:p-20 md:max-w-[80%] mx-auto">
            <p className="text-[24px] md:text-[40px] font-extrabold text-primary">
              A <span className="text-secondary">Comprehensive Guide</span> to
              all latest updates in the{" "}
              <span className="text-secondary">world of payments</span>
            </p>
            <p className="text-content text-[18px] mt-6">
              Subscribe today and keep up <br />
              on industry trends, expert advice, guides and stories to get you
              business ready from the get go.
            </p>
            <div className="flex flex-col md:flex-row items-start justify-center gap-4 mt-6">
              <div className="grid">
                <input
                  type="email"
                  placeholder="Enter Yout Email"
                  className="border-[1px] border-primary bg-[#F3F9FE] rounded-[6px] outline-none text-center p-2 text-sm w-[250px]"
                  value={emailAddress}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {error && (
                  <p className="text-[12px] text-left text-red-500 mt-1">
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              {loader ? (
                <p
                  className="bg-[#000000] py-2 px-8 border-[1px] border-primary rounded-[6px] text-[#FFFFFF] font-medium text-sm flex justify-center items-center w-[130px]"
                  onClick={() => {
                    toastConfig({ theme: "failure" });
                    toast("please wait! we are processing your request.");
                  }}
                >
                  <span className="loader"></span>
                </p>
              ) : (
                <button
                  className="bg-[#000000] py-2 px-8 border-[1px] border-primary rounded-[6px] text-[#FFFFFF] font-medium text-sm"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Subscribe
                </button>
              )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-20 items-center mx-4 md:mx-20">
            <div className="w-full md:w-[40%]">
              <p className="text-primary text-[30px] border-b-2 font-extrabold">
                Recent Blogs
              </p>
              {loading ? (
                <div>
                  {[...Array(2)]?.map((_, index) => {
                    return (
                      <div className="mt-4" key={index}>
                        <p className="text-[#32325D] text-[20px] md:text-[25px] font-extrabold cursor-pointer skeleton--title">
                          <span></span>
                        </p>
                        <p className="text-[14px] text-[#565656] skeleton--date w-[100px!important] mt-2">
                          <span></span>
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  {recentBlogsData?.length > 0 &&
                    recentBlogsData
                      ?.slice(1, recentBlogsData?.length)
                      ?.map((item, index) => {
                        return (
                          <div className="mt-4" key={index}>
                            <p
                              className="text-primary text-[20px] md:text-[25px] font-extrabold cursor-pointer"
                              onClick={() => {
                                navigate(
                                  `/blog/${item?.title
                                    .toLowerCase()
                                    .replace(/[\s,-/!:@#$%^*()"?`]+/g, "-")
                                    .replace(/[\s,-/!:@#$%^*()"?`]$/g, "")}/${
                                    item?.id
                                  }`,
                                  {
                                    state: {
                                      blog: item?.id,
                                    },
                                  }
                                );
                              }}
                            >
                              {item?.title}
                            </p>
                            <p className="text-[14px] text-[#565656]">
                              {item?.start_date
                                ? dayjs(item?.start_date).format("MMM DD, YYYY")
                                : dayjs(item?.created_at).format(
                                    "MMM DD, YYYY"
                                  )}
                            </p>
                          </div>
                        );
                      })}
                </div>
              )}
            </div>

            {loading ? (
              <div className="w-full md:w-[60%]">
                <p className="text-[#32325D] text-[30px] font-extrabold skeleton--title">
                  <span></span>
                </p>
                <p className="text-[14px] text-[#565656] skeleton--date w-[100px!important] mt-4">
                  <span></span>
                </p>
                <p className="text-[18px] mt-4 skeleton--description">
                  <span></span>
                </p>
                <p className="text-[#4949FF] underline font-bold mt-4 cursor-pointer w-max">
                  Read More
                </p>
              </div>
            ) : (
              <div className="md:w-[60%]">
                <p className="text-primary text-[30px] font-extrabold">
                  {recentBlogsData?.[0]?.title}
                </p>
                <p className="text-[14px] text-[#565656]">
                  {recentBlogsData?.[0]?.start_date
                    ? dayjs(recentBlogsData?.[0]?.start_date).format(
                        "MMM DD, YYYY"
                      )
                    : dayjs(recentBlogsData?.[0]?.created_at).format(
                        "MMM DD, YYYY"
                      )}
                </p>
                <p className="text-[18px] mt-4">
                  {recentBlogsData?.[0]?.description}...
                </p>
                <p
                  className="text-[#4949FF] underline font-bold mt-4 cursor-pointer w-max"
                  onClick={() => {
                    navigate(
                      `/blog/${recentBlogsData?.[0]?.title
                        .toLowerCase()
                        .replace(/[\s,-/!:@#$%^*()"?`]+/g, "-")
                        .replace(/[\s,-/!:@#$%^*()"?`]$/g, "")}/${
                        recentBlogsData?.[0]?.id
                      }`,
                      {
                        state: {
                          blog: recentBlogsData?.[0]?.id,
                        },
                      }
                    );
                  }}
                >
                  Read More
                </p>
              </div>
            )}
          </div>

          {loading ? (
            <div className="mx-4 md:mx-20 border-t-2 mt-10 pt-4">
              <div className="flex justify-between items-center">
                <p className="text-[#32325D] text-[30px] font-extrabold skeleton--title w-[150px!important]">
                  <span></span>
                </p>
                <p className="text-[14px] text-[#565656] cursor-pointer">
                  View All
                </p>
              </div>

              <div className="grid lg:grid-cols-3 gap-10 py-10">
                {[...Array(3)].map((_, key) => {
                  return (
                    <React.Fragment>
                      <div key={key}>
                        <div className="skeleton--img">
                          <span></span>
                        </div>
                        <div className="py-8 font-roboto">
                          <p className="skeleton--title">
                            <span></span>
                          </p>
                          <div className="flex items-center gap-5 text-[#57647C] my-3">
                            <label className="skeleton--date w-[150px!important]">
                              <span></span>
                            </label>
                          </div>
                          <p className="text-[18px] line-clamp-3 overflow-hidden skeleton--description">
                            <span></span>
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="bg-white">
              {blogData?.map((item, index) => {
                return (
                  <React.Fragment>
                    <div
                      className="mx-4 md:mx-20 border-t-2 mt-10 pt-4"
                      key={index}
                    >
                      <div className="flex justify-between items-center">
                        <p className="text-primary text-[24px] md:text-[30px] font-extrabold">
                          {item?.title}
                        </p>
                        {item?.blogs?.length > 3 && (
                          <p
                            className="text-[14px] text-[#565656] cursor-pointer"
                            onClick={() => {
                              navigate(`/view-all-blog/${item?.id}`, {
                                state: {
                                  blogs: item,
                                },
                              });
                            }}
                          >
                            View All
                          </p>
                        )}
                      </div>

                      <div className="grid lg:grid-cols-3 gap-10 py-10">
                        {item?.blogs?.length > 0 &&
                          item?.blogs?.slice(0, 3)?.map((ele, key) => {
                            return (
                              <React.Fragment>
                                <div
                                  key={key}
                                  className="hover:-translate-y-2 transition-all duration-500"
                                >
                                  <img
                                    src={ele?.["image_url"]}
                                    alt="blog 1"
                                    className="cursor-pointer h-[285px] object-cover"
                                    onClick={() => {
                                      navigate(
                                        `/blog/${ele["title"]
                                          .toLowerCase()
                                          .replace(/[\s,-/!:@#$%^*()"?`]+/g, "-")
                                          .replace(
                                            /[\s,-/!:@#$%^*()"?`]$/g,
                                            ""
                                          )}/${ele?.id}`,
                                        {
                                          state: {
                                            blog: ele?.id,
                                          },
                                        }
                                      );
                                    }}
                                  />
                                  <div className="py-8 font-roboto">
                                    <p
                                      className="text-primary text-[25px] font-extrabold cursor-pointer line-clamp-2"
                                      onClick={() => {
                                        navigate(
                                          `/blog/${ele["title"]
                                            .toLowerCase()
                                            .replace(
                                              /[\s,-/!:@#$%^*()"?`]+/g,
                                              "-"
                                            )
                                            .replace(
                                              /[\s,-/!:@#$%^*()"?`]$/g,
                                              ""
                                            )}/${ele?.id}`,
                                          {
                                            state: {
                                              blog: ele?.id,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      {ele["title"]}
                                    </p>
                                    <div className="flex items-center gap-5 text-[#57647C] my-3">
                                      <label className="flex items-center gap-2 text-[12px]">
                                        {ele?.["start_date"]
                                          ? dayjs(ele["start_date"]).format(
                                              "MMM DD, YYYY"
                                            )
                                          : dayjs(ele["created_at"]).format(
                                              "MMM DD, YYYY"
                                            )}
                                      </label>
                                    </div>
                                    <p className="text-[18px] line-clamp-3 text-content overflow-hidden">
                                      {ele.description}
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HOC(Blog);
