import clsx from "clsx";
import React, { useState } from "react";

const SelectDropdown = ({
  label,
  themeColor,
  data,
  error,
  placeHolder,
  message,
  onClick,
  value,
  helperText,
  required
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState(value || '');

  const handleSearch = (value) => {
    const val = value?.toLowerCase();
    setSearchValue(value);
    const search = data?.filter((item) => {
      return item?.label?.toLowerCase()?.includes(val) && item;
    });
    setSearchData(search);
  };

  return (
    <React.Fragment>
      <div>
        <div className="text-[#212121] grid">
          <label
            className={clsx(
              "text-[14px] font-medium flex items-center",
              error ? "text-red-500" : themeColor ? `text-[${themeColor}]` : ""
            )}
          >
            {label}<span className="text-secondary">{required ? "*" : ""}</span>
          </label>
        </div>
        <div
          className={clsx(
            "relative h-[45px] border-[1px] rounded-[8px] px-2 py-1",
            error
              ? "border-red-500"
              : themeColor
              ? `border-b-[${themeColor}]`
              : "border-[#A5A5A5]"
          )}
        >
          <div
            className={clsx(
              "flex items-center justify-between cursor-pointer text-[14px] h-[35px] text-[#212121] font-medium"
            )}
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          >
            <input
              src="text"
              value={searchValue?.label}
              placeholder={placeHolder}
              className="outline-none bg-transparent"
              autoComplete={false}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <span className="triangle-down mr-8"></span>
          </div>
          {showDropdown && (
            <div className="absolute z-10 w-full bg-white grid max-h-[200px] h-auto overflow-auto">
              {searchData?.length > 0 ? (
                searchData?.map((item, index) => {
                  return (
                    <label
                      key={index}
                      className="hover:bg-[#b9b9b9] cursor-pointer px-3 py-1 text-[14px]"
                      onClick={() => {
                        onClick(item);
                        setShowDropdown(false);
                        setSearchValue(item);
                      }}
                    >
                      {item?.label}
                    </label>
                  );
                })
              ) : data?.length > 0 ? (
                data?.map((item, index) => {
                  return (
                    <label
                      key={index}
                      className="hover:bg-[#b9b9b9] cursor-pointer px-3 py-1 text-[14px]"
                      onClick={() => {
                        onClick(item);
                        setShowDropdown(false);
                        setSearchValue(item);
                      }}
                    >
                      {item?.label}
                    </label>
                  );
                })
              ) : (
                <label className="hover:bg-[#b9b9b9] cursor-pointer px-3 py-3 text-[14px] text-center">
                  {message}
                </label>
              )}
            </div>
          )}
        </div>
        {error ? (
          <p className="text-[12px] text-red-500 mt-1 h-auto">{helperText}</p>
        ) : (
          <p className="text-[12px] mt-1 min-h-[18px]"></p>
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectDropdown;
