import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import simplifyImg from "../../assets/images/Products-images/industries/simplify1.png";
import HOC from "../../component/HOC";
import SimplifyComponent from "../../component/SimplifyComponent";
import { routes_path } from "../../config/routesPath";
import { d2cData } from "./industryData";
import banner from "../../assets/images/Industries-images/ecommerce.png";

const Ecommerce = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Ecommerce - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div>
        <div className="lg:h-[500px] ecommerce-bg">
          <div className="flex flex-col-reverse md:flex-row items-center justify-end gap-2 lg:gap-10 max-w-[1140px] mx-auto h-full">
            <div className="md:w-[60%] px-4 my-8">
              <p className="text-primary text-[24px] lg:text-[38px] font-extrabold lg:leading-[45px]">
                Streamlined Payment Collection for Thriving D2C Enterprises
              </p>
              <p className="py-3 text-[#000000] text-[14px] lg:text-[18px] max-w-[80%]">
                Take your business to new heights with our comprehensive
                e-commerce payments suite!
              </p>
              <div className="grid grid-cols-2 gap-4 lg:max-w-[70%] mt-8">
                <button
                  className="text-white bg-primary py-1 px-6 text-[16px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => {
                    // window.open(routes_path?.signup);
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Sign Up Today
                </button>
                <button
                  className="text-secondary border-[1px] text-[16px] lg:text-[18px] border-secondary py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => navigate(routes_path?.contactUs)}
                >
                  Talk to Sales
                </button>
              </div>
            </div>
            <div
              className="md:w-[40%] px-8 md:p-8"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img src={banner} alt="e-commerce" className="object-contain" />
            </div>
          </div>
        </div>

        {/* Aasaan Section */}
        <div className="py-10">
          <p className="text-primary text-center font-semibold text-[28px] md:text-[40px]">
            How <span className="text-secondary">Aasaan</span> Works?
          </p>

          <div className="grid lg:grid-cols-3 gap-20 md:gap-10 items-start mx-auto w-max max-w-[1140px] mt-10">
            <div className="flex justify-center items-center flex-col gap-5 w-[300px]">
              <p className="step-square">Step 1</p>
              <p className="text-[24px] font-semibold text-primary text-center">
                Enter <span className="text-secondary">Mobile Number</span>
              </p>
              <p className="text-[17px] text-center font-medium text-primary">
                The shopper fills in minimal details for the first time &
                becomes Aasaan registered user.
              </p>
            </div>
            <div className="flex justify-center items-center flex-col gap-5 w-[300px]">
              <p className="step-square">
                <span className="dashed_path">Step 2</span>
              </p>
              <p className="text-[24px] font-semibold text-primary text-center flex flex-col">
                Validate{" "}
                <span className="text-secondary">Shopper & Order </span>
                Details
              </p>
              <p className="text-[17px] text-center font-medium text-primary">
                The next time the shopper shops, their details get
                auto-populated just via OTP login.
              </p>
            </div>
            <div className="flex justify-center items-center flex-col gap-5 w-[300px]">
              <p className="step-square">Step 3</p>
              <p className="text-[24px] font-semibold text-primary text-center">
                Payment <span className="text-secondary">Confirmation</span>
              </p>
              <p className="text-[17px] text-center font-medium text-primary">
                The shopper pays and receives an order confirmation.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10 industries-bg">
          <p className="text-primary text-[24px] lg:text-[40px] px-4 font-bold leading-[45px] text-center">
            Easy{" "}
            <span className="text-secondary">E-commerce Payment Solutions</span>{" "}
            for Businesses
          </p>

          <div className="max-w-[1140px] mx-10 lg:mx-auto flex flex-wrap justify-center gap-x-20 gap-y-10 lg:gap-y-20 my-10 lg:my-20">
            {d2cData?.map((item, index) => {
              return (
                <div
                  className="bg-white rounded-[20px] text-center w-[300px] p-10 relative"
                  key={index}
                >
                  <img
                    src={item.iconUrl}
                    alt={item.title}
                    className="mx-auto w-[60px] lg:w-[80px]"
                  />
                  <p className="text-primary mt-4 text-[22px] font-bold">
                    {item.title}
                  </p>
                  <p className="text-[#212529] mt-3 font-medium">
                    {item.description}
                  </p>
                  <button
                    className="absolute -bottom-4 left-[50%] translate-x-[-50%] bg-primary rounded-[5px] px-4 py-1 text-white font-bold"
                    onClick={() => navigate(item.navigationUrl)}
                  >
                    Know More
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <SimplifyComponent
          title={"PAYMENTS"}
          content={
            "Manage your customer payments, payouts, and checkouts experience with ease. Contact us for custom solutions or create your account now!"
          }
          image={simplifyImg}
          height={"h-[300px] md:h-[500px]"}
        />
      </div>
    </>
  );
};

export default HOC(Ecommerce);
