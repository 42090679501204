import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import xIcon from "../../assets/images/Products-images/X-black.png";
import apixLogo from "../../assets/images/Products-images/apix/Apixlogo 2.png";
import bg2 from "../../assets/images/Products-images/apix/choose-apix.png";
import logo from "../../assets/images/logo.svg";
import banner from "../../assets/images/Products-images/apix/apix-banner.png";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import { routes_path } from "../../config/routesPath";
import { apixData, whyChooseAPIxData } from "./productDetailsData";

const APIX = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "APIX - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <React.Fragment>
      <Header
        safexpayLogo={logo}
        productLogo={apixLogo}
        productTitle={"APIX"}
        xIcon={xIcon}
      />
      <div>
        <div className="apix-bg">
          <div className="flex flex-col-reverse h-full lg:flex-row items-center max-w-[1140px] mx-auto px-4 gap-10 justify-start md:justify-between">
            <div
              className="md:w-[60%]"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={apixLogo} alt="apix" className="w-[150px]" />
              <p className="text-[40px] text-primary font-bold leading-[1.2] mb-2">
                New era of Regtech Platform
              </p>
              <p className="text-[16px] text-primary">
                Accelerate your product launches by 10X with open Verification
                APIs.
              </p>
              <div className="grid grid-cols-2 gap-4 lg:max-w-[70%] mt-8">
                <button
                  className="text-white bg-primary py-1 px-6 text-[16px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => {
                    // window.open(routes_path?.signup);
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Sign Up Today
                </button>
                <button
                  className="text-primary border-[1px] text-[16px] lg:text-[18px] border-primary py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => navigate(routes_path?.contactUs)}
                >
                  Talk to Sales
                </button>
              </div>
            </div>
            <div
              className="md:w-[40%] px-8 pt-4 md:p-8"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img src={banner} alt="apix" className="object-contain" />
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="text-center px-6">
            <p className="text-primary leading-[1.2] text-[26px] font-bold mb-2">
              Pick your API from our customised APIX Stack
            </p>
            <p className="text-[#57647C] text-[18px]">
              Access multiple APIs under one platform while we handle the magic
              behind the scenes!
            </p>
          </div>
          <div className="grid lg:grid-cols-4 gap-6 m-4 lg:my-10 lg:mx-10 pt-10 lg:py-0">
            {apixData?.map((item, index) => {
              return (
                <div
                  className="bg-backgroundLight p-8 rounded-[12px]"
                  key={index}
                >
                  <img
                    src={item?.iconUrl}
                    alt={item?.title}
                    width={50}
                    className="mb-5"
                  />
                  <div className="">
                    <p className="text-[23px] text-primary font-bold mb-2">
                      {item?.title}
                    </p>
                    <ul className="grid gap-2">
                      {item?.listItems?.map((ele, key) => {
                        return (
                          <li
                            className="offer-li relative pl-4 text-[14px] font-bold text-primary"
                            key={key}
                          >
                            {ele}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="py-10">
          <div className="flex flex-col lg:flex-row m-4 lg:mx-20">
            <div className="lg:w-[50%]">
              <img
                src={bg2}
                alt="why choose APIX?"
                className="w-[380px] mx-auto"
              />
            </div>
            <div className="lg:w-[50%] px-4 pt-10 md:pt-0">
              <p className="text-[29px] font-bold text-primary mb-2">
                Why Choose APIX?
              </p>
              <div className="grid">
                {whyChooseAPIxData?.map((item, index) => {
                  return (
                    <div className="py-3 flex items-start gap-4" key={index}>
                      <div className="w-[60px]">
                        <img
                          src={item?.iconUrl}
                          alt={item?.title}
                          className="mx-auto object-contain"
                        />
                      </div>
                      <div className="w-[calc(100%-60px)]">
                        <p className="text-[19px] text-primary group-hover:text-white">
                          {item?.title}
                        </p>
                        <p className="text-[16px] text-[#57647C] group-hover:text-white">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 flex flex-col items-center justify-center begin-api-journey">
          <p className="text-[24px] md:text-[31px] text-[#FFFFFF] font-bold text-center">
            Begin Your API Journey Now
          </p>
          <div className="flex flex-col md:flex-row gap-4 mt-10">
            <button
              className="bg-[#000000] py-2 px-8 w-max mx-auto md:mx-0 border-[1px] border-secondary rounded-[20px] text-[#FFFFFF] font-medium"
              onClick={() => {
                navigate(routes_path?.contactUs);
              }}
            >
              Contact Us!
            </button>
          </div>
          {/* <div className="flex flex-col md:flex-row gap-4 mt-10">
            <input
              type="number"
              placeholder="Enter Phone No."
              className="border-[1px] border-[#0C99D5] bg-[#F3F9FE] rounded-[3px] outline-none text-center p-2 text-sm w-[350px]"
            />
            <button className="bg-[#000000] py-2 px-8 w-max mx-auto md:mx-0 border-[1px] border-[#0C99D5] rounded-[3px] text-[#FFFFFF] font-medium">
              Contact Us!
            </button>
          </div> */}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default APIX;
