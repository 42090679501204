import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import simplifyImg from "../../assets/images/Products-images/industries/simplify2.png";
import educationFlow from "../../assets/video/Education Flow.mp4";
import HOC from "../../component/HOC";
import SimplifyComponent from "../../component/SimplifyComponent";
import { routes_path } from "../../config/routesPath";
import { educationData } from "./industryData";
import banner from "../../assets/images/Industries-images/education.png";

const Education = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Education - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div>
        <div className="lg:h-[450px] education-bg">
          <div className="flex flex-col-reverse md:flex-row items-center justify-end gap-2 lg:gap-10 max-w-[1140px] mx-auto h-full">
            <div className="md:w-[60%] m-8">
              <p className="text-primary text-[24px] lg:text-[36px] font-extrabold lg:leading-[45px]">
                Empowering Affordable and Accessible Education
              </p>
              <p className="py-3 text-[#000000] text-[14px] lg:text-[18px]">
                Efficiently manage fees, simplify expenses, and conduct
                hassle-free online transactions for educational institutions.
              </p>
              <div className="grid grid-cols-2 gap-4 lg:max-w-[70%] mt-8">
                <button
                  className="text-white bg-primary py-1 px-6 text-[16px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => {
                    // window.open(routes_path?.signup);
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Sign Up Today
                </button>
                <button
                  className="text-secondary border-[1px] text-[16px] lg:text-[18px] border-secondary py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                  onClick={() => navigate(routes_path?.contactUs)}
                >
                  Talk to Sales
                </button>
              </div>
            </div>
            <div
              className="md:w-[40%] px-8 md:p-8"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img src={banner} alt="education" className="object-contain" />
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-10 items-center py-10 px-8 md:px-20">
          <div className="md:w-[40%]">
            <p className="text-primary text-[20px] md:text-[24px] lg:text-[35px] font-bold text-center md:text-left">
              Observe the functionality of our Educational Payment System
            </p>
          </div>
          <div className="pb-10 h-[200px] md:h-auto flex justify-center md:w-[60%]">
            <div className="relative video-effect">
              <video
                autoPlay
                loop
                muted
                playsInline
                className="h-[150px] md:h-auto rounded-[20px]"
              >
                <source src={educationFlow} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <div className="py-10 industries-bg">
          <p className="text-primary text-[24px] lg:text-[40px] px-4 font-bold leading-[45px] text-center">
            Comprehensive Payment Solution for
            <span className="text-secondary"> Indian Education</span>
          </p>

          <div className="max-w-[1140px] mx-10 lg:mx-auto flex flex-wrap justify-center gap-x-20 gap-y-10 lg:gap-y-20 my-10 lg:my-20">
            {educationData?.map((item, index) => {
              return (
                <div
                  className="bg-white rounded-[20px] text-center w-[300px] p-10 relative"
                  key={index}
                >
                  <img
                    src={item.iconUrl}
                    alt={item.title}
                    className="mx-auto w-[60px] lg:w-[80px]"
                  />
                  <p className="text-primary mt-4 text-[22px] font-bold">
                    {item.title}
                  </p>
                  <p className="text-[#212529] mt-3 font-medium">
                    {item.description}
                  </p>
                  <button
                    className="absolute -bottom-4 left-[50%] translate-x-[-50%] bg-primary rounded-[5px] px-4 py-1 text-white font-bold"
                    onClick={() => navigate(item.navigationUrl)}
                  >
                    Know More
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <SimplifyComponent
          title={"EDUCATION PAYMENTS"}
          content={
            "Manage student fee payments, vendor payouts, and streamline collections with ease. Contact us for custom solutions or create your account now"
          }
          image={simplifyImg}
          height={"h-[300px] md:h-[500px]"}
        />
      </div>
    </>
  );
};

export default HOC(Education);
