import React from "react";
import { routes_path } from "../config/routesPath";

const ApplyForAccount = () => {
  return (
    <>
      <div className="grid lg:flex items-center justify-center lg:justify-between gap-4 bg-gradient-to-r from-[#283c91] to-[#119bd7] p-4 lg:p-16">
        <p className="text-white grid text-center lg:text-left">
          <span className="text-[22px] lg:text-[36px]">
            Apply for an account in minutes
          </span>
          <span className="text-[16px] lg:text-[18px] pt-4">
            Get your Touras account today!
          </span>
        </p>

        <button
          className="text-[14px] max-w-[200px] lg:max-w-max mx-auto lg:mx-0 text-white bg-[#2faa4a] hover:scale-95 transition-all py-4 px-8 rounded"
          onClick={() => {
            window.open(routes_path?.signup);
          }}
        >
          GET YOUR ACCOUNT
        </button>
      </div>
    </>
  );
};

export default ApplyForAccount;
