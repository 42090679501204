import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast, { toastConfig } from "react-simple-toasts";
import { AppConfig } from "../../apiservice/AppConfig";
import TextInput from "../textinput/TextInput";
import SelectDropdown from "../select-dropdown/SelectDropdown";
import fetchApi from "../../apiservice/ApiService";

const PartnerModal = ({ showModal, setShowModal, pageName }) => {
  const modalRef = useRef();
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [citiesData, setCitiesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [form, setForm] = useState({
    name: "",
    email: "",
    company_name: "",
    phone: "",
    website: "",
    company_address: "",
  });

  const handleGetCities = (id) => {
    fetchApi
      .getCities(id)
      .then((response) => {
        let newCitiesArray = [];
        response?.data?.data?.forEach((item) => {
          newCitiesArray.push({ id: item?.id, label: item?.name });
        });
        setCitiesData(newCitiesArray);
      })
      .catch((error) => {
        console.log(error);
        toastConfig({ theme: "failure" });
        toast(error?.message);
      });
  };

  const handleGetStates = () => {
    fetchApi
      .getStates()
      .then((response) => {
        let newStateArray = [];
        response?.data?.data?.forEach((item) => {
          newStateArray.push({ id: item?.id, label: item?.name });
        });
        setStatesData(newStateArray);
      })
      .catch((error) => {
        toastConfig({ theme: "failure" });
        toast(error?.message);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "email") {
      const mail = e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      setForm({ ...form, [e.target.name]: e.target.value });
      if (mail) {
        setIsValidEmail(true);
        setError({ ...error, email: "" });
      } else if (e.target.value === "") {
        setForm({ ...form, [e.target.name]: "" });
        setError({ ...error, email: "" });
      } else {
        setError({ ...error, email: "Please enter a valid email address." });
      }
    } else if (e.target.name === "phone") {
      const tel = e.target.value.match(/^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/gm);
      setForm({ ...form, [e.target.name]: e.target.value });
      if (tel) {
        setIsValidPhone(true);
        setError({ ...error, phone: "" });
      } else if (e.target.value === "") {
        setForm({ ...form, [e.target.name]: "" });
        setError({ ...error, phone: "" });
      } else {
        setError({ ...error, phone: "Please enter a valid phone number." });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    if (
      form?.name === "" ||
      form?.company_name === "" ||
      form?.email === "" ||
      form?.phone === "" ||
      form?.website === "" ||
      form?.company_address === "" ||
      selectedCity === null ||
      selectedState === null
    ) {
      toastConfig({ theme: "failure" });
      toast("Please fill all the mandatory fields to send message.");
      setTimeout(() => {
        setLoader(false);
      }, 500);
      return false;
    }
    if (isValidEmail && isValidPhone) {
      const _form = new FormData();
      _form.append("name", form?.name);
      _form.append("company_name", form?.company_name);
      _form.append("email", form?.email);
      _form.append("phone", form?.phone);
      _form.append("website", form?.website);
      _form.append("company_address", form?.company_address);
      _form.append("page_name", pageName);
      _form.append("city", selectedCity?.id);
      _form.append("state", selectedState?.id);
      axios
        .post(AppConfig.baseUrl + "customer/landing/become-partner", _form)
        .then((response) => {
          setForm({
            name: "",
            email: "",
            company_name: "",
            phone: "",
            website: "",
            company_address: "",
          });
          setSelectedCity(null);
          setSelectedState(null);
          setShowModal(false);
          toastConfig({ theme: "success" });
          toast(response?.data?.message);
        })
        .catch((error) => {
          console.log(error);
          setError(error?.response?.data?.errors);
          toastConfig({ theme: "failure" });
          if (error?.response?.data?.message) {
            toast(error?.response?.data?.message);
          } else {
            toast("Email not sent.");
          }
        })
        .finally(() => {
          setLoader(false);
          setIsValidEmail(false);
          setIsValidPhone(false);
        });
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetStates();
    function closePages(e) {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setShowModal(false);
        document.body.style.overflow = "auto";
      }
    }
    document.body.style.overflow = "hidden";
    document.addEventListener("mousedown", closePages);

    return () => {
      document.removeEventListener("mousedown", closePages);
      setShowModal(false);
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    showModal && (
      <React.Fragment>
        <div className="bg-[#00000030] fixed top-0 w-full h-full z-20">
          <div className="flex justify-center items-center h-full">
            <div
              className="w-[90%] md:w-max md:max-w-[600px] md:m-auto h-[80%] overflow-auto md:h-max"
              ref={modalRef}
            >
              <div className="bg-white md:min-w-[500px] w-auto h-auto rounded-[10px] p-10">
                <div className="grid md:grid-cols-2 gap-x-6">
                  <TextInput
                    className="bg-transparent"
                    themeColor="#060606"
                    label={"Name"}
                    placeholder={"Enter Your Name"}
                    type={"text"}
                    name="name"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.name !== "") {
                        setError({ ...error, name: "" });
                      }
                    }}
                    error={Boolean(error?.name)}
                    helperText={error?.name}
                  />
                  <TextInput
                    className="bg-transparent"
                    themeColor="#060606"
                    label={"Company Name"}
                    placeholder={"Enter Company's Name"}
                    type={"text"}
                    name="company_name"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.name !== "") {
                        setError({ ...error, company_name: "" });
                      }
                    }}
                    error={Boolean(error?.company_name)}
                    helperText={error?.company_name}
                  />
                  <TextInput
                    className="bg-transparent"
                    themeColor="#060606"
                    label={"Email Address"}
                    placeholder={"Enter Email Address"}
                    type={"email"}
                    name="email"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={Boolean(error?.email)}
                    helperText={error?.email}
                  />
                  <TextInput
                    className="bg-transparent"
                    themeColor="#060606"
                    label={"Contact No."}
                    placeholder={"Enter Contact Number"}
                    type={"number"}
                    name="phone"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={Boolean(error?.phone)}
                    helperText={error?.phone}
                  />
                  <SelectDropdown
                    label={"State"}
                    themeColor={"#060606"}
                    placeHolder="Select State"
                    data={statesData}
                    value={selectedState?.label}
                    onClick={(val) => {
                      setSelectedState(val);
                      handleGetCities(val?.id);
                    }}
                    error={Boolean(error?.state)}
                    helperText={error?.state}
                  />
                  <SelectDropdown
                    label={"City"}
                    themeColor={"#060606"}
                    placeHolder="Select City"
                    message="Please choose a state in order to select a city."
                    data={citiesData}
                    value={selectedCity?.label}
                    onClick={(val) => {
                      setSelectedCity(val);
                    }}
                    error={Boolean(error?.city)}
                    helperText={error?.city}
                  />
                </div>
                <div className="grid gap-6 mt-6">
                  <TextInput
                    className="bg-transparent"
                    themeColor="#060606"
                    label={"Website"}
                    placeholder={"Enter Website URL"}
                    type={"text"}
                    name="website"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.name !== "") {
                        setError({ ...error, website: "" });
                      }
                    }}
                    error={Boolean(error?.website)}
                    helperText={error?.website}
                  />
                  <TextInput
                    className="bg-transparent"
                    themeColor="#060606"
                    label={"Company Address"}
                    placeholder={"Enter Company's Address"}
                    type={"text"}
                    name="company_address"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.name !== "") {
                        setError({ ...error, company_address: "" });
                      }
                    }}
                    error={Boolean(error?.company_address)}
                    helperText={error?.company_address}
                  />
                </div>
                <div className="flex justify-center items-center mt-6">
                  {loader ? (
                    <p
                      className="bg-primary text-white text-sm font-semibold py-3 px-6 rounded-[4px] flex justify-center items-center w-[120px]"
                      onClick={() => {
                        toastConfig({ theme: "failure" });
                        toast("please wait! we are processing your request.");
                      }}
                    >
                      <span className="loader"></span>
                    </p>
                  ) : (
                    <button
                      className="bg-primary text-white text-sm font-semibold py-3 px-6 rounded-[4px] cursor-pointer"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Send Email
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default PartnerModal;
