import React, { useEffect, useState } from "react";
import HOC from "../../component/HOC";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/social-icons/left-arrow.png";
import { routes_path } from "../../config/routesPath";

const BlogViewAll = () => {
  const location = useLocation();
  const [allBlogs, setAllBlogs] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setAllBlogs(location?.state?.blogs);
  }, []);

  return (
    <React.Fragment>
      <div className="mx-4 md:mx-20 pt-4">
        <div className="flex flex-col justify-between">
          <p
            className="w-max cursor-pointer flex gap-2 items-center text-primary font-semibold"
            onClick={() => {
              navigate(routes_path?.blog);
            }}
          >
            <img src={back} alt="back to blog" width={15} height={15} />
            Back
          </p>
          <p className="text-primary text-center text-[24px] md:text-[30px] font-extrabold">
            {allBlogs?.title}
          </p>
          <p className="w-[60px]"></p>
        </div>

        <div className="grid lg:grid-cols-3 gap-10 py-10">
          {allBlogs?.blogs?.length > 0 &&
            allBlogs?.blogs?.map((ele, key) => {
              return (
                <React.Fragment>
                  <div
                    key={key}
                    className="hover:-translate-y-2 transition-all duration-500"
                  >
                    <img
                      src={ele?.["image_url"]}
                      alt="blog 1"
                      className="cursor-pointer h-[285px] object-cover"
                      onClick={() => {
                        navigate(
                          `/blog/${ele["title"]
                            .toLowerCase()
                            .replace(/[\s,-/!:@#$%^*()"?`]+/g, "-")
                            .replace(/[\s,-/!:@#$%^*()"?`]$/g, "")}/${ele?.id}`,
                          {
                            state: {
                              blog: ele?.id,
                            },
                          }
                        );
                      }}
                    />
                    <div className="py-8 font-roboto">
                      <p
                        className="text-primary text-[25px] font-extrabold cursor-pointer line-clamp-2"
                        onClick={() => {
                          navigate(
                            `/blog/${ele["title"]
                              .toLowerCase()
                              .replace(/[\s,-/!:@#$%^*()"?`]+/g, "-")
                              .replace(/[\s,-/!:@#$%^*()"?`]$/g, "")}/${
                              ele?.id
                            }`,
                            {
                              state: {
                                blog: ele?.id,
                              },
                            }
                          );
                        }}
                      >
                        {ele["title"]}
                      </p>
                      <div className="flex items-center gap-5 text-[#57647C] my-3">
                        <label className="flex items-center gap-2 text-[12px]">
                          {ele?.["start_date"]
                            ? dayjs(ele["start_date"]).format("MMM DD, YYYY")
                            : dayjs(ele["created_at"]).format("MMM DD, YYYY")}
                        </label>
                      </div>
                      <p className="text-[18px] text-content line-clamp-3 overflow-hidden">
                        {ele.description}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HOC(BlogViewAll);
