import clsx from "clsx";
import React from "react";

const TextInput = ({
  label,
  type,
  placeholder,
  value,
  name,
  onChange,
  className,
  themeColor,
  defaultValue,
  error,
  helperText,
  required
}) => {
  return (
    <div className="text-[#212121] grid">
      <label
        className={clsx(
          "text-[14px] font-medium",
          error ? "text-red-500" : themeColor ? `text-[${themeColor}]` : ""
        )}
      >
        {label}<span className="text-secondary">{required ? "*" : ""}</span>
      </label>
      <input
        className={clsx(
          "border-[1px] outline-none p-2 w-full rounded-[8px]",
          className,
          error ? "border-red-500" : themeColor ? `border-[${themeColor}]` : "border-[#A5A5A5]"
        )}
        type={type}
        placeholder={placeholder || label}
        alt=""
        value={value}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      {error ? (
        <p className="text-[12px] text-red-500 mt-1 h-auto">{helperText}</p>
      ) : (
        <p className="text-[12px] mt-1 min-h-[18px]"></p>
      )}
    </div>
  );
};

export default TextInput;
