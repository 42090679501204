import aasaan from "../../assets/images/Products-images/aasaan.svg";
import payManage from "../../assets/images/Products-images/aggregation.svg";
import api from "../../assets/images/Products-images/api.svg";
import billPayment from "../../assets/images/Products-images/bill-payment.svg";
import connectedBanking from "../../assets/images/Products-images/connect-banking.svg";
import neux from "../../assets/images/Products-images/neux.svg";
import onboarding from "../../assets/images/Products-images/onboarding.svg";
import payouts from "../../assets/images/Products-images/payouts.svg";
import whitelabel from "../../assets/images/Products-images/whitelabel.svg";
import businessStandards from "../../assets/images/about-images/business-std.svg";
import combinator from "../../assets/images/about-images/combinator.svg";
import economicTimes from "../../assets/images/about-images/economic-times.svg";
import hindu from "../../assets/images/about-images/hindu.svg";
import inc from "../../assets/images/about-images/inc.svg";
import mint from "../../assets/images/about-images/mint.svg";
import productHunt from "../../assets/images/about-images/product-hunt.svg";
import techAsia from "../../assets/images/about-images/tech-asia.svg";
import techCrunch from "../../assets/images/about-images/tech-crunch.svg";
import yourStory from "../../assets/images/about-images/your-story.svg";
import aasaanClient1 from "../../assets/images/clientele/aasaan-client-1.png";
import aasaanClient2 from "../../assets/images/clientele/aasaan-client-2.png";
import aasaanClient3 from "../../assets/images/clientele/aasaan-client-3.png";
import aasaanClient4 from "../../assets/images/clientele/aasaan-client-4.png";
import aasaanClient5 from "../../assets/images/clientele/aasaan-client-5.png";
import client2 from "../../assets/images/clientele/client2.png";
import {
  default as client3,
  default as testimonialBrand3,
} from "../../assets/images/clientele/client3.png";
import client4 from "../../assets/images/clientele/client4.png";
import client5 from "../../assets/images/clientele/client5.png";
import client6 from "../../assets/images/clientele/client6.png";
import client7 from "../../assets/images/clientele/client7.png";
import rating from "../../assets/images/testimonial-images/rating.png";
import testimonialBrand1 from "../../assets/images/testimonial-images/testimonial-brand-1.png";
import testimonialBrand2 from "../../assets/images/testimonial-images/testimonial-brand-2.png";
import bankingPartnerOne from "../../assets/images/bank logos/icici_logo.png";
import bankingPartnerTwo from "../../assets/images/bank logos/DBS-Bank-logo.png";
import bankingPartnerThree from "../../assets/images/bank logos/RAKBANK.png";
import bankingPartnerFour from "../../assets/images/bank logos/Yes-bank_logo.png";
import bankingPartnerFive from "../../assets/images/bank logos/ADCB_logo.png";
import bankingPartnerSix from "../../assets/images/bank logos/ADIB_logo.png";
import bankingPartnerSeven from "../../assets/images/bank logos/Axis_Bank-Logo.wine.svg";
import bankingPartnerEight from "../../assets/images/bank logos/equitas_logo.png";
import bankingPartnerNine from "../../assets/images/bank logos/FAB_logo.png";
import homeProductVideo1 from "../../assets/video/Home_page_videos/PG.mp4";
import homeProductVideo2 from "../../assets/video/Home_page_videos/payout.mp4";
import homeProductVideo3 from "../../assets/video/Home_page_videos/whitelabel.mp4";
import homeProductVideo4 from "../../assets/video/Home_page_videos/aasaan check.mp4";
import homeProductVideo5 from "../../assets/video/Home_page_videos/apix.mp4";
import homeProductVideo6 from "../../assets/video/Home_page_videos/Aasaan pay.mp4";
import homeProductVideo7 from "../../assets/video/Home_page_videos/neux.mp4";
import homeProductVideo8 from "../../assets/video/Home_page_videos/Idpe.mp4";
import solutionIcon1 from "../../assets/images/Ecommerce.svg";
import solutionIcon2 from "../../assets/images/Society 1.svg";
import solutionIcon3 from "../../assets/images/retail.svg";
import solutionIcon4 from "../../assets/images/education.svg";
import solutionIcon5 from "../../assets/images/Other Industries.svg";
import { routes_path } from "../../config/routesPath";

export const productData = [
  {
    iconUrl: payManage,
    productName: "Payment Platform",
    productDescription:
      "Multiple payment options. Quick activation. Whitelabeling available with faster go-live",
    navigateUrl: routes_path?.paymentPlatform,
  },
  {
    iconUrl: neux,
    productName: "NeuX",
    productDescription:
      "Efficiently manage your B2B payments with the best features from transfer of money to your sales purchase, catalogue management & analytics",
    navigateUrl: routes_path?.neuX,
  },
  {
    iconUrl: aasaan,
    productName: "Aasaan",
    productDescription:
      "Revolutionise your shopping experience and drive higher conversion rates on the brand's website with India's 1st merchant focused checkout solutions.",
    navigateUrl: routes_path?.aasaan,
  },
  {
    iconUrl: whitelabel,
    productName: "Whitelabel",
    productDescription:
      "Maintain your brand identity with ease and avail all benefits through our custom made solutions and platforms",
    navigateUrl: routes_path?.whitelabel,
  },
  {
    iconUrl: billPayment,
    productName: "Bill Payment",
    productDescription:
      "360 degree Suite with API integration and white-labelled portal for offline channels.",
    navigateUrl: routes_path?.onox,
  },
  {
    iconUrl: api,
    productName: "APIX",
    productDescription:
      "Begin your Verification journey & reduce costs with our new era of API platform.",
    navigateUrl: routes_path?.apix,
  },
  {
    iconUrl: payouts,
    productName: "Payouts",
    productDescription:
      "Enable recipients to choose their preferred payment mode",
    navigateUrl: routes_path?.payouts,
  },
  {
    iconUrl: onboarding,
    productName: "Digital On-boarding Solution",
    productDescription:
      "Experience the best CRM and advanced on-boarding platform which can meet all automation needs",
    navigateUrl: routes_path?.apix,
  },
  {
    iconUrl: connectedBanking,
    productName: "Connected Banking",
    productDescription:
      "Manage your business finances easily by connecting accounts on one dashboard",
    navigateUrl: routes_path?.payouts,
  },
];

export const testimonialData = [
  {
    id: 1,
    url: testimonialBrand1,
    message:
      "This is to show our appreciation for the marathon effort you guys put in for us. We wanted to say a very BIG THANK YOU for the effort and your continued support.We appreciate and love you all!",
    name: "Trish Kunath",
    company_profile: "Team Atlantis",
    rating: rating,
  },
  {
    id: 2,
    url: testimonialBrand3,
    message:
      "We at Railway Recruitment Cell Northern Railway hereby state that during the online application for engagement of Act Apprentices, we have availed services of Touras's Payment Gateway and duly appreciate the services for its exemplary features. We are highly pleased as their services has benefitted significantly due to the excellent services rendered by your team.",
    name: "Mukesh Kumar",
    company_profile: "APO",
    rating: rating,
  },
  {
    id: 3,
    url: testimonialBrand2,
    message:
      "Customer Support at Touras is prompt, courteous, and extremely helpful. Whenever I run into a problem, the team is always there with a prompt solution. I've found their swift customer service to be amazing and has been helpful in resolving my concerns.",
    name: "Umesh Arya",
    company_profile: "IT Head, PCJ jewellers",
    rating: rating,
  },
];

export const featuredData = [
  {
    url: businessStandards,
    alt: "business standards logo",
  },
  {
    url: hindu,
    alt: "The Hindu logo",
  },
  {
    url: mint,
    alt: "mint logo",
  },
  {
    url: yourStory,
    alt: "yourstory logo",
  },
  {
    url: productHunt,
    alt: "product hunt logo",
  },
  {
    url: combinator,
    alt: "combinator logo",
  },
  {
    url: techCrunch,
    alt: "tech crunch logo",
  },
  {
    url: techAsia,
    alt: "tech asia logo",
  },
  {
    url: inc,
    alt: "inc logo",
  },
  {
    url: economicTimes,
    alt: "economic times logo",
  },
  {
    url: businessStandards,
    alt: "business standards logo",
  },
  {
    url: hindu,
    alt: "The Hindu logo",
  },
  {
    url: mint,
    alt: "mint logo",
  },
  {
    url: yourStory,
    alt: "yourstory logo",
  },
  {
    url: productHunt,
    alt: "product hunt logo",
  },
  {
    url: combinator,
    alt: "combinator logo",
  },
  {
    url: techCrunch,
    alt: "tech crunch logo",
  },
  {
    url: techAsia,
    alt: "tech asia logo",
  },
  {
    url: inc,
    alt: "inc logo",
  },
  {
    url: economicTimes,
    alt: "economic times logo",
  },
];

export const clienteleData = [
  // {
  //   alt_text: "Clientele",
  //   imgUrl: client1
  // },
  {
    alt_text: "Clientele",
    imgUrl: client2,
  },
  {
    alt_text: "Clientele",
    imgUrl: client3,
  },
  {
    alt_text: "Clientele",
    imgUrl: client4,
  },
  {
    alt_text: "Clientele",
    imgUrl: client5,
  },
  {
    alt_text: "Clientele",
    imgUrl: client6,
  },
  {
    alt_text: "Clientele",
    imgUrl: client7,
  },
  // {
  //   alt_text: "Clientele",
  //   imgUrl: client8
  // },
];

export const bankingPartnerData = [
  {
    alt_text: "banking partner icici",
    imgUrl: bankingPartnerOne,
  },
  {
    alt_text: "banking partner DBS",
    imgUrl: bankingPartnerTwo,
  },
  {
    alt_text: "banking partner RAKBANK",
    imgUrl: bankingPartnerThree,
  },
  {
    alt_text: "banking partner YES Bank",
    imgUrl: bankingPartnerFour,
  },
  {
    alt_text: "banking partner ADCB",
    imgUrl: bankingPartnerFive,
  },
  {
    alt_text: "banking partner ADIB",
    imgUrl: bankingPartnerSix,
  },
  {
    alt_text: "banking partner Axis",
    imgUrl: bankingPartnerSeven,
  },
  {
    alt_text: "banking partner Equitas",
    imgUrl: bankingPartnerEight,
  },
  {
    alt_text: "banking partner FAB",
    imgUrl: bankingPartnerNine,
  },
  {
    alt_text: "banking partner icici",
    imgUrl: bankingPartnerOne,
  },
  {
    alt_text: "banking partner DBS",
    imgUrl: bankingPartnerTwo,
  },
  {
    alt_text: "banking partner RAKBANK",
    imgUrl: bankingPartnerThree,
  },
  {
    alt_text: "banking partner YES Bank",
    imgUrl: bankingPartnerFour,
  },
  {
    alt_text: "banking partner ADCB",
    imgUrl: bankingPartnerFive,
  },
  {
    alt_text: "banking partner ADIB",
    imgUrl: bankingPartnerSix,
  },
  {
    alt_text: "banking partner Axis",
    imgUrl: bankingPartnerSeven,
  },
  {
    alt_text: "banking partner Equitas",
    imgUrl: bankingPartnerEight,
  },
  {
    alt_text: "banking partner FAB",
    imgUrl: bankingPartnerNine,
  },
];

export const aasaanClienteleData = [
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient1,
  },
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient2,
  },
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient3,
  },
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient4,
  },
  {
    alt_text: "Aasaan Clientele",
    imgUrl: aasaanClient5,
  },
];

export const bringProficiencyData = [
  {
    title: "Powerful Dashboards and Portals",
    description:
      "Unlock sales growth with transactional insights &powerful features",
  },
  {
    title: "Multi-platform & plugin compatibility",
    description:
      "Best in class uptime of 99.9%. & plugins like Shopify, Woo-comerce etc.",
  },
  {
    title: "Experience the best",
    description:
      "A strong and reliable digital infrastructure capable of handling 1M+ transactions daily.",
  },
  {
    title: "Cloud agnostic support",
    description: "Perform consistently across platforms, even when migrating.",
  },
  {
    title: "Secure",
    description:
      "We're PCI DSS Level 1 compliant, so you don't have to worry about meeting regulations.",
  },
  {
    title: "Easy Integration",
    description:
      "Integrate and go live in less than an hour with Touras's platform plugins.",
  },
];

export const financePlatformTitleData = [
  {
    id: "accept-payments",
    title: "Accept Payments",
  },
  {
    id: "make-payouts",
    title: "Your Payouts. Your Way",
  },
  {
    id: "customise-with-whiteLabeling",
    title: "Tailored solutions",
  },
  {
    id: "one-click-checkout",
    title: "1 Click Checkout",
  },
  {
    id: "right-verification",
    title: "Right Verification",
  },
  {
    id: "new-age-soundbox",
    title: "New Age Soundbox",
  },
  {
    id: "seamless-b2b-management",
    title: "Seamless B2B management",
  },
  {
    id: "biometric-checkout",
    title: "Biometric Checkout",
  },
];

export const financePlatformProductData = [
  {
    id: "accept-payments",
    title: "Accept Payments",
    heading: "The Right Platform for all payments",
    description:
      "Grow your business by accepting online payments with high success rates, multiple payment modes and state-of-the-art features",
    videoURL: homeProductVideo1,
    navigationURL: routes_path?.paymentPlatform,
  },
  {
    id: "make-payouts",
    title: "Make Payouts",
    heading: "Your Payout .Your Way",
    description:
      "Take control of how you want to send funds via APIs or Bulk Payments ",
    videoURL: homeProductVideo2,
    navigationURL: routes_path?.payouts,
  },
  {
    id: "customise-with-whiteLabeling",
    title: "Customise with WhiteLabeling",
    heading: "Right payments at your fingertips",
    description:
      "Maintain brand identity with ease & avail all benefits through our custom made solutions and platform",
    videoURL: homeProductVideo3,
    navigationURL: routes_path?.whitelabel,
  },
  {
    id: "one-click-checkout",
    title: "One Click Checkout",
    heading: "The Reliable Way to Track Sales",
    description: "Boost up your sales with India's top checkout solutions",
    videoURL: homeProductVideo4,
    navigationURL: routes_path?.aasaan,
  },
  {
    id: "right-verification",
    title: "Right Verification",
    heading: "Right Way to begin your verification & onboarding journey",
    description:
      "Simplify KYC & KYB  Checks with multiple APIs  on a single Platform",
    videoURL: homeProductVideo5,
    navigationURL: routes_path?.apix,
  },
  {
    id: "new-age-soundbox",
    title: "New Age Soundbox",
    heading: "Be Resilient & Never Miss A Payment",
    description:
      "Experience instant audio payment confirmation with the AasaanPay !",
    videoURL: homeProductVideo6,
  },
  {
    id: "seamless-b2b-management",
    title: "Seamless B2B management",
    heading:
      "Reliable Way to manage your business journey's with customized digital solutions",
    description:
      "Manage your payments with suite of features like sales, purchase, expense & catalogue management & analytics",
    videoURL: homeProductVideo7,
    navigationURL: routes_path?.neuX,
  },
  {
    id: "biometric-checkout",
    title: "Biometric Checkout",
    heading: "Your Face is Your Card",
    description:
      "Enable payment acceptance using facial recognition without the hassle of mobile, cards etc. ",
    videoURL: homeProductVideo8,
  },
];

export const getStartedData = [
  {
    title: "Sign Up",
    description:
      "Sign Up  your account within a minute by adding Name, Email and Phone No",
  },
  {
    title: "Details Verify",
    description: "Submit mandatory docs and await for verification by our team",
  },
  {
    title: "Engage",
    description:
      "Once verified, start accepting payments, manage your transactions, and grow your business.",
  },
];

export const solutionsData = [
  {
    title: "Ecommerce",
    navigationURL: routes_path?.eCommerce,
    iconURL: solutionIcon1,
  },
  {
    title: "Society",
    navigationURL: routes_path?.society,
    iconURL: solutionIcon2,
  },
  {
    title: "Retail",
    navigationURL: routes_path?.retail,
    iconURL: solutionIcon3,
  },
  {
    title: "Education",
    navigationURL: routes_path?.education,
    iconURL: solutionIcon4,
  },
  {
    title: "Other Industries",
    navigationURL: routes_path?.industries,
    iconURL: solutionIcon5,
  },
];
