import React, { useEffect } from "react";
import xIcon from "../../assets/images/Products-images/X-white.png";
import bannerImg from "../../assets/images/Products-images/aasaan-banner.png";
import footerImg from "../../assets/images/Products-images/aasaan-footer.png";
import productLogo from "../../assets/images/Products-images/aasaanlogowhite.png";
import shopperImg from "../../assets/images/Products-images/wyswg.png";
import safexpayLogo from "../../assets/images/logo-white.svg";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import { aasaanClienteleData } from "../home/homeData";
import { aasaanData, aasaanShopperData } from "./productDetailsData";

const Aasaan = () => {
  useEffect(() => {
    document.title = "Aasaan - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header
        safexpayLogo={safexpayLogo}
        xIcon={xIcon}
        productLogo={productLogo}
        bgColor={"#060606"}
      />
      <div className="overflow-x-hidden">
        <div className="flex flex-col-reverse md:flex-row lg:h-[500px] justify-center items-center bg-primary text-white px-4 py-10 lg:px-20 font-MyriadPro">
          <div
            className="lg:w-[50%]"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-[24px] lg:text-[50px] font-bold">
              Your shortcut to conversions
            </p>
            <p className="font-light text-[14px] lg:text-[18px] py-4">
              Aasaan helps brands' give a 1 click checkout experience & also
              improve their conversions.
            </p>
            <div className="grid grid-cols-2 lg:max-w-[60%] gap-4">
              <button
                className="text-white bg-gradient-to-b from-[#FF0000] to-[#000000] py-2 px-6 font-medium text-[12px] lg:text-[14px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  window.open("https://www.aasaancheckout.com", "_blank");
                }}
              >
                Visit Us
              </button>
              <button className="border-[1px] border-primary font-medium text-[12px] lg:text-[14px] py-[1px] px-[1px] rounded-[20px] hover:scale-95 transition-all">
                <span
                  className="w-full flex justify-center items-center border-[1px] border-primary bg-white py-[8px!important] px-[14px!important] rounded-[20px] text-primary"
                  onClick={() => {
                    window.open(
                      "https://www.aasaancheckout.com/book-a-demo/",
                      "_blank"
                    );
                  }}
                >
                  Try Demo
                </span>
              </button>
            </div>
          </div>
          <div
            className="lg:w-[50%] h-full flex justify-center mt-10 lg:mt-0 green-spot-bg"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img src={bannerImg} alt="banner" className="h-auto mx-auto" />
          </div>
        </div>

        {/* Our Clientele */}
        <section className="py-10">
          <p className="text-[28px] lg:text-[40px] text-primary font-bold text-center">
            Our Clientele
          </p>
          <div className="flex flex-wrap gap-20 justify-center items-center">
            {aasaanClienteleData?.map((item) => {
              return (
                <img
                  src={item?.imgUrl}
                  alt={item?.alt_text}
                  key={item?.index}
                />
              );
            })}
          </div>
        </section>

        <div className="py-10 bg-primary relative z-10">
          <p className="text-[30px] lg:text-[38px] font-bold text-center px-4">
            <span className="text-[#ffffff]">Why Go For</span>{" "}
            <span className="text-secondary">
              <span className="aasaan_underline">Merchant</span> Centric
              Checkout
            </span>
          </p>

          <div className="m-4 lg:m-10 grid lg:grid-cols-2 items-center justify-center gap-4 lg:gap-8 relative z-10">
            {aasaanData?.map((item, index) => {
              return (
                <div
                  className="py-6 px-6 lg:px-10 h-auto rounded-[20px] flex items-start gap-4 border-[1px] border-[#4B4B4B]"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="w-[20%] h-full flex">
                    <img
                      src={item.imageUrl}
                      alt={item.title}
                      className="w-[100px] h-auto"
                    />
                  </div>
                  <div className="w-[80%]">
                    <p className="text-[18px] text-secondary font-bold">
                      {item.title}
                    </p>
                    <p className="text-[14px] text-[#FFFFFF] font-light pt-3">
                      {item.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="green-spot-bg hidden lg:block absolute top-[40%] -left-[40%] w-[70%] h-[120%]"></div>
        </div>

        <div className="py-10 px-4 flex justify-center bg-primary">
          <iframe
            className="lg:w-[700px] lg:h-[400px] w-full h-[250px] relative z-10"
            src="https://www.youtube.com/embed/Q0HXwVbo4oI"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          ></iframe>
        </div>

        <div className="py-20 bg-primary flex items-start flex-col-reverse lg:flex-row relative overflow-hidden">
          <div className="green-spot-bg hidden lg:block absolute top-0 -left-[30%] w-[70%] h-[120%]"></div>
          <div className="green-spot-bg hidden lg:block absolute -bottom-[20%] -right-[25%] w-[50%] h-[50%]"></div>
          <div
            className="lg:w-[40%] relative z-10"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img src={shopperImg} alt="what your shoppers will get" />
          </div>
          <div className="lg:w-[60%] relative z-10">
            <p className="text-[28px] lg:text-[40px] text-center lg:text-left font-bold text-[#ffffff] mx-10">
              What Your <span className="text-secondary">Shoppers</span> Will
              Get
            </p>
            <div className="grid gap-4 m-4 lg:m-10 mt-8 lg:mt-0 lg:max-w-[50%]">
              {aasaanShopperData?.map((ele, index) => {
                return (
                  <div
                    className="py-2 flex flex-col justify-between"
                    key={index}
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <p className="text-[18px] text-secondary font-bold">
                      {ele.title}
                    </p>
                    <p className="text-[18px] text-[#FFFFFF] pt-3">
                      {ele.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="lg:pt-10 lg:pb-0 py-10 text-center flex px-4 lg:px-10">
          <div className="lg:w-[60%]">
            <p className="text-primary text-[24px] lg:text-[40px] font-bold">
              We are here for you at every step
            </p>
            <p className="text-primary text-[14px] lg:text-[18px] lg:w-[60%] mx-auto mt-4 font-light">
              We'll get you going quickly with our expert assistance for a
              trouble-free experience.
            </p>
            <div className="grid grid-cols-2 lg:max-w-[60%] gap-4 mx-auto mt-14">
              <button
                className="text-white bg-primary py-2 px-6 font-medium text-[12px] lg:text-[14px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  window.open(
                    "https://www.aasaancheckout.com/book-a-demo/",
                    "_blank"
                  );
                }}
              >
                Book a Demo
              </button>
              <button className="font-medium text-[12px] lg:text-[14px] py-[1px] px-[1px] rounded-[20px] hover:scale-95 transition-all">
                <span
                  className="w-full flex justify-center items-center border-[1px] border-primary bg-white py-[8px!important] px-[14px!important] rounded-[20px] text-primary"
                  onClick={() => {
                    window.open("https://www.aasaancheckout.com", "_blank");
                  }}
                >
                  Visit Site
                </span>
              </button>
            </div>
          </div>
          <div
            className="w-[40%] hidden lg:block"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img src={footerImg} alt="we are here" />
          </div>
        </div>
      </div>
      <Footer safexpayLogo={safexpayLogo} />
    </>
  );
};

export default Aasaan;
