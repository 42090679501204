import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo-white.svg";
import payOption from "../assets/images/price-payment-icon.png";
import facebook from "../assets/social-icons/facebook-app-symbol.png";
import insta from "../assets/social-icons/instagram-footer.png";
import linkedIn from "../assets/social-icons/linkedin.png";
import twitter from "../assets/social-icons/twitter-1.png";
import youtube from "../assets/social-icons/youtube.png";
import { routes_path } from "../config/routesPath";

const Footer = ({ safexpayLogo }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* Footer */}
      <section
        // className={clsx(window.location.pathname === "/aasaan" && "bg-primary")}
        className="bg-primary font-MyriadPro"
      >
        <div className="grid lg:flex gap-4 max-w-[1140px] mx-auto py-[76px]">
          <div className="lg:w-[40%] lg:pr-20 mx-auto">
            <img
              src={safexpayLogo ? safexpayLogo : logo}
              alt="logo"
              className="w-auto max-w-[200px] mx-auto lg:mx-0"
            />
            <p
              className={clsx(
                "leading-[30px] my-3 hidden lg:block text-white"
                // window.location.pathname === "/aasaan"
                //   ? "text-white"
                //   : "text-[#777777]"
              )}
            >
              Discover our innovative solutions for business growth. From
              neobanking to checkout platforms, we're your trusted partner.
              Optimize operations, enhance security, and boost the bottom line.
              Reshape business transactions with us today.
            </p>
            <img
              src={payOption}
              alt="payment options"
              className="w-[180px] mt-4 lg:mt-0 lg:w-[200px]"
            />
          </div>
          <div className="lg:w-[60%] grid lg:grid-cols-3 mt-6 lg:mt-0 px-6">
            <div className="hidden lg:block">
              <p
                className={clsx(
                  "relative pb-4 text-[24px] text-white footer-label"
                )}
              >
                Important Link
              </p>
              <ul
                className={clsx(
                  "grid mt-6 footer-li text-white"
                  // window.location.pathname === "/aasaan"
                  //   ? "text-white"
                  //   : "text-[#777777]"
                )}
              >
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.home);
                  }}
                >
                  Home
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.aboutUs);
                  }}
                >
                  About Us
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.partnerProgram);
                  }}
                >
                  Partner's Program
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.contactUs);
                  }}
                >
                  Contact Us
                </li>
                {/* <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.pricing);
                  }}
                >
                  Pricing
                </li> */}
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.industries);
                  }}
                >
                  Industries
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.career);
                  }}
                >
                  Career
                </li>
                {/* <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.mediaAndAwards);
                  }}
                >
                  Media & Awards
                </li> */}
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.FAQ);
                  }}
                >
                  FAQ
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.privacyPoliciy);
                  }}
                >
                  Privacy Policy
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.termsAndCondition);
                  }}
                >
                  Terms & Conditions
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    // window.open(routes_path.developerDoc, "_blank");
                    navigate(routes_path?.contactUs);
                  }}
                >
                  Developer Docs
                </li>
              </ul>
            </div>
            <div className="hidden lg:block">
              <p
                className={clsx(
                  "relative pb-4 text-[24px] text-white footer-label"
                )}
              >
                Products
              </p>
              <ul
                className={clsx(
                  "grid mt-6 footer-li text-white"
                  // window.location.pathname === "/aasaan"
                  //   ? "text-white"
                  //   : "text-[#777777]"
                )}
              >
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.paymentPlatform);
                  }}
                >
                  Payment Aggregator
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.payouts);
                  }}
                >
                  Payout
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.aasaan);
                  }}
                >
                  Aasaan
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.whitelabel);
                  }}
                >
                  Whitelabel
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.neuX);
                  }}
                >
                  NeuX
                </li>
                <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.apix);
                  }}
                >
                  APIX
                </li>
                {/* <li
                  className="relative pl-4 py-1 cursor-pointer"
                  onClick={() => {
                    navigate(routes_path.onox);
                  }}
                >
                  ONOX
                </li> */}
              </ul>
            </div>
            <div className="text-center lg:text-left">
              <p
                className={clsx(
                  "relative pb-4 text-[24px] text-white footer-label"
                )}
              >
                Get in Touch
              </p>
              <ul
                className={clsx(
                  "grid mt-6 text-white"
                  // window.location.pathname === "/aasaan"
                  //   ? "text-white"
                  //   : "text-[#777777]"
                )}
              >
                <li className="text-[14px] relative py-1 cursor-pointer">
                  Email: care@touras.in
                </li>
                <li className="text-[14px] relative py-1 cursor-pointer">
                  <span>Phone: 7738309366</span>
                </li>
                <li className="justify-center lg:justify-start relative my-4 cursor-pointer flex gap-2">
                  <img
                    src={facebook}
                    alt="social icons"
                    width={30}
                    title="Facebook"
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/profile.php?id=61565064460477&sk=about"
                      );
                    }}
                  />
                  <img
                    src={twitter}
                    alt="social icons"
                    width={30}
                    title="Twitter"
                    onClick={() => {
                      window.open("https://x.com/Touras_Official");
                    }}
                  />
                  <img
                    src={linkedIn}
                    alt="social icons"
                    width={30}
                    title="LinkedIn"
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/company/official-touras/?viewAsMember=true"
                      );
                    }}
                  />
                  <img
                    src={insta}
                    alt="social icons"
                    width={30}
                    title="Instagram"
                    onClick={() => {
                      window.open("https://www.instagram.com/official_touras/");
                    }}
                  />
                  <img
                    src={youtube}
                    alt="social icons"
                    width={30}
                    title="Youtube"
                    onClick={() => {
                      window.open("https://www.youtube.com/@officialtouras");
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-white bg-primary text-[14px] lg:text-[16px] text-center py-4 border-t border-t-white mx-20">
          Copyright @2024 Touras. All Rights Reserved
        </div>
      </section>
    </>
  );
};

export default Footer;
