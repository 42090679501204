import ultimateCollectionIcon7 from '../../assets/images/Industries-images/industry_icon_5.png';
import ultimateCollectionIcon1 from '../../assets/images/Products-images/aasaan.svg';
import ultimateCollectionIcon9 from '../../assets/images/Products-images/payouts_industry.png';
import getMoreIcon1 from '../../assets/images/partner-images/get-more-icon-1.png';
import getMoreIcon2 from '../../assets/images/partner-images/get-more-icon-2.png';
import getMoreIcon3 from '../../assets/images/partner-images/get-more-icon-3.png';
import getMoreIcon4 from '../../assets/images/partner-images/get-more-icon-4.png';
import partnerWithUsIcon1 from '../../assets/images/partner-images/partner-with-us-icon1.png';
import partnerWithUsIcon2 from '../../assets/images/partner-images/partner-with-us-icon2.png';
import partnerWithUsIcon3 from '../../assets/images/partner-images/partner-with-us-icon3.png';
import ultimateCollectionIcon2 from '../../assets/images/partner-images/ultimate-collection-icon-2.png';
import ultimateCollectionIcon3 from '../../assets/images/partner-images/ultimate-collection-icon-3.png';
import ultimateCollectionIcon4 from '../../assets/images/partner-images/ultimate-collection-icon-4.png';
import ultimateCollectionIcon5 from '../../assets/images/partner-images/ultimate-collection-icon-5.png';
import ultimateCollectionIcon6 from '../../assets/images/partner-images/ultimate-collection-icon-6.png';
import ultimateCollectionIcon8 from '../../assets/images/partner-images/ultimate-collection-icon-7.png';
import yourBrandImg1 from '../../assets/images/partner-images/your-brand-img-1.png';
import yourBrandImg2 from '../../assets/images/partner-images/your-brand-img-2.png';

export const PartnerWithUsData = [
  {
    title: "Start-up accelerators, enablers, incubators",
    description: "Fuel your business journey with Touras as your trusted partner",
    iconUrl: partnerWithUsIcon1
  },
  {
    title: "Developer, designer, or freelancer",
    description: "Forge a secure payment acceptance interface through our powerful APIs",
    iconUrl: partnerWithUsIcon2
  },
  {
    title: "Enterprise solution providers, ERP & SAAS players",
    description: "Elevate your client experience through seamless integrations.",
    iconUrl: partnerWithUsIcon3
  },
];

export const GetMoreData = [
  {
    title: "SIGNUP FOR PARTNER",
    description: "To join Touras, sign up and complete the online agreement. We welcome you as partners!",
    iconUrl: getMoreIcon1
  },
  {
    title: "REFER TO OTHERS",
    description: "Businesses can easily refer their partners through our dashboard or via SMS, Whatsapp, email etc",
    iconUrl: getMoreIcon2
  },
  {
    title: "INTEGRATION WITH KYC",
    description: "Refer businesses to Touras for payment solutions that streamline transactions.",
    iconUrl: getMoreIcon3
  },
  {
    title: "START EARNING",
    description: "You earn commissions every month for a lifetime with each transaction.",
    iconUrl: getMoreIcon4
  },
];

export const yourBrandData = [
  {
    title: "Dashboard",
    description: "Customize your dashboard with brand colors and logos. Quickly analyze merchant transactions and stay in control of your business.",
    imageUrl: yourBrandImg1,
    imgDirection: "left"
  },
  {
    title: "Email/SMS notifications",
    description: "Use pre-made templates to keep customers updated on transactions. Save time and effortlessly satisfy their needs.",
    imageUrl: yourBrandImg2,
    imgDirection: "right"
  },
  // {
  //   title: "Checkout pages",
  //   description: "Customize the checkout page with your brand logo and colors to foster customer trust.",
  //   imageUrl: yourBrandImg3,
  //   imgDirection: "left"
  // },
];

export const ultimateCollectionData = [
  {
    label: "APIX",
    iconUrl: ultimateCollectionIcon8,
  },
  {
    label: "Payment Links",
    iconUrl: ultimateCollectionIcon7,
  },
  {
    label: "Payment Forms",
    iconUrl: ultimateCollectionIcon2,
  },
  {
    label: "Whitelabel",
    iconUrl: ultimateCollectionIcon3,
  },
  {
    label: "Payment Aggregator",
    iconUrl: ultimateCollectionIcon4,
  },
  {
    label: "Payout",
    iconUrl: ultimateCollectionIcon5,
  },
  {
    label: "Aasaan",
    iconUrl: ultimateCollectionIcon1,
  },
  {
    label: "Neux-Neobanking",
    iconUrl: ultimateCollectionIcon6,
  },
  // {
  //   label: "ONOX",
  //   iconUrl: ultimateCollectionIcon9,
  // },
];