import React, { useEffect, useState } from "react";
import Pagination from "../../common-component/pagination/Pagination";
import fetchApi from "../../apiservice/ApiService";
import ImageModal from "../../common-component/image-modal/ImageModal";

const AwardsSection = () => {
  const [awardPage, setAwardPage] = useState(1);
  const [awardPageData, setAwardPageData] = useState(0);
  const [prevPage, setPrevPage] = useState(null);
  const [awardsData, setAwardsData] = useState([]);
  const [selectedImgObj, setSelectedImgObj] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setAwardPageData(
      awardPage === 1
        ? 0
        : awardPage + 1 !== awardPage &&
          (awardPage > prevPage + 1 || awardPage < prevPage + 1)
        ? (awardPage - 1) * 3
        : prevPage < awardPage
        ? awardPageData + 3
        : awardPageData - 3
    );
    setPrevPage(awardPage);
  }, [awardPage]);

  useEffect(() => {
    getAwardsData();
  }, []);

  const getAwardsData = () => {
    fetchApi
      .getAwards()
      .then((response) => {
        setAwardsData(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {showModal && (
        <ImageModal
          id={selectedImgObj?.["id"]}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
      <div className="bg-background" id="awards">
        <div className="mx-4 md:mx-20 pt-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-primary text-[30px] font-extrabold">Awards</p>
            {/* <div className="bg-[#FFFFFF] text-[#565656] p-4 flex items-center gap-3">
              <p>Filter By :</p>
              <div>
                <MonthSelector />
              </div>
              <div>
                <YearSelector />
              </div>
            </div> */}
          </div>

          <div className="grid lg:grid-cols-3 gap-10 py-10">
            {awardsData?.data?.length > 0 &&
              awardsData?.data?.map((ele, key) => {
                return (
                  <React.Fragment>
                    <div
                      key={key}
                      className="hover:-translate-y-2 transition-all duration-500 bg-[#FFFFFF] p-10 cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedImgObj(ele);
                      }}
                    >
                      <div className="w-full h-[185px] flex">
                        <img
                          src={ele["image_path"]}
                          alt="blog 1"
                          className="cursor-pointer h-auto mx-auto"
                        />
                      </div>
                      <div className="pt-8 font-roboto">
                        <p
                          className="text-primary text-[20px] font-extrabold cursor-pointer text-center"
                          title={ele["title"]}
                        >
                          {ele["title"]}
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
          {awardsData?.data?.length > 0 && (
            <Pagination totalPage={awardsData?.links} setData={setAwardsData} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AwardsSection;
