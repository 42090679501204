import React from "react";
import { useNavigate } from "react-router-dom";
import { routes_path } from "../config/routesPath";

const SheetsComponent = ({ image, signupLink }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#283C91] relative text-center grid md:flex gap-6 lg:gap-0 items-center sheets-bg">
      <div className="md:w-[50%] py-8 px-4">
        <p className="text-white text-[28px] lg:text-[38px] font-bold">
          Add beneficiaries instantly
        </p>
        <p className="text-white mx-auto text-[14px] lg:text-[18px] py-4">
          Enjoy instant beneficiary addition and payment without any cool-off
          period!
        </p>
        <div className="flex flex-wrap gap-4 justify-center">
          <button
            className="text-white bg-primary py-2 px-6 font-medium text-[12px] lg:text-[14px] rounded-[20px] hover:scale-95 transition-all"
            onClick={() => {
              // window.open(signupLink ? signupLink : routes_path?.signup);
              navigate(routes_path?.contactUs);
            }}
          >
            Create Account
          </button>
          <button
            className="text-secondary border-[1px] font-medium text-[12px] lg:text-[14px] border-secondary bg-white py-2 px-6 rounded-[20px] hover:scale-95 transition-all"
            onClick={() => {
              navigate(routes_path?.contactUs);
            }}
          >
            Contact Sales
          </button>
        </div>
      </div>
      <div
        className="md:w-[50%] md:h-[450px] flex lg:absolute -top-[60px] right-0"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <img
          src={image}
          alt="sheet background"
          className="h-auto object-contain"
        />
      </div>
    </div>
  );
};

export default SheetsComponent;
