import React, { useEffect, useState } from "react";
import HOC from "../component/HOC";
import fetchApi from "../apiservice/ApiService";
import parser from "html-react-parser";

const TermsCondition = () => {
  const [termsAndCondition, setTermsAndCondition] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Terms & Conditions | Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetTC();
  }, []);

  const handleGetTC = () => {
    setLoading(true);
    fetchApi
      .getTermsAndCondition()
      .then((response) => {
        setTermsAndCondition(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <div className="terms-bg flex justify-center items-center">
          <p className="text-white text-[32px] lg:text-[47px] font-bold text-center">
            TERMS & CONDITIONS
          </p>
        </div>
        {loading ? (
          <div className="flex flex-col items-center justify-center py-20">
            <div className="page-loader"></div>
          </div>
        ) : (
          <div className="my-10 mx-4 lg:mx-20 d-flex">
            {termsAndCondition?.description &&
              parser(termsAndCondition?.description)}
          </div>
        )}
      </div>
    </>
  );
};

export default HOC(TermsCondition);
