import aepsImg from "../../assets/images/Products-images/aeps_logo.png";
import apixIcon4 from "../../assets/images/Products-images/bank.png";
import bankSolution from "../../assets/images/Products-images/banking_solution.png";
import cart from "../../assets/images/Products-images/cart.png";
import checkoutExperience from "../../assets/images/Products-images/checkout-experience.png";
import chooseapixIcon1 from "../../assets/images/Products-images/apix/choose-apix-1.png";
import chooseapixIcon2 from "../../assets/images/Products-images/apix/choose-apix-2.png";
import chooseapixIcon3 from "../../assets/images/Products-images/apix/choose-apix-3.png";
import chooseapixIcon4 from "../../assets/images/Products-images/apix/choose-apix-4.png";
import conversation from "../../assets/images/Products-images/conversation.png";
import dataIntegration from "../../assets/images/Products-images/data-integration.png";
import apixIcon2 from "../../assets/images/Products-images/documents.png";
import neuxImg3 from "../../assets/images/Products-images/easy-reconciliation.png";
import flexiQr from "../../assets/images/Products-images/flexi-qr.png";
import gateway from "../../assets/images/Products-images/gateway.png";
import neuxImg1 from "../../assets/images/Products-images/invoice.png";
import neuxImg2 from "../../assets/images/Products-images/manage_inventory.png";
import billPayment from "../../assets/images/Products-images/money_reciept.png";
import apixIcon3 from "../../assets/images/Products-images/more-information.png";
import onoxDG1 from "../../assets/images/Products-images/onox_digital_growth_1.png";
import onoxDG2 from "../../assets/images/Products-images/onox_digital_growth_2.png";
import onoxDG3 from "../../assets/images/Products-images/onox_digital_growth_3.png";
import onoxDG4 from "../../assets/images/Products-images/onox_digital_growth_4.png";
import onoxDG5 from "../../assets/images/Products-images/onox_digital_growth_5.png";
import onoxDG6 from "../../assets/images/Products-images/onox_digital_growth_6.png";
import onoxDG7 from "../../assets/images/Products-images/onox_digital_growth_7.png";
import onoxDG8 from "../../assets/images/Products-images/onox_digital_growth_8.png";
import onoxWL1 from "../../assets/images/Products-images/onox_whitelabel_img_1.png";
import onoxWL2 from "../../assets/images/Products-images/onox_whitelabel_img_2.png";
import onoxWL3 from "../../assets/images/Products-images/onox_whitelabel_img_3.png";
import onoxWL4 from "../../assets/images/Products-images/onox_whitelabel_img_4.png";
import onoxWL5 from "../../assets/images/Products-images/onox_whitelabel_img_5.png";
import onoxWL6 from "../../assets/images/Products-images/onox_whitelabel_img_6.png";
import payAggregator from "../../assets/images/Products-images/pay-aggregator.png";
import payForm from "../../assets/images/Products-images/pay-forms.png";
import payAgg1 from "../../assets/images/Products-images/payAggregator_icon_1.png";
import payAgg2 from "../../assets/images/Products-images/payAggregator_icon_2.png";
import payAgg3 from "../../assets/images/Products-images/payAggregator_icon_3.png";
import payAgg4 from "../../assets/images/Products-images/payAggregator_icon_4.png";
import payAgg5 from "../../assets/images/Products-images/payAggregator_icon_5.png";
import payAgg6 from "../../assets/images/Products-images/payAggregator_icon_6.png";
import neuxImg4 from "../../assets/images/Products-images/reporting.png";
import seller from "../../assets/images/Products-images/seller.png";
import sendMoney from "../../assets/images/Products-images/send_money.png";
import apixIcon1 from "../../assets/images/Products-images/wallet.png";
import webAnalysis from "../../assets/images/Products-images/web-analysis.png";
import whitelabelIcon1 from "../../assets/images/Products-images/whitelabel/whitelabel-icon-1.png";
import whitelabelIcon2 from "../../assets/images/Products-images/whitelabel/whitelabel-icon-2.png";
import whitelabelIcon3 from "../../assets/images/Products-images/whitelabel/whitelabel-icon-3.png";
import whitelabelIcon4 from "../../assets/images/Products-images/whitelabel/whitelabel-icon-4.png";
import whitelabelIcon5 from "../../assets/images/Products-images/whitelabel/whitelabel-icon-5.png";
import whitelabelIcon6 from "../../assets/images/Products-images/whitelabel/whitelabel-icon-6.png";
import whitelabelIcon7 from "../../assets/images/Products-images/whitelabel/whitelabel-icon-7.png";
import whitelabelIcon8 from "../../assets/images/Products-images/whitelabel/whitelabel-icon-8.png";
import whitelabelSolutionIcon1 from "../../assets/images/Products-images/whitelabel/whitelabel-solution-icon-1.png";
import whitelabelSolutionIcon2 from "../../assets/images/Products-images/whitelabel/whitelabel-solution-icon-2.png";
import whitelabelSolutionIcon3 from "../../assets/images/Products-images/whitelabel/whitelabel-solution-icon-3.png";
import payoutImg3 from "../../assets/images/payout-images/automated-reconcilation.png";
import payoutImgCard1 from "../../assets/images/payout-images/bulkpaymeny.png";
import payoutImgCard3 from "../../assets/images/payout-images/connect.png";
import payoutsAfterCardImg3 from "../../assets/images/payout-images/payout-icon-10.png";
import payoutsAfterCardImg4 from "../../assets/images/payout-images/payout-icon-11.png";
import payoutImg1 from "../../assets/images/payout-images/payout-icon-4.png";
import payoutImg2 from "../../assets/images/payout-images/payout-icon-6.png";
import payoutImg4 from "../../assets/images/payout-images/payout-icon-7.png";
import payoutsAfterCardImg1 from "../../assets/images/payout-images/payout-icon-8.png";
import payoutsAfterCardImg2 from "../../assets/images/payout-images/payout-icon-9.png";
import payoutImgCard2 from "../../assets/images/payout-images/support.png";
import { routes_path } from "../../config/routesPath";

export const neuxData = [
  {
    title: "Easy Invoice raising with payment collections",
    description:
      "Simplify your purchase and sales cycles effortlessly. Manage invoices, receipts, delivery notes, and more, covering various transaction types, from physical goods to subscriptions.",
    imgUrl: neuxImg1,
  },
  {
    title: "Manage your Contacts and Inventory",
    description:
      "Revamp your inventory management with personalized catalogs, SKU management, and optimized pricing. Simplify buying and onboarding for multiple buyers and sellers through various channels.",
    imgUrl: neuxImg2,
  },
  {
    title: "Easy reconciliation",
    description:
      "Easily handle all your collections and payouts while seamlessly reconciling your transactions.",
    imgUrl: neuxImg3,
  },
  {
    title: "Insightful Reporting",
    description:
      "Access valuable transaction reports, payment histories & more offering insights into your financial operations. Unlock data-driven decision-making.",
    imgUrl: neuxImg4,
  },
];

export const payoutsData = [
  {
    title: "Multi Paymodes",
    description:
      "Explore different payment options, such as bank transfers, e-wallets, IMPS etc., to pick the one that suits you best.",
    img: payoutImg1,
  },
  {
    title: "API Integration",
    description:
      "Seamlessly connect with popular business tools such as CRM, ERPs, payroll, and multiple accounting software.",
    img: payoutImg2,
  },
  {
    title: "Flexible Payment Plans",
    description: "Organize your payments to suit your business requirements.",
    img: payoutImg4,
  },
  {
    title: "Automated Reconciliation",
    description: "Simplify payment matching without manual effort!",
    img: payoutImg3,
  },
];

export const payoutCardsSectionData = [
  {
    title: "Bulk Payments",
    description: "Easily transfer funds to numerous beneficiaries all at once.",
    image: payoutImgCard1,
    img_direction: "right",
    bg: true,
  },
  {
    title: "Customer Support",
    description:
      "Get quick, efficient and dedicated support for any payout-related issues.",
    image: payoutImgCard2,
    img_direction: "left",
    bg: false,
  },
  {
    title: "Connected Banking",
    description:
      "Connect your existing current accounts for easy cash flow control or open a new current account for your business, easily send payments and track your finances from one dashboard.",
    image: payoutImgCard3,
    img_direction: "right",
    bg: true,
  },
];

export const payoutsAfterCardData = [
  {
    title: "Customised Approval Workflows",
    description:
      "Design personalized approval workflows to streamline your transactions and enhance security within you team.",
    img: payoutsAfterCardImg1,
  },
  {
    title: "Payout from Credit Cards",
    description:
      "Operate your corporate credit card for vendor payments, along with topping up your virtual account.",
    img: payoutsAfterCardImg3,
  },
  {
    title: "Role Based User Access Management",
    description:
      "Maximize security and streamline productivity with customized user permissions based on their roles.",
    img: payoutsAfterCardImg2,
  },
  {
    title: "Digital Onboarding",
    description:
      "Sign up, complete KYC, and activate your Payout Account instantly.",
    img: payoutsAfterCardImg4,
  },
  {
    title: "Bank Account Validation/Verification",
    description:
      "Verify bank account and holder name for secure payments before sending them payout.",
    img: payoutsAfterCardImg2,
  },
];

export const aasaanData = [
  {
    title: "Payment Gateway Agnostic",
    description:
      "Integrate with Razorpay, PayU, Cashfree, and other major players without changing your payment platform.",
    imageUrl: gateway,
  },
  {
    title: "Get Your Conversions Increased",
    description:
      "With our standardized checkout get a 30% boost in your conversions",
    imageUrl: conversation,
  },
  {
    title: "Consolidate all dashboards with the platforms you use",
    description: "Simplify your marketing dashboards with our platform",
    imageUrl: webAnalysis,
  },
  {
    title: "No Minimum Order Volume",
    description: "No minimum order volume threshold during onboarding.",
    imageUrl: cart,
  },
  {
    title: "Insightful Merchant Portal",
    description: "Get a track of everything with an intuitive merchant portal.",
    imageUrl: seller,
  },
  {
    title: "Easy Integration",
    description:
      "Get ready for an easy checkout experience with aasaan - all within a few hours!",
    imageUrl: dataIntegration,
  },
];

export const aasaanShopperData = [
  {
    title: "Easy Checkouts",
    description:
      "Let your shoppers enjoy with Aasaan Easy's Checkout, even as a guest user.",
  },
  {
    title: "Checkout With Flexibility",
    description:
      "Simplify your checkout process across all our brands, with a range of options to ensure a stress-free experience.",
  },
  {
    title: "Quick Buy",
    description:
      "Just like Amazon’s “Buy Now” feature, your shoppers can buy any item you want with a single click.",
  },
  {
    title: "One-Stop Shopping Platform",
    description:
      "Easily Manage Your Orders from Multiple Brands with One Convenient Platform",
  },
];

export const onoxSuccessData = [
  {
    title: "Bill Payment Services",
    imageUrl: billPayment,
  },
  {
    title: "Domestic Money Transfer",
    imageUrl: sendMoney,
  },
  {
    title: "Cash Withdrawal and Money Transfer via AEPS",
    imageUrl: aepsImg,
  },
  {
    title: "Digital Linking with 100+ Service Providers",
    imageUrl: bankSolution,
  },
];

export const onoxDigitalGrowthData = [
  {
    title: "Free Account Creation",
    imageUrl: onoxDG1,
  },
  {
    title: "Trusted by 100+ Service Providers",
    imageUrl: onoxDG2,
  },
  {
    title: "Expanding Service Provider Network",
    imageUrl: onoxDG3,
  },
  {
    title: "365 Days, Round-the-Clock Support",
    imageUrl: onoxDG4,
  },
  {
    title: "99% Uptime with Robust Security Measures",
    imageUrl: onoxDG5,
  },
  {
    title: "Easy-to-Use App in 3 Simple Steps",
    imageUrl: onoxDG6,
  },
  {
    title: "Real-Time Commission",
    imageUrl: onoxDG7,
  },
  {
    title: "Instant Onboarding with Dedicated Support",
    imageUrl: onoxDG8,
  },
];

export const onoxWhiteLabelPortalData = [
  {
    title: "Cross-Selling Opportunities:",
    description:
      "Expand services offline to boost revenue and attract more customers. Strengthen brand loyalty.",
    imageUrl: onoxWL1,
    imageDirection: "left",
  },
  {
    title: "AI-Powered Expansion",
    description:
      "Extend your reach and services leveraging our AI capabilities, with a focus on identifying and preventing fraud.",
    imageUrl: onoxWL2,
    imageDirection: "right",
  },
  {
    title: "Time and Money Saver",
    description:
      "Save time and money with quick integration using our Onox App.",
    imageUrl: onoxWL3,
    imageDirection: "left",
  },
  {
    title: "Rapid Market Entry",
    description:
      "Go to market swiftly with our training support and the potential to go live within 10-15 days.",
    imageUrl: onoxWL4,
    imageDirection: "right",
  },
  {
    title: "Risk Mitigation and Brand Enhancement",
    description:
      "Get a personalized and secure front-end solution that reflects your brand's identity.",
    imageUrl: onoxWL5,
    imageDirection: "left",
  },
  {
    title: "Seamless Customization",
    description:
      "Onox offers swift and customizable payment integration for seamless operations.",
    imageUrl: onoxWL6,
    imageDirection: "right",
  },
];

export const paymentAggregatorData = [
  {
    title: "Payment Aggregator",
    description:
      "Consolidate with us with our seamless features, diverse payments with our easy onboarding.",
    imageUrl: payAggregator,
    buttonTxt: "Create Account",
    path: routes_path?.contactUs,
    img_direction: "left",
  },
  {
    title: "Payment Forms",
    description:
      "A completely safe and secure payment collection tool, with no code & all analytics support",
    imageUrl: payForm,
    buttonTxt: "Explore Payment Forms",
    path: routes_path?.contactUs,
    img_direction: "right",
  },
  {
    title: "Checkout Experience",
    description:
      "Experience seamless checkout with JS integration, merchant-hosted, aggregator-hosted or Mobile SDK's",
    imageUrl: checkoutExperience,
    buttonTxt: "Explore Checkout",
    path: routes_path?.contactUs,
    img_direction: "left",
  },
  {
    title: "Flexi QR",
    description:
      "Quickly generate and distribute QR codes and comfortably collect payments through various modes.",
    imageUrl: flexiQr,
    buttonTxt: "Explore QR",
    path: routes_path?.contactUs,
    img_direction: "right",
  },
];

export const payAggregatorData_two = [
  {
    title: "Payment Links",
    description:
      "Easily share payment options via social media, messaging apps, SMS, Emails and chatbots.",
    iconUrl: payAgg1,
  },
  {
    title: "Settlement",
    description:
      "We ensure your payment goes securely to the merchant with multiple settlement cycles, so everyone gets paid what they're owed with our instant, on-demand, and split settlement options.",
    iconUrl: payAgg2,
  },
  {
    title: "UPI Autopay",
    description:
      "Automate your subscription payments for up to ₹15,000 with a one-time UPI mandate authentication",
    iconUrl: payAgg3,
  },
  {
    title: "ENach",
    description:
      "Simplify your customers' lives by effortlessly setting up recurring payments and subscriptions through the speedy and hassle-free creation of mandates with eNACH.",
    iconUrl: payAgg4,
  },
  {
    title: "Smart Routing",
    description:
      "Boost your sales and customer satisfaction with Touras's Smart Routing solution.",
    iconUrl: payAgg5,
  },
  {
    title: "Consolidated Dashboards",
    description:
      "Gain business clarity using Touras Dashboard by tracking analytics, staying updated, and elevating your strategies",
    iconUrl: payAgg6,
  },
];

export const paGrowBusinessData = [
  {
    title: "Multiple Payment Modes",
    description:
      "Provides 100+ payment methods like Credit card, Debit Card, Net-Banking, UPI, Wallets",
  },
  {
    title: "Robust Security",
    description:
      "Ensuring your data's safety with PCI DSS Level 1 compliance, which does regular 3rd party audits, with a dedicated security team",
  },
  {
    title: "Faster Settlements",
    description:
      "Get the convenience of accepting payments and receiving speedy settlements",
  },
  {
    title: "Go Worldwide",
    description:
      "Expand your business worldwide by enabling international payment options",
  },
  {
    title: "Refunds",
    description:
      "With our easy refund process you can request or issue partial refunds quickly from the dashboard.",
  },
  {
    title: "",
    description: "",
  },
  {
    title: "Form Builder",
    description:
      "Creates Customize Online Form which helps to capture your customer details, accept payment online and provides customize payment reports with customer details.",
  },
  {
    title: "White Label platform",
    description:
      "Customize your dashbaord and checkout page with your own brand docket.",
  },
];

export const whitelabelData = [
  {
    title: "Own branded checkouts",
    iconUrl: whitelabelIcon2,
  },
  {
    title: "Customized portals enhance merchant branding and engagement.",
    iconUrl: whitelabelIcon4,
  },
  {
    title: "Email & SMS templates as per your brand",
    iconUrl: whitelabelIcon6,
  },
  {
    title: "Merchant analytics",
    iconUrl: whitelabelIcon3,
  },
  {
    title: "Multiple payment options",
    iconUrl: whitelabelIcon5,
  },
  {
    title: "Branded API for 15+ shopping carts",
    iconUrl: whitelabelIcon1,
  },
  {
    title: "Multiple currency acceptance",
    iconUrl: whitelabelIcon7,
  },
  {
    title: "Branded SDK for mobile API integration",
    iconUrl: whitelabelIcon8,
  },
];

export const WLPAData = [
  "Merchant lifecycle management",
  "Payment modes & priority routing",
  "Flexible reconciliation & settlement",
  "Integration kits & developer guides",
  "Built-in or API-led dashboards",
];

export const WLPNData = [
  "Own banking environment",
  "Send or receive money",
  "Pay bills",
  "Add & manage cards",
  "Physical or Virtual Card",
  "Co-branded card",
  "ATM Withdrawals",
  "Easy Card loading & Re-loading",
  "Pay Rewards/Salaries on card",
  "Balance Passbook",
];

export const whiteLabelSolutionData = [
  {
    title: "Branded Checkout",
    description:
      "Partner sign up is now easier than ever with our seamless onboarding.",
    iconUrl: whitelabelSolutionIcon1,
  },
  {
    title: "Reassured Checkout",
    description:
      "Track transactions details effectively through \n our comprehensive dashboard insights.",
    iconUrl: whitelabelSolutionIcon2,
  },
  {
    title: "Quick Launch",
    description:
      "Get up and running in just 7 days, with easy onboarding and integration for merchants of all types.",
    iconUrl: whitelabelSolutionIcon3,
  },
];

export const apixData = [
  // {
  //   title: "Payment APIs",
  //   iconUrl: apixIcon1,
  //   listItems: [
  //     "All Bill Pay API",
  //     "DTH Recharge APIs",
  //     "Mobile Recharge APIs",
  //     "Money Transfer APIs",
  //   ],
  // },
  {
    title: "KYC APIs",
    iconUrl: apixIcon2,
    listItems: [
      "Aadhaar verification",
      "PAN verification",
      "CKYC download",
      "Voter ID",
      "DL verification",
      "OCR - Aadhaar, Passport, Voter, DL",
      "Passoport verification",
    ],
  },
  {
    title: "KYB APIs",
    iconUrl: apixIcon1,
    listItems: [
      "Udyog/Udyam",
      "Shop and Establishment",
      "Import/Export",
      "CIN/DIN verification",
      "GST verification/report",
      "FSAAI",
      "MCA",
      "TAN",
      "RERA",
    ],
  },
  // {
  //   title: "Other utility API",
  //   iconUrl: apixIcon3,
  //   listItems: [
  //     "Mobile Operator APIs",
  //     "Bank Logo APIs",
  //     "e Bank List/ IIN APIs",
  //     "Prepaid Plans API",
  //     "PIN Code APIs",
  //   ],
  // },
  {
    title: "Bank",
    iconUrl: apixIcon4,
    listItems: [
      "Bank Account",
      "UPI verification",
      "Bank search by IFSC code",
      "OCR-Cheque",
    ],
  },
  {
    title: "Individual",
    iconUrl: apixIcon3,
    listItems: [
      "Face match/liveness",
      "Telecom verification",
      "Court room check",
      "Video KYC",
      "Email verification",
      "ITR compliances/26AS",
      "Vehicle Challan",
      "RC verification",
      "Electricity Bill",
      "Digilocker document",
    ],
  },
];

export const whyChooseAPIxData = [
  {
    title: "Integrate APIs Easily",
    description:
      "Tested REST APIs with clear documentation for easy integration.",
    iconUrl: chooseapixIcon1,
  },
  {
    title: "Bank Switch Made Easy",
    description:
      "Our multi-vendor partnerships provide backup in case of transaction issues with any bank.",
    iconUrl: chooseapixIcon2,
  },
  {
    title: "Partner Support System",
    description: "Partner support desk here to assist or your business!",
    iconUrl: chooseapixIcon3,
  },
  {
    title: "Uniform Verification",
    description:
      "Verify bank account numbers and holder names to avoid errors when sending money.",
    iconUrl: chooseapixIcon4,
  },
];
