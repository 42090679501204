import axios from "axios";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import toast, { toastConfig } from "react-simple-toasts";
import { AppConfig } from "../../apiservice/AppConfig";
import "../../assets/css/about.css";
import yearlyData from "../../assets/images/about-images/about-data.png";
import aboutMap from "../../assets/images/about-images/about-map.png";
import ourMission from "../../assets/images/about-images/ourMissionImg.png";
import ourVision from "../../assets/images/about-images/ourVisionImg.png";
import HOC from "../../component/HOC";
import { featuredData } from "../home/homeData";
import { aboutStatistics } from "./aboutData";

const About = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e) => {
    const mail = e.target.value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    setEmailAddress(e.target.value);
    if (mail) {
      setIsValid(true);
      setError(false);
    } else if (e.target.value === "") {
      setEmailAddress("");
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    if (emailAddress === "") {
      toastConfig({ theme: "failure" });
      toast("please enter a email.");
      setTimeout(() => {
        setLoader(false);
      }, 500);
      return false;
    }

    if (isValid) {
      const _form = new FormData();
      _form.append("email", emailAddress);
      _form.append("page_name", "about_us");
      axios
        .post(AppConfig.baseUrl + "customer/landing/subscribe", _form)
        .then((response) => {
          setError(false);
          toastConfig({ theme: "success" });
          toast(response?.data?.message);
        })
        .catch((err) => {
          console.log(err);
          toastConfig({ theme: "failure" });
          if (err?.response?.data?.message) {
            toast(err?.response?.data?.message);
          } else {
            toast("Email not sent.");
          }
        })
        .finally(() => {
          setLoader(false);
          setEmailAddress("");
          setIsValid(false);
        });
    } else {
      toastConfig({ theme: "failure" });
      toast("Enter email to subscribe.");
      setIsValid(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    document.title = "About Us - Trusted Online Payment Services";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <section className="">
        <div className="abt-bnr-bg flex gap-10">
          <div className="lg:w-[60%] lg:py-10 lg:px-20 py-4 px-4">
            <p className="text-[28px] lg:text-[50px] mb-4 font-extrabold text-primary">
              The Joy of Doing The <span className="text-secondary">Best</span>
            </p>
            <p className="mx-auto max-w-[1024px] text-[14px] md:text-[18px] text-content">
              Touras is a platform that specializes in orchestrating payments
              and offering customised payment solutions and value-added services
              for both merchants.
            </p>
          </div>
        </div>

        {/* <div className="achivment-bg flex flex-wrap gap-6 md:gap-20 justify-center">
          {aboutStatistics?.map((item, index) => {
            return (
              <div
                className="bg-[#EBF5FE] flex items-center justify-center flex-col p-4 md:p-10 rounded-full md:w-[200px] md:h-[200px] w-[120px] h-[120px] text-center"
                key={index}
              >
                <CountUp
                  start={0}
                  end={item?.number}
                  delay={0}
                  separator=""
                  enableScrollSpy={true}
                  suffix={item?.suffix}
                  className="text-[24px] md:text-[30px] font-extrabold text-primary"
                />
                <p className="text-[14px] md:text-[18px] text-primary">
                  {item?.label}
                </p>
              </div>
            );
          })}
        </div> */}

        {/* <div className="py-10 px-4">
          <p className="text-[28px] md:text-[50px] font-extrabold text-center text-primary">
            Touras's <span className="text-secondary">Growth Journey</span>
          </p>

          <div className="h-full md:h-screen flex justify-center py-10">
            <img src={yearlyData} alt="yearly data" className="h-auto" />
          </div>
        </div> */}

        <div className="lg:p-12 p-6 bg-background">
          <div className="text-center">
            <img src={ourVision} alt="our vision" className="w-full mx-auto" />
            <div className="py-10 px-6 md:px-20 flex flex-col justify-end md:justify-center">
              <p className="text-[24px] md:text-[40px] font-extrabold text-primary">
                Our Vision
              </p>
              <p className="md:text-[24px] font-semibold text-primary">
                To build digital platform infrastructure that will transform the
                payment and banking needs for every business across the globe.
              </p>
            </div>
          </div>

          <div className="text-center">
            <img
              src={ourMission}
              alt="our mission"
              className="w-full mx-auto"
            />
            <div className="pt-10 px-6 md:px-20 flex flex-col justify-center">
              <p className="text-[24px] md:text-[40px] font-extrabold text-primary">
                Our Mission
              </p>
              <p className="md:text-[20px] font-semibold text-primary">
                Accelerate economic growth of digital platform & design APIs for
                every business.
              </p>
            </div>
          </div>
        </div>

        {/* Also featured on section */}
        <section className="pb-10 pt-14 bg-primary">
          <p className="text-[34px] text-white font-bold text-center">
            Also featured on
          </p>
          <div className="overflow-hidden">
            <div className="max-w-[1140px] mx-auto pt-10 flex gap-4 items-center marquee-effect">
              {featuredData?.map((item, index) => {
                return (
                  <span
                    className="mb-[40px] mx-[0px] lg:mx-[10px] w-[130px] lg:w-[200px]"
                    key={index}
                  >
                    <img
                      src={item?.url}
                      alt={item.alt}
                      className="max-w-[130px] lg:max-w-[200px] max-h-[75px]"
                    />
                  </span>
                );
              })}
            </div>
          </div>
        </section>

        {/* Meet team section */}
        {/* <div className="abt-team-bg flex items-end">
          <div className="flex flex-col abt-team-bg-1 flex-wrap gap-10 justify-end items-end p-4 md:p-10 pb-10 md:pb-20">
            <div>
              <div className="text-center md:w-[70%] mx-auto">
                <p className="text-white text-[20px] md:text-[30px] lg:text-[38px] font-bold">
                  <q>
                    Great things in business are never done by one person.
                    They're done by a team of people.
                  </q>
                </p>
              </div>
              <div className="text-end font-bold px-4 md:px-10">
                <p className="text-white text-[18px] md:text-[25px] font-medium">
                  - Aradhana Gupta
                </p>
                <p className="text-white text-[18px] md:text-[25px] font-bold">
                  Chief Human Resources Officer
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="flex justify-center py-10 md:h-[500px] relative">
          <img src={aboutMap} alt="Map" className="h-auto object-contain" />
          <div className="bg-white hidden md:block rounded-[30px] w-[350px] h-auto p-4 absolute top-20 left-40 shadow-[0px_4px_4px_0px_#00000033]">
            <div className="text-primary text-center relative">
              <p className="text-[20px] font-black">Dubai Headquarter</p>
              <p className="text-[12px]">
                1603,Al Shafar Tower 1, Barsha Heights, Dubai, UAE
              </p>
            </div>
          </div>
          <div className="bg-white hidden md:block rounded-[30px] w-[350px] h-auto p-4 absolute bottom-20 shadow-[0px_4px_4px_0px_#00000033]">
            <div className="text-primary text-center relative">
              <p className="text-[20px] font-black">Mumbai Headquarter</p>
              <p className="text-[12px]">
                Unit No 718, Lodha Supremus 2, Road No 22, Wagle Industrial
                Estate, Thane, Maharashtra, 400604
              </p>
              <button
                className="absolute left-[50%] -translate-x-[50%] bg-primary text-white text-[14px] px-6 py-1 rounded-[5px]"
                onClick={() => {
                  window.open(
                    "https://maps.app.goo.gl/2wB3mcoNiYVqhe2FA",
                    "_blank"
                  );
                }}
              >
                Get Direction
              </button>
            </div>
          </div>
        </div>

        <div className="px-4 block md:hidden">
          <p className="text-[20px] font-bold">Get Directions:</p>
          <div
            className="text-primary my-4"
            onClick={() => {
              window.open(
                "https://maps.app.goo.gl/2wB3mcoNiYVqhe2FA",
                "_blank"
              );
            }}
          >
            <p className="text-[20px] font-black text-primary underline">
              Mumbai Headquarter
            </p>
            <p className="text-[12px]">
              Unit No 718, Lodha Supremus 2, Road No 22, Wagle Industrial
              Estate, Thane, Maharashtra, 400604
            </p>
          </div>
          <div className="text-primary my-4">
            <p className="text-[20px] font-black">Dubai Headquarter</p>
            <p className="text-[12px]">
              1603,Al Shafar Tower 1, Barsha Heights, Dubai, UAE
            </p>
          </div>
        </div>

        {/* Subscribe section */}
        <div className="bg-background abt-sub-bg py-10 px-4 mb-6 rounded-[10px] max-w-[1140px] mx-auto">
          <div className="max-w-[1024px] mx-auto">
            <p className="text-[20px] md:text-[30px] font-bold text-primary text-center">
              Unlock exclusive access to latest product updates, insightful
              industry trends, & many others, delivered directly to your inbox.
            </p>
            <div className="flex flex-col md:flex-row items-start justify-center gap-4 mt-10">
              <div className="grid">
                <input
                  type="email"
                  placeholder="Enter Mail ID"
                  className="border-[1px] border-primary bg-[#F3F9FE] rounded-[6px] outline-none text-center p-2 text-sm w-[250px]"
                  value={emailAddress}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {error && (
                  <p className="text-[12px] text-left text-red-500 mt-1">
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              {loader ? (
                <p
                  className="bg-[#000000] py-2 px-8 border-[1px] border-primary rounded-[6px] text-[#FFFFFF] font-medium text-sm flex justify-center items-center w-[130px]"
                  onClick={() => {
                    toastConfig({ theme: "failure" });
                    toast("please wait! we are processing your request.");
                  }}
                >
                  <span className="loader"></span>
                </p>
              ) : (
                <button
                  className="bg-[#000000] py-2 px-4 border-[1px] border-primary text-sm rounded-[6px] text-[#FFFFFF] font-medium"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Subscribe Now!
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HOC(About);
