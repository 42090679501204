import clsx from "clsx";
import React from "react";
import { routes_path } from "../config/routesPath";
import { useNavigate } from "react-router-dom";

const IntegrationSection = ({ page, color, bgColor, signupLink }) => {
  const navigate = useNavigate();

  return (
    <div
      className={clsx(
        "relative mb-10",
        page === "neux"
          ? "integration_1-bg"
          : page === "payment-aggregator"
          ? "pa_integration"
          : "integration-bg"
      )}
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      <p
        className={clsx(
          "absolute top-6 lg:top-8 left-[50%] translate-x-[-50%] text-[20px] lg:text-[40px] font-bold w-full text-center lg:w-max text-primary"
        )}
      >
        The Safest & Simplest Integration Ever
      </p>
      <div className="flex items-center gap-4 w-full lg:max-w-[40%] absolute -bottom-[20px] justify-center lg:-bottom-[18px] left-[50%] translate-x-[-50%]">
        {page !== "neux" && (
          <button
            className={clsx(
              "text-white py-2 lg:py-1 px-8 text-[12px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all bg-primary border border-secondary"
            )}
            onClick={() => {
              // window.open(routes_path.developerDoc, "_blank");
              navigate(routes_path?.contactUs);
            }}
          >
            View Developer Doc
          </button>
        )}
        <button
          className={clsx(
            "border-[1px] text-[12px] bg-white lg:text-[18px] py-2  lg:py-1 px-8 rounded-[20px] hover:scale-95 transition-all text-secondary border-secondary"
          )}
          onClick={() => {
            // window.open(signupLink ? signupLink : routes_path?.signup);
            navigate(routes_path?.contactUs);
          }}
        >
          Signup Now
        </button>
      </div>
    </div>
  );
};

export default IntegrationSection;
