import React, { useEffect, useState } from "react";
import pressReleaseArrow from "../../assets/social-icons/press-release-arrow.svg";
import Pagination from "../../common-component/pagination/Pagination";
import fetchApi from "../../apiservice/ApiService";
import dayjs from "dayjs";

const PressReleaseSection = () => {
  const [pressReleaseData, setPressReleaseData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetPressRelease();
  }, []);

  const handleGetPressRelease = () => {
    setLoading(true);
    fetchApi
      .getPressRelease()
      .then((response) => {
        setPressReleaseData(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div id="press-releases">
        <div className="mx-4 md:mx-20 py-10">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-primary text-[30px] font-extrabold">
              Press Release
            </p>
          </div>

          {loading ? (
            <div className="p-4 md:p-10 grid gap-10">
              {[...Array(5)]?.map((_, index) => {
                return (
                  <div
                    className="flex items-center justify-between cursor-pointer gap-10"
                    key={index}
                  >
                    <div className="w-[90%] flex flex-col gap-2">
                      <p className="skeleton--title">
                        <span></span>
                      </p>
                      <p className="skeleton--title">
                        <span></span>
                      </p>
                      <p className="skeleton--date w-[150px!important]">
                        <span></span>
                      </p>
                    </div>
                    <div className="w-[10%]">
                      <img
                        src={pressReleaseArrow}
                        alt="press release arrow"
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="p-4 md:p-10 grid gap-10">
              {pressReleaseData?.data?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => {
                      window.open(item?.link);
                    }}
                  >
                    <div className="w-[90%]">
                      <p className="text-[#565656] text-[18px] font-medium">
                        {item?.domain}
                      </p>
                      <p className="text-primary text-[20px] font-extrabold">
                        {item?.title}
                      </p>
                      <p className="text-[#565656] text-[16px] font-medium">
                        {item?.start_date ? dayjs(item?.start_date).format("MMM DD, YYYY") : dayjs(item?.created_at).format("MMM DD, YYYY")}
                      </p>
                    </div>
                    <div className="w-[10%]">
                      <img
                        src={pressReleaseArrow}
                        alt="press release arrow"
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {pressReleaseData?.data?.length > 0 && (
            <Pagination
              totalPage={pressReleaseData?.links}
              setData={setPressReleaseData}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PressReleaseSection;
