import React, { useEffect, useState } from "react";
import fetchApi from "../../apiservice/ApiService";
import Pagination from "../../common-component/pagination/Pagination";

const InterviewsSection = () => {
  const [interviewData, setInterviewData] = useState([]);

  useEffect(() => {
    getInterviewsData();
  }, []);

  const getInterviewsData = () => {
    fetchApi
      .getInterviews()
      .then((response) => {
        setInterviewData(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div id="interviews">
        <div className="mx-4 md:mx-20 pt-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-primary text-[30px] font-extrabold">
              Interviews
            </p>
          </div>

          <div className="py-10 grid md:grid-cols-2 items-start gap-20">
            {interviewData?.data?.length > 0 &&
              interviewData?.data?.map((item, index) => {
                return (
                  <div className="flex flex-col items-center justify-center" key={index}>
                    <iframe
                      src={item?.content_link}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                      className="w-full h-[250px] md:h-[315px] md:w-[560px] max-w-[560px]"
                      allowfullscreen
                    ></iframe>

                    <p className="text-[18px] md:text-[24px] font-semibold text-primary text-center mt-10">
                      {item?.title}
                    </p>
                  </div>
                );
              })}
          </div>
          {interviewData?.data?.length > 0 && (
            <Pagination
              totalPage={interviewData?.links}
              setData={setInterviewData}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InterviewsSection;
