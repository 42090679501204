import clsx from "clsx";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import security from "../../assets/images/Products-images/security.png";
import payEasier from "../../assets/images/payout-images/payout-easier.png";
import benefitImg1 from "../../assets/images/payout-images/payout-icon-1.png";
import benefitImg2 from "../../assets/images/payout-images/payout-icon-2.png";
import benefitImg3 from "../../assets/images/payout-images/payout-icon-3.png";
import sheetImg from "../../assets/images/payout-images/payout-sheet-img.png";
import HOC from "../../component/HOC";
import IntegrationSection from "../../component/IntegrationSection";
import SheetsComponent from "../../component/SheetsComponent";
import { routes_path } from "../../config/routesPath";
import banner from "../../assets/images/payout-images/payout-banner.png";
import {
  payoutCardsSectionData,
  payoutsAfterCardData,
  payoutsData,
} from "./productDetailsData";

const Payouts = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Payout - Touras";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="overflow-hidden">
        <div className="w-full flex flex-col-reverse md:flex-row items-center px-4 py-10 lg:px-10 font-MyriadPro payout-bg">
          <div
            className="md:w-[60%] md:pl-10"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="text-[24px] lg:text-[50px] font-bold text-primary">
              Send Payouts at your convenience.
            </p>
            <p className="font-light pr-10 text-[14px] lg:text-[18px] py-4 text-primary">
              Sends money instantly to pay vendors, refund customers, disburse
              loans with full Safety and ease.
            </p>
            <div className="grid grid-cols-2 gap-4 md:max-w-[70%]">
              <button
                className="text-white bg-primary py-2 md:py-1 px-6 text-[12px] lg:text-[18px] rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  // window.open(routes_path?.payoutSignup);
                  navigate(routes_path?.contactUs);
                }}
              >
                Create Account
              </button>
              <button
                className="text-secondary border-[1px] text-[12px] lg:text-[18px] border-secondary py-2 md:py-1 px-6 rounded-[20px] hover:scale-95 transition-all"
                onClick={() => {
                  navigate(routes_path?.contactUs);
                }}
              >
                Contact Sales
              </button>
            </div>
          </div>
          <div
            className="md:w-[40%] px-8 pb-8 md:p-8"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img src={banner} alt="payouts" className="object-contain" />
          </div>
        </div>

        <div className="py-10 bg-background">
          <p className="text-primary text-[24px] lg:text-[38px] px-4 lg:px-0 font-bold text-center">
            Make Payout Easier & Quicker
          </p>
          <div className="flex flex-col items-center lg:flex-row gap-10 m-4 lg:m-10">
            <div
              className="lg:w-[50%] h-[250px] lg:h-full flex"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img
                src={payEasier}
                alt="neuX"
                className="h-auto w-full object-contain"
              />
            </div>
            <div className="lg:w-[50%] flex flex-col gap-10">
              <div
                className="flex items-start gap-4 lg:pr-10 relative z-[4]"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <p className="">
                  <img src={benefitImg1} alt="payout benefits" width={80} />
                </p>
                <p className="grid max-w-[60%]">
                  <span className="text-content text-[16px] lg:text-[23px] font-bold">
                    End to End payment
                  </span>
                  <span className="text-[#000000] text-[14px] lg:text-[17px]">
                    Covers payouts completely, using exclusive UPI and bank
                    ecosystems for instant and secure transactions.
                  </span>
                </p>
              </div>
              <div
                className="flex items-start gap-4 lg:pr-10 relative z-[3]"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <p className="relative payout-steps">
                  <img src={benefitImg2} alt="payout benefits" width={80} />
                </p>
                <p className="grid max-w-[60%]">
                  <span className="text-content text-[16px] lg:text-[23px] font-bold">
                    Built for Scale
                  </span>
                  <span className="text-[#000000] text-[14px] lg:text-[17px]">
                    Fast transaction handling with asynchronous API management
                    during peak times.
                  </span>
                </p>
              </div>
              <div
                className="flex items-start gap-4 lg:pr-10 relative z-[2]"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <p className="relative payout-steps">
                  <img src={benefitImg3} alt="payout benefits" width={80} />
                </p>
                <p className="grid max-w-[60%]">
                  <span className="text-content text-[16px] lg:text-[23px] font-bold">
                    Complete Console
                  </span>
                  <span className="text-[#000000] text-[14px] lg:text-[17px]">
                    Manage your payout needs, collections, current account, and
                    business expenses easily through a unified platform.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="my-0 lg:mx-4">
            <div className="grid gap-4 lg:gap-10 p-4 lg:p-8">
              <div className="flex justify-center items-center flex-col gap-3 lg:gap-6 mb-4 lg:mb-0">
                <p className="text-primary text-[24px] lg:text-[38px] font-bold text-center px-4">
                  Now take control of how you pay out.
                </p>
              </div>
              <div className="grid lg:grid-cols-2 gap-2 lg:gap-4">
                {payoutsData?.slice(0, 2).map((item, index) => {
                  return (
                    <div
                      className="h-auto flex justify-center items-center lg:p-6"
                      key={index}
                    >
                      <div className="w-[15%]">
                        <img
                          src={item.img}
                          alt="payout benefits"
                          className="w-20"
                        />
                      </div>
                      <div className="w-[80%]">
                        <p className="grid px-4 py-2 h-full items-center rounded-[10px]">
                          <span className="text-[20px] lg:text-[22px] text-primary font-bold">
                            {item.title}
                          </span>
                          <span className="text-[14px] lg:text-[16px] font-light">
                            {item.description}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "grid lg:flex py-10 px-4 lg:px-20 lg:h-[400px] items-center bg-backgroundLight"
          )}
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="lg:w-[50%] text-center lg:text-left">
            <p className="text-[28px] lg:text-[40px] mb-2 lg:mb-4 text-primary font-bold">
              Compliance and Security
            </p>
            <p className="text-[18px] lg:text-[23px] leading-[25.5px] text-[#000000] lg:pr-32 mb-4 lg:mb-0">
              Payout solutions with the best features compliant with data
              privacy and security.
            </p>
          </div>
          <div className="lg:w-[50%] h-auto max-h-[300px] lg:h-full flex justify-center">
            <img
              src={security}
              className="h-auto object-contain"
              alt={"Compliance and Security"}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-2 gap-2 lg:gap-4 p-4 lg:p-8 lg:mx-4">
          {payoutsData?.slice(2, 4).map((item, index) => {
            return (
              <div
                className="h-auto flex justify-center items-center lg:p-6"
                key={index}
              >
                <div className="w-[15%]">
                  <img src={item.img} alt={item.title} className="w-20" />
                </div>
                <div className="w-[80%]">
                  <p className="grid px-4 py-2 h-full items-center rounded-[10px]">
                    <span className="text-[20px] lg:text-[22px] text-primary font-bold">
                      {item.title}
                    </span>
                    <span className="text-[14px] lg:text-[16px] font-light">
                      {item.description}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="">
          {payoutCardsSectionData?.map((item, index) => {
            return (
              <div
                className={clsx(
                  "grid lg:flex py-10 px-4 lg:px-20 lg:h-[400px] items-center",
                  item.bg ? "bg-backgroundLight" : "",
                  item.img_direction === "left"
                    ? "lg:flex-row-reverse"
                    : "lg:flex-row"
                )}
                data-aos={`${
                  item.img_direction === "left" ? "fade-right" : "fade-left"
                }`}
                data-aos-duration="1000"
                key={index}
              >
                <div className="lg:w-[50%] text-center lg:text-left">
                  <p className="text-[28px] lg:text-[40px] mb-2 lg:mb-4 text-primary font-bold">
                    {item?.title}
                  </p>
                  <p className="text-[18px] lg:text-[23px] leading-[25.5px] text-[#000000] lg:pr-32 mb-4 lg:mb-0">
                    {item?.description}
                  </p>
                </div>
                <div className="lg:w-[50%] h-auto max-h-[300px] lg:h-full flex">
                  <img
                    src={item?.image}
                    className="h-auto object-contain"
                    alt={item.title}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className="grid lg:grid-cols-2 gap-2 lg:gap-4 py-10 px-4 lg:px-0">
          {payoutsAfterCardData.map((item, index) => {
            return (
              <div
                className="h-auto flex justify-center items-center lg:p-6"
                key={index}
              >
                <div className="w-[15%]">
                  <img src={item.img} alt={item.title} className="w-20" />
                </div>
                <div className="w-[80%]">
                  <p className="grid px-4 py-2 h-full items-center rounded-[10px]">
                    <span className="text-[20px] lg:text-[22px] text-primary font-bold">
                      {item.title}
                    </span>
                    <span className="text-[14px] lg:text-[16px] font-light">
                      {item.description}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <SheetsComponent
          image={sheetImg}
          signupLink={routes_path?.payoutSignup}
        />
        <IntegrationSection signupLink={routes_path?.payoutSignup} />
      </div>
    </>
  );
};

export default HOC(Payouts);
